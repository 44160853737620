"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class PageControlSiteHomeController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.onClick = (evt) => {
            var _a, _b;
            evt.preventDefault();
            evt.stopPropagation();
            const value = (_a = this.element.querySelector('textarea')) === null || _a === void 0 ? void 0 : _a.value;
            (_b = window.open(document.location.pathname + "/preview?content=" + encodeURIComponent(value || ""), "_blank")) === null || _b === void 0 ? void 0 : _b.focus();
        };
    }
    connect() {
        var _a;
        (_a = this.element.querySelector('#btn-home-preview')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.onClick);
    }
    disconnect() {
        var _a;
        (_a = this.element.querySelector('#btn-home-preview')) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', this.onClick);
    }
}
exports.default = PageControlSiteHomeController;
