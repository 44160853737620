import { Controller } from "@hotwired/stimulus";

require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');

const DATEPICKER_OPTIONS = [
    'autoclose' , 'assumeNearbyYear', 'beforeShowDay', 'beforeShowMonth', 'beforeShowYear', 'beforeShowDecade',
    'beforeShowDecade', 'calendarWeeks', 'clearBtn', 'container', 'datesDisabled', 'daysOfWeekDisabled',
    'daysOfWeekHighlighted', 'defaultViewDate', 'disableTouchKeyboard', 'enableOnReadonly', 'endDate', 'forceParse',
    'format', 'immediateUpdates', 'inputs', 'keepEmptyValues', 'keyboardNavigation', 'language', 'maxViewMode',
    'minViewMode', 'multidate', 'multidateSeparator', 'orientation', 'showOnFocus', 'startDate', 'startView',
    'templates', 'title', 'todayBtn', 'todayHighlight', 'toggleActive', 'weekStart', 'zIndexOffset'
];

const DEFAULT_OPTIONS = {
    autoclose: true,
    language: 'fr',
    zIndexOffset: 1000,
    // orientation: 'bottom',
};

export default class FormDateController extends Controller {
    initialize() {
        this.input.pattern = '\\d{2}/\\d{2}/\\d{4}';
        if (!this.input.placeholder) {
            this.input.placeholder = 'DD/MM/YYYY';
        }
    }

    connect() {
        if (undefined !== this.input.attributes.disabled || undefined !== this.input.attributes.readonly) {
            return;
        }

        this.init();
        $(this.input).mask('00/00/0000');
        this.input.addEventListener('blur', this.onBlur);
    }

    disconnect() {
        $(this.input).datepicker('destroy');
        $(this.input).unmask();
        this.input.removeEventListener('blur', this.onBlur);
    }

    init = () => {
        const input = this.input;
        const options = Object.assign({}, DEFAULT_OPTIONS, JSON.parse(input.dataset.datepickerOptions));

        // select month or year views?
        if (undefined === options.maxViewMode && undefined !== options.changeMonth && false == options.changeMonth) {
            options.maxViewMode = 0;
        }
        if (undefined === options.maxViewMode && undefined !== options.changeYear && false == options.changeYear) {
            options.maxViewMode = 1;
        }

        // clear options
        for (let keys = Object.keys(options), idx = 0, len = keys.length; idx < len; idx++) {
            if (!DATEPICKER_OPTIONS.includes(keys[idx])) {
                delete options[keys[idx]];
            }
        }

        // remove autocomplete
        input.setAttribute('autocomplete', 'off');

        $(input).datepicker(options);

        $(input).datepicker().on('changeDate', () => {
            $(input).closest('.form-floating').addClass('active');
            $(input).trigger('change');
        });
    }

    onBlur = () => {
        const { value } = this.input;
        const now = new Date();
        if (value.length === 8) {
            const is2000Year = $(this.input).hasClass('birth-date') ? Number(`20${value.slice(6, 8)}`) <= (now.getFullYear() - 14) : true;
            $(this.input).val(`${value.slice(0, 6)}${is2000Year ? '20' : '19'}${value.slice(6)}`);
            $(this.input).closest('.form-floating').addClass('active');
            $(this.input).trigger('change');
        }
    };

    get form() {
        return this.element.closest('form');
    }

    get input() {
        if (this.element.matches('input')) {
            return this.element;
        }

        return this.element.querySelector('input');
    }
}
