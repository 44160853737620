import { Controller } from "@hotwired/stimulus";
const { default: SignaturePad } = require('signature_pad');
Object.assign(window, { SignaturePad });

export default class FormSignatureController extends Controller {
    pad = null

    connect() {
        const options = this.options;
        this.pad = new SignaturePad(this.canvas, options);

        // redraw content if set
        this.pad.fromDataURL(this.input.value);

        // undo btn
        if (undefined !== options.allowUndo && true === options.allowUndo) {
            const bt_undo = '<button class="btn btn-default signature-pad-action signature-pad-action-undo">'
                + '<span class="fal fa-undo"></span>'
                + '</button>'
            ;
            this.footerContainer.insertAdjacentHTML('beforeend', bt_undo);

            this.footerContainer.querySelector('.signature-pad-action-undo').addEventListener('click', (evt) => {
                evt.preventDefault();

                this.undo();
            });
        }

        // cancel btn
        if (undefined !== options.allowClear && true === options.allowClear) {
            const bt_reset = '<button class="btn btn-danger signature-pad-action signature-pad-action-reset">'
                + '<span class="fal fa-trash-alt"></span>'
                + '</button>'
            ;
            this.footerContainer.insertAdjacentHTML('beforeend', bt_reset);

            this.footerContainer.querySelector('.signature-pad-action-reset').addEventListener('click', (evt) => {
                evt.preventDefault();

                this.clear();
            });
        }

        // handle window resize
        window.onresize = this.resize;
        this.resize();
    }

    resize() {
        return;

        if (null === this.pad || undefined === this.pad.canvas) {
            return;
        }

        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        this.pad.canvas.width = this.pad.canvas.offsetWidth * ratio;
        this.pad.canvas.height = this.pad.canvas.offsetHeight * ratio;
        this.pad.canvas.getContext("2d").scale(ratio, ratio);

        this.pad.clear(); // otherwise isEmpty() might return incorrect value
    }

    clear() {
        if (null === this.pad) {
            return;
        }

        this.pad.clear();
        this.pad.onEnd();
    }

    undo() {
        if (null === this.pad) {
            return;
        }

        const data = this.pad.toData();
        if (data) {
            data.pop(); // remove the last dot or line
            this.pad.fromData(data);
        }

        this.pad.onEnd();
    }

    disconnect() {
        ;
    }

    get container() {
        return this.element;
    }

    get padContainer() {
        return this.container.querySelector('.signature-pad');
    }

    get footerContainer() {
        return this.container.querySelector('.signature-pad--actions');
    }

    get canvas() {
        return this.container.querySelector('canvas');
    }

    get input() {
        return this.container.querySelector('textarea');
    }

    get options() {
        let options = ('pad_options' in this.padContainer.dataset)
            ? JSON.parse(this.padContainer.dataset.pad_options)
            : {}
        ;

        options.onEnd = () => {
            const content = (this.pad.isEmpty())
                ? ""
                : this.pad.toDataURL(options.dataUrlFormat)
            ;

            this.input.value = content;

        };

        return options;
    }
}
