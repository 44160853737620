import { Controller } from "@hotwired/stimulus";

export default class FormFileController extends Controller {
    connect() {
        this.element.addEventListener('change', this.onChange);
    }

    disconnect() {
        this.element.addEventListener('change', this.onChange);
    }

    onChange = () => {
        const parent = this.element.parentElement;
        if (parent?.matches('.custom-file')) {
            const files = this.element.files || [];
            const names = [];
            for (let idx = 0; idx < files.length; idx++) {
                names.push(files[idx].name);
            }

            const label = parent.querySelector('.custom-file-label');
            if (label) {
                label.textContent = names.join(', ');
            }
        }
    };
}