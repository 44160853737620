import { Controller } from "@hotwired/stimulus";

export default class FormLastnameController extends Controller {
    connect() {
        this.element.addEventListener('keyup', this.onKeyUp);
    }

    disconnect() {
        this.element.removeEventListener('keyup', this.onKeyUp);
    }

    onKeyUp = () => {
        this.element.value = this.element.value.toUpperCase();
    }
}
