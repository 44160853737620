import { Controller } from "@hotwired/stimulus";

const DEFAULT_OPTIONS = {
    format: 'hh:mm',
    timeFormat: 'hh:mm',
    is24hour: true
};

export default class FormTimeController extends Controller {
    connect() {
        $(this.element).mask('00:00');
        $(this.element)
            .mdtimepicker(DEFAULT_OPTIONS)
            .on('timechanged', (evt) => {
                $(this.element).trigger('change');
            })
        ;

        $(this.element).bind('change', this.onChangeTime);

        if ('to' === this.element.dataset.timepickerRangeScope) {
            $(this.linkedTimeInput).trigger('change');
        }

        // reset limits ond dates changes
        if (null !== this.dateInput) {
            $(this.dateInput).on('changeDate', this.onChangeDate.bind(this));
        }
        if (null !== this.linkedDateInput) {
            $(this.linkedDateInput).on('changeDate', this.onChangeDate.bind(this));
        }
    }

    disconnect() {
        $(this.element).mdtimepicker('destroy');
    }

    onChangeTime = () => {
        if ('to' === this.element.dataset.timepickerRangeScope) {
            return;
        }

        let minTime = null;

        if (null === this.dateInput || $(this.dateInput)?.datepicker('getDate')?.getTime() === $(this.linkedDateInput)?.datepicker('getDate')?.getTime()) {
            minTime = this.element.value;
        }

        $(this.linkedTimeInput).mdtimepicker('setMinTime', minTime);
    }

    onChangeDate() {
        if ('to' === this.element.dataset.timepickerRangeScope) {
            $(this.linkedTimeInput).trigger('change');
        } else {
            $(this.element).trigger('change');
        }
    }

    get form() {
        let parent = this.element.closest('.form-collection-item');
        if (!parent) {
            parent = this.element.closest('form');
        }
        return parent;
    }

    get timeInput() {
        return this.element;
    }

    get dateInput() {
        const scope = this.element.dataset.timepickerRange;
        if (!scope) {
            return;
        }

        const uid = this.element.dataset.timepickerRangeUid;

        return this.form.querySelector(`input[data-datepicker-range="${scope}"][data-datepicker-range-uid="${uid}"]`);
    }

    get linkedTimeInput() {
        const scope = this.element.dataset.timepickerRangeLinked;
        if (!scope) {
            return;
        }

        const uid = this.element.dataset.timepickerRangeUid;

        return this.form.querySelector(`input[data-timepicker-range="${scope}"][data-timepicker-range-uid="${uid}"]`);
    }

    get linkedDateInput() {
        const scope = this.element.dataset.timepickerRangeLinked;
        if (!scope) {
            return;
        }

        const uid = this.element.dataset.timepickerRangeUid;

        return this.form.querySelector(`input[data-datepicker-range="${scope}"][data-datepicker-range-uid="${uid}"]`);
    }
}
