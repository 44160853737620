import { Controller } from "@hotwired/stimulus";

const INPUT_SELECTOR = 'input[name][type="checkbox"], input[name][type="radio"]';

export default class FormAjaxToggleController extends Controller {
  connect() {
    $(this.element).on('change', INPUT_SELECTOR, this.onChange);
  }

  disconnect() {
    $(this.element).off('change', INPUT_SELECTOR, this.onChange);
  }

  onChange = (event) => {
    if (!this.forced) {
      const form = event.currentTarget.matches('form') ? event.currentTarget : event.currentTarget.closest('form');

      fetch(form.action || document.location.toString(), {
        method: form.method,
        body: new FormData(form),
        credentials: 'same-origin',
      }).then((response) => {
        return response.json().then(({ message, value }) => {
          this.forced = true;
          event.target.checked = !!value;
          this.forced = false;

          if (!response.ok) {
            console.error(new Error(response.statusText));
            toastr.error(message);
          } else {
            toastr.success(message);
          }
        });
      });
    }
  };
}
