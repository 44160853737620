"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class LinkFetchController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.handleClick = (event) => {
            event.preventDefault();
            if (this.element instanceof HTMLButtonElement) {
                const form = this.element.closest('form');
                const url = this.element.getAttribute('formaction') || form.getAttribute('action') || document.location.href;
                const method = this.element.getAttribute('formmethod') || form.getAttribute('method') || 'GET';
                // @ts-ignore Because when new FormData(form) is called, tinymce not update the textarea
                try {
                    tinymce.triggerSave();
                }
                catch (e) { }
                const data = new FormData(form);
                return this.fetch(url, method, data);
            }
            return this.fetch(this.element.href);
        };
    }
    connect() {
        this.element.addEventListener("click", this.handleClick);
    }
    disconnect() {
        this.element.removeEventListener("click", this.handleClick);
    }
    fetch(url, method = 'GET', body, headers) {
        if (!headers)
            headers = {};
        Object.assign(headers, { "X-Requested-With": "XMLHttpRequest" });
        fetch(url, { method, body, headers }).then((response) => {
            var _a;
            if ((_a = response.headers.get('Content-Type')) === null || _a === void 0 ? void 0 : _a.includes('application/json')) {
                return response.json().then((messages) => {
                    const { success = [], warning = [], error = [], info = [] } = messages;
                    for (let idx = 0; idx < success.length || 0; idx++) {
                        // @ts-ignore
                        window.toastr.success(success[idx]);
                    }
                    for (let idx = 0; idx < warning.length || 0; idx++) {
                        // @ts-ignore
                        window.toastr.warning(warning[idx]);
                    }
                    for (let idx = 0; idx < error.length || 0; idx++) {
                        // @ts-ignore
                        window.toastr.error(error[idx]);
                    }
                    for (let idx = 0; idx < info.length || 0; idx++) {
                        // @ts-ignore
                        window.toastr.info(info[idx]);
                    }
                });
            }
            if (!response.ok) {
                return response.text().then((message) => {
                    // @ts-ignore
                    window.toastr.success(message);
                });
            }
            return Promise.resolve().then(() => {
                // @ts-ignore
                window.toastr.error(response.statusText);
            });
        });
    }
}
exports.default = LinkFetchController;
