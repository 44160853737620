import { Controller } from "@hotwired/stimulus";

export default class FormTimeController extends Controller {
    connect() {
        const icon = this.element.previousElementSibling.matches('.form-control-icon-time')
            ? this.element.previousElementSibling
            : this.element.parentElement.querySelector('.form-control-icon-time');

        $(this.element).mask('00:00');
        $(icon).mdtimepicker({
            format: 'hh:mm',
            timeFormat: 'hh:mm',
            is24hour: true
        }).on('timechanged', (evt) => {
            this.element.value = evt.value;
            $(this.element).trigger('change');
        });
    }

    disconnect() {
        const icon = this.element.previousElementSibling.matches('.form-control-icon-time')
            ? this.element.previousElementSibling
            : this.element.parentElement.querySelector('.form-control-icon-time');

        $(icon).mdtimepicker('destroy');
    }
}
