import { Controller } from "@hotwired/stimulus";

export default class ActionController extends Controller {
    initialize() {
        this.setVisibility(this.element.value);
    }

    connect() {
        this.element.addEventListener('change', this.onChange);
    }

    disconnect() {
        this.element.removeEventListener('change', this.onChange);
    }

    onChange = (event) => {
        this.setVisibility(event.target.value);
    };

    setVisibility = (value) => {
        const details = document.getElementById('mass-generate-details');
        if (details) {
            if (value === 'validate') {
                $(details).collapse('show');
            } else {
                $(details).collapse('hide');
            }
        }
    };
}
