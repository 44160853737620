import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class WizardContractController extends Controller {
    static targets = ['firstColumn', 'secondColumn', 'stepNature', 'stepFunction', 'stepPeriod', 'stepDegree', 'stepSalary'];

    initialize() {
        this.reorganize();
    }

    connect() {
        $('form', this.stepNatureTarget).on('change', ':input[name]', this.onChangeStepNature);
        $('form', this.stepFunctionTarget).on('change', ':input[name]', this.onChangeStepFunction);
        $('form', this.stepPeriodTarget).on('change', ':input[name]', this.onChangeStepPeriod);
        $('form', this.stepDegreeTarget).on('change', ':input[name]', this.onChangeStepDegree);
        $('form', this.stepSalaryTarget).on('change', ':input[name]', this.onChangeStepSalary);

        $('form button[type="submit"]', this.stepNatureTarget).on('click', this.onClickSubmitStepNature);
        $('form button[type="submit"]', this.stepFunctionTarget).on('click', this.onClickSubmitStepFunction);
        $('form button[type="submit"]', this.stepPeriodTarget).on('click', this.onClickSubmitStepPeriod);
        $('form button[type="submit"]', this.stepDegreeTarget).on('click', this.onClickSubmitStepDegree);
        $('form button[type="submit"]', this.stepSalaryTarget).on('click', this.onClickSubmitStepSalary);
    }

    disconnect() {
        $('form', this.stepNatureTarget).off('change', ':input[name]', this.onChangeStepNature);
        $('form', this.stepFunctionTarget).off('change', ':input[name]', this.onChangeStepFunction);
        $('form', this.stepPeriodTarget).off('change', ':input[name]', this.onChangeStepPeriod);
        $('form', this.stepSalaryDegree).off('change', ':input[name]', this.onChangeStepDegree);
        $('form', this.stepSalaryTarget).off('change', ':input[name]', this.onChangeStepSalary);

        $('form button[type="submit"]', this.stepNatureTarget).off('click', this.onClickSubmitStepNature);
        $('form button[type="submit"]', this.stepFunctionTarget).off('click', this.onClickSubmitStepFunction);
        $('form button[type="submit"]', this.stepPeriodTarget).off('click', this.onClickSubmitStepPeriod);
        $('form button[type="submit"]', this.stepDegreeTarget).off('click', this.onClickSubmitStepDegree);
        $('form button[type="submit"]', this.stepSalaryTarget).off('click', this.onClickSubmitStepSalary);
    }

    reorganize = () => {
        if (!this.stepFunctionTarget.innerHTML.trim()) {
            this.firstColumnTarget.setAttribute('class', 'col-12');
            this.secondColumnTarget.setAttribute('class', 'col-0');
            this.stepNatureTarget.setAttribute('class', 'col-12');
            this.stepFunctionTarget.setAttribute('class', 'col-0');
        } else if (!this.stepPeriodTarget.innerHTML.trim()) {
            this.firstColumnTarget.setAttribute('class', 'col-12');
            this.secondColumnTarget.setAttribute('class', 'col-0');
            this.stepNatureTarget.setAttribute('class', 'col-12 col-md-6');
            this.stepFunctionTarget.setAttribute('class', 'col-12 col-md-6');
        } else {
            this.firstColumnTarget.setAttribute('class', 'col-12 col-xl-3');
            this.secondColumnTarget.setAttribute('class', 'col-12 col-xl-9');
            this.stepNatureTarget.setAttribute('class', 'col-12 col-md-6 col-xl-12');
            this.stepFunctionTarget.setAttribute('class', 'col-12 col-md-6 col-xl-12');
        }
    }

    change = (input, panel, nextPanels = []) => {
        return this.send(panel, nextPanels, input);
    };

    submit = (panel, nextPanels = []) => {
        return this.send(panel, nextPanels);
    };

    send = (panel, nextPanels = [], input) => {
        const form = panel.querySelector('form');
        if (!form) return;

        const headers = { 'X-Requested-With': 'XMLHttpRequest' };
        const updater = input?.closest('[data-form-modifiers]');

        if (input) {
            Object.assign(headers, { 'X-Form-Modifier': 'fetch' });
        }
        updater?.dataset.formModifierHeaders?.split(',').forEach((header) => {
            const [name, value] = header.split(':').map((value) => value.trim());
            if (name && value) {
                Object.assign(headers, {
                    [`X-Form-Modifier-${name}`]: value
                });
            }
        });

        const $forms = $([panel, ...nextPanels]).find('form');
        $forms.trigger('form_modifier.process_start');

        return window.fetch(form.action || document.location.toString(), {
            method: form.method || 'POST',
            body: new FormData(form),
            credentials: 'include',
            redirect: 'follow',
            headers,
        }).then((response) => {
            if (response.redirected) document.location = response.url;
            else if (response.ok) return response.text().then((html) => $(html));
            else throw new Error(response.statusText);
        }).then(($html) => {
            $('#alert-session-flash-container').html($html.find('#alert-session-flash-container').html());

            [['success', 'success'], ['error', 'danger']].forEach(([method, className]) => {
                $html.find(`#alert-session-flash-container .alert-${className}`).each(function () {
                    window.toastr[method]($(this).html());
                });
            });

            if (!input) {
                // Add current panel to panel refresh because it's submit
                nextPanels.unshift(panel);
            } else {
                // Update modifier current panel
                const targets = updater?.dataset.formModifiers.split(',').map((selector) => selector.trim()) || [];
                for (let idx = 0, len = targets.length; idx < len; idx++) {
                    const content = $html.find(targets[idx]).html();
                    const $target = $(targets[idx], form);
                    $target.html(content);
                    if ($target.length && content) {
                        window.initFormUi($target);
                    }
                }
            }

            // Update next panels
            for (let idx = 0, len = nextPanels.length; idx < len; idx++) {
                const content = $html.find(`#${nextPanels[idx].id}`).html();
                $(nextPanels[idx]).html(content);
                if (content) {
                    window.initFormUi($(nextPanels[idx]));
                }
            }

            const subheader = $html.find('.page-subheader .rh-wizard-indicator').html();
            $('.page-subheader .rh-wizard-indicator').html(subheader);
            $('.page-subheader-scrolling .rh-wizard-indicator').html(subheader);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            $forms.trigger('form_modifier.process_end');

            if (headers['X-Form-Modifier-Trial-Auto']) {
                const inputTrial = document.querySelector('input[name$="[trialPeriodEnd]"]');
                const alertTrial = document.getElementById('alert-trial-changed-auto');

                if (inputTrial?.dataset.changedAutomatically === '1') {
                    alertTrial?.classList.remove('d-none');
                } else {
                    alertTrial?.classList.add('d-none');
                }
            }

            this.disconnect();
            this.connect();
            this.reorganize();
        });
    };

    onChangeStepNature = (evt) => {
        this.change(evt.target, this.stepNatureTarget, [
            this.stepFunctionTarget,
            this.stepPeriodTarget,
            this.stepDegreeTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepFunction = (evt) => {
        this.change(evt.target, this.stepFunctionTarget, [
            this.stepPeriodTarget,
            this.stepDegreeTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepPeriod = (evt) => {
        this.change(evt.target, this.stepPeriodTarget, [
            this.stepDegreeTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepDegree = (evt) => {
        this.change(evt.target, this.stepDegreeTarget, [
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepSalary = (evt) => {
        this.change(evt.target, this.stepSalaryTarget, [
        ]);
    }

    onClickSubmitStepNature = (event) => {
        event.preventDefault();
        this.submit(this.stepNatureTarget, [
            this.stepFunctionTarget,
        ]);
    };

    onClickSubmitStepFunction = (event) => {
        event.preventDefault();
        this.submit(this.stepFunctionTarget, [
            this.stepPeriodTarget,
            this.stepDegreeTarget,
        ]);
    };

    onClickSubmitStepPeriod = (event) => {
        event.preventDefault();
        this.submit(this.stepPeriodTarget, [
            this.stepDegreeTarget,
            this.stepSalaryTarget,
        ]);
    };

    onClickSubmitStepDegree = (event) => {
        event.preventDefault();
        this.submit(this.stepDegreeTarget, [
            this.stepSalaryTarget,
        ]);
    };

    onClickSubmitStepSalary = (event) => {
        event.preventDefault();
        this.submit(this.stepSalaryTarget, [
            this.stepSalaryTarget,
        ]);
    };
}
