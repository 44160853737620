"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _WysiwygDraggablePageManager_instances, _WysiwygDraggablePageManager_elements, _WysiwygDraggablePageManager_items, _WysiwygDraggablePageManager_getCurrentPositions, _WysiwygDraggablePageManager_moveLinkedKeywords, _WysiwygDraggablePageManager_parse, _WysiwygDraggablePageManager_onButtonClick, _WysiwygDraggablePageManager_onInputChange, _WysiwygDraggablePageManager_onSort, _WysiwygDraggableKeywordManager_instances, _WysiwygDraggableKeywordManager_aliases, _WysiwygDraggableKeywordManager_currentDraggedKeyword, _WysiwygDraggableKeywordManager_elements, _WysiwygDraggableKeywordManager_keywords, _WysiwygDraggableKeywordManager_previousX, _WysiwygDraggableKeywordManager_previousY, _WysiwygDraggableKeywordManager_target, _WysiwygDraggableKeywordManager_appendItem, _WysiwygDraggableKeywordManager_getRelativeRect, _WysiwygDraggableKeywordManager_getUnderlyingKeywords, _WysiwygDraggableKeywordManager_parse, _WysiwygDraggableKeywordManager_onElementContextMenu, _WysiwygDraggableKeywordManager_onElementMove, _WysiwygDraggableKeywordManager_onFakeToggle, _WysiwygDraggableKeywordManager_onInputChange, _WysiwygDraggableKeywordManager_onItemMouseDown, _WysiwygDraggableKeywordManager_onViewerMouseDown, _WysiwygDraggableKeywordManager_onViewerMouseOver, _WysiwygDraggableKeywordManager_onViewerMouseMove, _WysiwygDraggableKeywordManager_onViewerMouseUp, _WysiwygStyleManager_onChange, _WysiwygDraggableController_keyword, _WysiwygDraggableController_page, _WysiwygDraggableController_style;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const async_1 = require("#assets/utils/async");
const dom_1 = require("#assets/utils/dom");
const file_1 = require("#assets/utils/file");
const modal_1 = require("#assets/utils/modal");
require("./draggable_controller.scss");
const PAGE_HEIGHT = 1115;
const PAGE_OUTPUT_HEIGHT = 1100;
const PAGE_WIDTH = 778;
const PAGE_OUTPUT_WIDTH = PAGE_WIDTH;
const OFFSET_KEYWORD_X = 0;
const OFFSET_KEYWORD_Y = -3;
const OFFSET_PAGE_X = 0;
const OFFSET_PAGE_Y = 9;
document.documentElement.style.setProperty('--page-height', `${PAGE_HEIGHT}px`);
document.documentElement.style.setProperty('--page-width', `${PAGE_WIDTH}px`);
let fetchKeywordPromise = null;
function fetchKeywords() {
    if (!fetchKeywordPromise) {
        fetchKeywordPromise = fetch('/api/autocomplete?scope=keywords', {
            credentials: 'same-origin',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        }).then((response) => {
            if (!response.ok)
                throw new Error(response.statusText);
            return response.json();
        }).then((data) => {
            // Convert data to Map
            const keywords = new Map();
            const keys = Object.keys(data.keywords);
            for (let idx = 0; idx < keys.length; idx++) {
                const key = keys[idx];
                const [label, sample] = data.keywords[key];
                keywords.set(key, { key, label, sample });
            }
            return { aliases: data.aliases, keywords };
        });
    }
    return fetchKeywordPromise;
}
function isRectIntersect(rect, refRect) {
    return rect.top <= (refRect.top + refRect.height)
        && (rect.top + rect.height) >= refRect.top
        && rect.left <= (refRect.left + refRect.width)
        && (rect.left + rect.width) >= refRect.left;
}
function getAliases() {
    return __awaiter(this, void 0, void 0, function* () {
        const { aliases } = yield fetchKeywords();
        return aliases;
    });
}
function getKeywords() {
    return __awaiter(this, void 0, void 0, function* () {
        const { keywords } = yield fetchKeywords();
        return keywords;
    });
}
const TEMPLATE_FIELD = (0, dom_1.createTemplate)(`
<div class="wysiwyg-draggable-container">
    <div class="wysiwyg-draggable-viewer">
        <div id="{{id}}_wysiwyg_content" class="wysiwyg-draggable-content"></div>
    </div>
    <aside class="wysiwyg-draggable-sidebar">
        <fieldset class="wysiwyg-draggable-page mb-3">
            <legend>Pages</legend>
            <div class="custom-control custom-switch mb-3">
                <input type="checkbox" id="{{id}}_wysiwyg_page_link" class="custom-control-input" checked="checked" />
                <label class="custom-control-label" for="{{id}}_wysiwyg_page_link">
                    Lier les mots-clés aux pages
                </label>
            </div>
            <ul id="{{id}}_wysiwyg_page_list" class="list-group wysiwyg-draggable-page-list" data-controller="list--sortable"></ul>
            <input type="file" multiple="multiple" accept="image/*,application/pdf" id="{{id}}_wysiwyg_page_upload" class="visually-hidden" />
            <div class="form-actions">
                <button type="button" id="{{id}}_wysiwyg_page_add" class="btn btn-sm btn-success">
                    <span class="fal fa-plus mr-1"></span>
                    Ajouter une page
                </button>
            </div>
        </fieldset>
        <fieldset class="wysiwyg-draggable-keyword-fieldset mb-3">
            <legend>Mots-clés</legend>
            <small class="text-muted mb-2"><span class="fal fa-question-circle mr-1"></span> Utilisez le clic droit pour supprimer un mot-clé dans le document.</small>
            <div class="custom-control custom-switch">
                <input type="checkbox" id="{{id}}_wysiwyg_fake_toggle" class="custom-control-input" />
                <label class="custom-control-label" for="{{id}}_wysiwyg_fake_toggle">
                    Afficher des valeurs factices
                </label>
            </div>
            <div class="form-group form-floating mb-3">
                <input type="text" id="{{id}}_wysiwyg_keyword_search" class="form-control" placeholder="" />
                <label for="{{id}}_wysiwyg_keyword_search">
                    Ajouter du texte ou un mot-clé
                </label>
            </div>
            <div class="dropdown wysiwyg-draggable-keyword-dropdown">
                <a href="#!" class="dropdown-toggle wysiwyg-draggable-keyword-toggle" data-toggle="dropdown" aria-expanded="false">
                    Voir la liste des mots-clés
                </a>
                <menu id="{{id}}_wysiwyg_keyword_menu" class="dropdown-menu dropdown-menu-right wysiwyg-draggable-keyword-menu"></menu>
            </div>
        </fieldset>
    </aside>
    <div class="loader--page">
        <div><div><div><div><div><div><div></div></div></div></div></div></div></div>
    </div>
</div>
`);
const TEMPLATE_CONTENT = (0, dom_1.createTemplate)(`
<style>
    #content { display: flex; flex-flow: column nowrap; gap: ${OFFSET_PAGE_Y}px; }
    img { box-sizing: border-box; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5); }
    p { cursor: crosshair; user-select: none; }
    p:hover { background: white; z-index: 1000; }
    p.dragging { cursor: move; opacity: .5; }
    p.ghosted { opacity: .2; }
</style>
<style id="custom-styles"></style>
<div id="content"></div>
`);
const TEMPLATE_PAGE_ITEM = (0, dom_1.createTemplate)(`
<span class="fal fa-bars flex-0" data-list--sortable-target="selector"></span>
<span class="flex-1 px-2">{{filename}}</span>
<a href="#!" class="flex-0 text-danger" title="Supprimer">&times;</a>
`);
class WysiwygDraggablePageManager {
    static createElement(src, filename) {
        const img = document.createElement('img');
        img.dataset.filename = filename;
        img.height = PAGE_HEIGHT;
        img.src = src;
        img.width = PAGE_WIDTH;
        return img;
    }
    static createItem(filename) {
        const item = document.createElement('li');
        item.setAttribute('data-list--sortable-target', 'item');
        item.classList.add('list-group-item');
        item.appendChild(TEMPLATE_PAGE_ITEM.content.cloneNode(true));
        item.innerHTML = item.innerHTML.replace(/{{filename}}/g, filename);
        return item;
    }
    constructor(controller, keyword, _textarea, _viewer) {
        this.controller = controller;
        this.keyword = keyword;
        this._textarea = _textarea;
        this._viewer = _viewer;
        _WysiwygDraggablePageManager_instances.add(this);
        _WysiwygDraggablePageManager_elements.set(this, void 0);
        _WysiwygDraggablePageManager_items.set(this, void 0);
        _WysiwygDraggablePageManager_onButtonClick.set(this, (input) => (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            input.click();
        });
        _WysiwygDraggablePageManager_onInputChange.set(this, (button, input) => (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            button.classList.add('disabled');
            button.toggleAttribute('disabled', true);
            const files = Array.from(input.files || []);
            input.files = null;
            input.type = 'hidden';
            input.value = '';
            input.type = 'file';
            const promises = files.map((file) => (0, file_1.fileToBase64)(file).then((sources) => [sources, file.name]));
            Promise.all(promises).then((results) => {
                results.forEach(([sources, filename]) => {
                    if (!sources.length)
                        return;
                    if (sources.length === 1) {
                        this.add(sources[0], filename, __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").size);
                    }
                    else {
                        for (let idx = 0; idx < sources.length; idx++) {
                            this.add(sources[idx], `${filename} - Page ${idx + 1}`, __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").size);
                        }
                    }
                });
                this.controller.serialize();
            }).finally(() => {
                button.classList.remove('disabled');
                button.toggleAttribute('disabled', false);
            });
        });
        _WysiwygDraggablePageManager_onSort.set(this, () => {
            const entries = Array.from(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").entries());
            const positions = __classPrivateFieldGet(this, _WysiwygDraggablePageManager_instances, "m", _WysiwygDraggablePageManager_getCurrentPositions).call(this);
            // Sort image by descending order in list
            entries.sort((firstEntry, secondEntry) => {
                const [firstImg] = firstEntry;
                const [secondImg] = secondEntry;
                const firstIdx = (0, dom_1.getIndex)(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_items, "f").get(firstImg));
                const secondIdx = (0, dom_1.getIndex)(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_items, "f").get(secondImg));
                return secondIdx - firstIdx;
            });
            // Move images in viewer
            this._viewer.querySelectorAll('img').forEach((img) => img.remove());
            entries.forEach(([img]) => this._viewer.prepend(img));
            // Sort images by ascending order
            entries.reverse();
            // Reset map
            __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").clear();
            entries.forEach(([img, [src, filename]], idx) => __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").set(img, [src, filename, idx]));
            // Move keywords if linked
            if (this._toggle.checked) {
                __classPrivateFieldGet(this, _WysiwygDraggablePageManager_instances, "m", _WysiwygDraggablePageManager_moveLinkedKeywords).call(this, positions);
            }
            this.controller.serialize();
        });
        this._toggle = document.getElementById(`${this._textarea.id}_wysiwyg_page_link`);
        this._list = document.getElementById(`${this._textarea.id}_wysiwyg_page_list`);
        __classPrivateFieldSet(this, _WysiwygDraggablePageManager_elements, new Map(), "f");
        __classPrivateFieldSet(this, _WysiwygDraggablePageManager_items, new Map(), "f");
    }
    initialize() {
        __classPrivateFieldGet(this, _WysiwygDraggablePageManager_instances, "m", _WysiwygDraggablePageManager_parse).call(this);
        const button = document.getElementById(`${this._textarea.id}_wysiwyg_page_add`);
        const input = document.getElementById(`${this._textarea.id}_wysiwyg_page_upload`);
        button.addEventListener('click', __classPrivateFieldGet(this, _WysiwygDraggablePageManager_onButtonClick, "f").call(this, input));
        input.addEventListener('change', __classPrivateFieldGet(this, _WysiwygDraggablePageManager_onInputChange, "f").call(this, button, input));
        this._list.addEventListener('list--sortable::change', (0, async_1.throttle)(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_onSort, "f"), 10));
    }
    add(src, filename, idx) {
        // Add page to viewer
        const img = WysiwygDraggablePageManager.createElement(src, filename);
        __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").set(img, [src, filename, idx]);
        this._viewer.appendChild(img);
        // Add page to list
        const item = WysiwygDraggablePageManager.createItem(filename);
        this._list.appendChild(item);
        __classPrivateFieldGet(this, _WysiwygDraggablePageManager_items, "f").set(img, item);
        item.querySelector('a').addEventListener('mousedown', (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.delete(img);
        });
    }
    delete(img) {
        const currentIdx = __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").get(img)[2];
        const closure = () => {
            var _a;
            (_a = __classPrivateFieldGet(this, _WysiwygDraggablePageManager_items, "f").get(img)) === null || _a === void 0 ? void 0 : _a.remove();
            img.remove();
            __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").delete(img);
            __classPrivateFieldGet(this, _WysiwygDraggablePageManager_items, "f").delete(img);
            __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").forEach(([_, __, idx], element) => {
                if (idx < currentIdx)
                    return;
                __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").set(element, [_, __, idx - 1]);
            });
        };
        if (!this._toggle.checked) {
            closure();
            return;
        }
        (0, modal_1.confirm)(WysiwygDraggablePageManager.CONFIRM_MESSAGE, () => {
            const entries = __classPrivateFieldGet(this, _WysiwygDraggablePageManager_instances, "m", _WysiwygDraggablePageManager_getCurrentPositions).call(this);
            closure();
            __classPrivateFieldGet(this, _WysiwygDraggablePageManager_instances, "m", _WysiwygDraggablePageManager_moveLinkedKeywords).call(this, entries);
        }, {
            title: WysiwygDraggablePageManager.CONFIRM_TITLE,
        });
    }
    serialize() {
        const container = document.createElement('div');
        __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").forEach(([src, filename]) => {
            const img = WysiwygDraggablePageManager.createElement(src, filename);
            container.appendChild(img);
        });
        return container.innerHTML;
    }
}
_WysiwygDraggablePageManager_elements = new WeakMap(), _WysiwygDraggablePageManager_items = new WeakMap(), _WysiwygDraggablePageManager_onButtonClick = new WeakMap(), _WysiwygDraggablePageManager_onInputChange = new WeakMap(), _WysiwygDraggablePageManager_onSort = new WeakMap(), _WysiwygDraggablePageManager_instances = new WeakSet(), _WysiwygDraggablePageManager_getCurrentPositions = function _WysiwygDraggablePageManager_getCurrentPositions() {
    return Array.from(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").entries()).map(([img, [_, __, idx]]) => [img, idx]);
}, _WysiwygDraggablePageManager_moveLinkedKeywords = function _WysiwygDraggablePageManager_moveLinkedKeywords(entries) {
    const buffer = [];
    // Retrieve old and current position with their keywords
    for (let idx = 0; idx < entries.length; idx++) {
        const [img, previousIdx] = entries[idx];
        const rect = new DOMRect(0, previousIdx * (PAGE_HEIGHT + OFFSET_PAGE_Y), PAGE_WIDTH, PAGE_HEIGHT);
        const elements = this.keyword.getElementsForRect(rect);
        const [_, __, currentIdx] = __classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").get(img) || [];
        if (undefined === currentIdx) {
            elements.forEach((element) => this.keyword.delete(element));
        }
        else if (previousIdx !== currentIdx) {
            buffer.push([currentIdx - previousIdx, elements]);
        }
    }
    // Move keywords to new position
    for (let idx = 0; idx < buffer.length; idx++) {
        const [offsetIdx, elements] = buffer[idx];
        const offsetY = offsetIdx * (PAGE_HEIGHT + OFFSET_PAGE_Y);
        elements.forEach((element) => {
            this.keyword.moveElement(element, { offsetY });
        });
    }
    // Save change into textarea
    this.controller.serialize();
}, _WysiwygDraggablePageManager_parse = function _WysiwygDraggablePageManager_parse() {
    // Clear images and items
    Array.from(__classPrivateFieldGet(this, _WysiwygDraggablePageManager_elements, "f").keys()).forEach((img) => this.delete(img));
    const container = document.createElement('div');
    container.innerHTML = this._textarea.value;
    container.querySelectorAll('img').forEach((source, idx) => {
        this.add(source.src, source.dataset.filename, idx);
    });
};
WysiwygDraggablePageManager.CONFIRM_MESSAGE = "Vous allez supprimer une page, puisque les mots-clés sont liés aux pages, ceux contenu sur cette page seront également supprimés.<br />Êtes-vous sûr de vouloir continuer ?";
WysiwygDraggablePageManager.CONFIRM_TITLE = "Suppression de page";
class WysiwygDraggableKeywordManager {
    static createElement(content, top, left) {
        const element = document.createElement('p');
        element.style.position = 'absolute';
        element.style.top = `${Number.isNaN(top) ? 0 : top}px`;
        element.style.left = `${Number.isNaN(left) ? 0 : left}px`;
        element.innerHTML = content;
        return element;
    }
    static createItem(keyword) {
        const item = document.createElement('li');
        item.classList.add('dropdown-item');
        item.innerHTML = typeof keyword === 'string'
            ? `<b>${keyword}</b>`
            : `<b>#${keyword.key}#</b><br/><span>${keyword.label}</span>`;
        return item;
    }
    constructor(controller, _textarea, _viewer) {
        this.controller = controller;
        this._textarea = _textarea;
        this._viewer = _viewer;
        _WysiwygDraggableKeywordManager_instances.add(this);
        _WysiwygDraggableKeywordManager_aliases.set(this, void 0);
        _WysiwygDraggableKeywordManager_currentDraggedKeyword.set(this, void 0);
        _WysiwygDraggableKeywordManager_elements.set(this, void 0);
        _WysiwygDraggableKeywordManager_keywords.set(this, void 0);
        _WysiwygDraggableKeywordManager_previousX.set(this, void 0);
        _WysiwygDraggableKeywordManager_previousY.set(this, void 0);
        _WysiwygDraggableKeywordManager_target.set(this, void 0);
        this.moveElement = (element, opts) => {
            const refRect = this._viewer.getBoundingClientRect();
            const [currentRect] = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").get(element);
            function getValue(refValue, currentValue, absoluteValue, relativeValue, offsetValue) {
                if (absoluteValue)
                    return absoluteValue - refValue;
                if (relativeValue)
                    return relativeValue;
                if (offsetValue)
                    return currentValue + offsetValue;
                return currentValue;
            }
            const x = getValue(refRect.left, currentRect.left, opts.absoluteX, opts.relativeX, opts.offsetX);
            const y = getValue(refRect.top, currentRect.top, opts.absoluteY, opts.relativeY, opts.offsetY);
            let rect = new DOMRect(x, y, currentRect.width, currentRect.height);
            // Update element position
            element.style.top = `${rect.top}px`;
            element.style.left = `${rect.left}px`;
            // Update element in map
            const [_, keyword] = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").get(element);
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").set(element, [rect, keyword]);
        };
        _WysiwygDraggableKeywordManager_onElementContextMenu.set(this, (element) => (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.delete(element);
            this.controller.serialize();
        });
        _WysiwygDraggableKeywordManager_onElementMove.set(this, (element) => {
            if (!element)
                return;
            const [_, keyword] = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").get(element);
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").set(element, [
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_getRelativeRect).call(this, element.getBoundingClientRect()),
                keyword
            ]);
        });
        _WysiwygDraggableKeywordManager_onFakeToggle.set(this, (input) => () => {
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").forEach(([_, keyword], element) => {
                if (typeof keyword !== 'string') {
                    if (input.checked) {
                        element.innerHTML = element.innerHTML.replace(`#${keyword.key}#`, keyword.sample);
                    }
                    else {
                        element.innerHTML = element.innerHTML.replace(keyword.sample, `#${keyword.key}#`);
                    }
                }
            });
        });
        _WysiwygDraggableKeywordManager_onInputChange.set(this, (input) => () => {
            const value = input.value.toLowerCase();
            this.controller.element.dataset.keywordQuery = value;
            this._menu.innerHTML = '';
            if (!value) {
                // Add all values if no query
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_keywords, "f").forEach((keyword) => {
                    __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_appendItem).call(this, keyword);
                });
            }
            else {
                // Add raw content to choices
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_appendItem).call(this, input.value);
                // Add matching keywords
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_keywords, "f").forEach((keyword) => {
                    if (keyword.key.toLowerCase().includes(value)) {
                        __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_appendItem).call(this, keyword);
                    }
                    else if (keyword.label.toLowerCase().includes(value)) {
                        __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_appendItem).call(this, keyword);
                    }
                });
            }
        });
        _WysiwygDraggableKeywordManager_onItemMouseDown.set(this, (value) => (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_currentDraggedKeyword, value, "f");
        });
        _WysiwygDraggableKeywordManager_onViewerMouseDown.set(this, (evt) => {
            if (evt.target instanceof HTMLParagraphElement) {
                evt.preventDefault();
                __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_target, evt.target, "f");
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").classList.add('dragging');
                __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousX, evt.clientX, "f");
                __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousY, evt.clientY, "f");
            }
        });
        _WysiwygDraggableKeywordManager_onViewerMouseOver.set(this, (0, async_1.throttle)((evt) => {
            if (!__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_currentDraggedKeyword, "f"))
                return;
            const value = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_currentDraggedKeyword, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_currentDraggedKeyword, undefined, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_target, this.add(value, evt.clientY, evt.clientX), "f");
            this.moveElement(__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f"), { absoluteX: evt.clientX, absoluteY: evt.clientY });
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousX, evt.clientX, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousY, evt.clientY, "f");
            // @ts-ignore Bootstrap jQuery methods
            $(this._menu).dropdown('hide');
            // Save change into textarea
            this.controller.serialize();
        }, 15));
        _WysiwygDraggableKeywordManager_onViewerMouseMove.set(this, (0, async_1.throttle)((evt) => {
            if (!__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f"))
                return;
            const top = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.top ? Number(__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.top.slice(0, -2)) : 0;
            const left = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.left ? Number(__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.left.slice(0, -2)) : 0;
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.top = `${top + (evt.clientY - __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_previousY, "f"))}px`;
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").style.left = `${left + (evt.clientX - __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_previousX, "f"))}px`;
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousX, evt.clientX, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousY, evt.clientY, "f");
            this._viewer.querySelectorAll('.ghosted').forEach((element) => element.classList.remove('ghosted'));
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_getUnderlyingKeywords).call(this).forEach((text) => text.classList.add('ghosted'));
        }, 100));
        _WysiwygDraggableKeywordManager_onViewerMouseUp.set(this, () => {
            var _a;
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onElementMove, "f").call(this, __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f"));
            this._viewer.querySelectorAll('.ghosted').forEach((element) => element.classList.remove('ghosted'));
            (_a = this._viewer.querySelector('.dragging')) === null || _a === void 0 ? void 0 : _a.classList.remove('dragging');
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_currentDraggedKeyword, undefined, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousX, undefined, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousY, undefined, "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_target, undefined, "f");
            this.controller.serialize();
        });
        this._menu = document.getElementById(`${this._textarea.id}_wysiwyg_keyword_menu`);
        __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_elements, new Map(), "f");
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_aliases, yield getAliases(), "f");
            __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_keywords, yield getKeywords(), "f");
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_parse).call(this);
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_keywords, "f").forEach((keyword) => {
                __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_appendItem).call(this, keyword);
            });
            const search = document.getElementById(`${this._textarea.id}_wysiwyg_keyword_search`);
            search.addEventListener('keyup', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onInputChange, "f").call(this, search));
            const toggle = document.getElementById(`${this._textarea.id}_wysiwyg_fake_toggle`);
            toggle.addEventListener('change', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onFakeToggle, "f").call(this, toggle));
            this._viewer.addEventListener('mousedown', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseDown, "f"));
            this._viewer.addEventListener('mouseover', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseOver, "f"));
            this._viewer.addEventListener('mousemove', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseMove, "f"));
            const globalStyle = document.querySelector(this._textarea.dataset.styles);
            if (!globalStyle.value.length) {
                globalStyle.value = `p { margin: 0; padding: 0; color: #000000; font-size: 13px; font-family: Calibri; white-space: normal; }`;
                globalStyle.dispatchEvent(new Event('change'));
            }
        });
    }
    connect() {
        document.addEventListener('mouseup', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseUp, "f"));
        document.addEventListener('mouseleave', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseUp, "f"));
    }
    disconnect() {
        document.removeEventListener('mouseup', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseUp, "f"));
        document.removeEventListener('mouseleave', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onViewerMouseUp, "f"));
    }
    add(content, top, left) {
        top = Number.isNaN(top) ? 0 : top;
        left = Number.isNaN(left) ? 0 : left;
        // Initialize element
        const element = WysiwygDraggableKeywordManager.createElement(content, top, left);
        this._viewer.appendChild(element);
        element.addEventListener('contextmenu', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onElementContextMenu, "f").call(this, element));
        const isFakeToggled = document.getElementById(`${this._textarea.id}_wysiwyg_fake_toggle`).checked;
        const keyword = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_keywords, "f").get(content.trim().slice(1, -1)) || content;
        if (isFakeToggled && typeof keyword !== 'string') {
            element.innerHTML = keyword.sample;
        }
        // Save element into map
        __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").set(element, [
            __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_getRelativeRect).call(this, element.getBoundingClientRect()),
            keyword
        ]);
        return element;
    }
    delete(element) {
        __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").delete(element);
        __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_target, undefined, "f");
        __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousX, undefined, "f");
        __classPrivateFieldSet(this, _WysiwygDraggableKeywordManager_previousY, undefined, "f");
        element.remove();
    }
    getElementsForRect(rect) {
        return Array.from(__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").entries()).filter(([_, [refRect]]) => {
            return isRectIntersect(refRect, rect);
        }).map(([element]) => {
            return element;
        });
    }
    serialize() {
        const template = document.createElement('div');
        __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").forEach(([rect, keyword]) => {
            const content = typeof keyword === 'string' ? keyword : `#${keyword.key}#`;
            const child = WysiwygDraggableKeywordManager.createElement(content, rect.top - OFFSET_KEYWORD_Y, rect.left - OFFSET_KEYWORD_X);
            template.appendChild(child);
        });
        return template.innerHTML;
    }
}
_WysiwygDraggableKeywordManager_aliases = new WeakMap(), _WysiwygDraggableKeywordManager_currentDraggedKeyword = new WeakMap(), _WysiwygDraggableKeywordManager_elements = new WeakMap(), _WysiwygDraggableKeywordManager_keywords = new WeakMap(), _WysiwygDraggableKeywordManager_previousX = new WeakMap(), _WysiwygDraggableKeywordManager_previousY = new WeakMap(), _WysiwygDraggableKeywordManager_target = new WeakMap(), _WysiwygDraggableKeywordManager_onElementContextMenu = new WeakMap(), _WysiwygDraggableKeywordManager_onElementMove = new WeakMap(), _WysiwygDraggableKeywordManager_onFakeToggle = new WeakMap(), _WysiwygDraggableKeywordManager_onInputChange = new WeakMap(), _WysiwygDraggableKeywordManager_onItemMouseDown = new WeakMap(), _WysiwygDraggableKeywordManager_onViewerMouseDown = new WeakMap(), _WysiwygDraggableKeywordManager_onViewerMouseOver = new WeakMap(), _WysiwygDraggableKeywordManager_onViewerMouseMove = new WeakMap(), _WysiwygDraggableKeywordManager_onViewerMouseUp = new WeakMap(), _WysiwygDraggableKeywordManager_instances = new WeakSet(), _WysiwygDraggableKeywordManager_appendItem = function _WysiwygDraggableKeywordManager_appendItem(keyword) {
    const value = typeof keyword === 'string' ? keyword : `#${keyword.key}#`;
    const item = WysiwygDraggableKeywordManager.createItem(keyword);
    item.addEventListener('mousedown', __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_onItemMouseDown, "f").call(this, value));
    this._menu.appendChild(item);
}, _WysiwygDraggableKeywordManager_getRelativeRect = function _WysiwygDraggableKeywordManager_getRelativeRect(rect, refRect) {
    if (!refRect)
        refRect = this._viewer.getBoundingClientRect();
    return new DOMRect(rect.left - refRect.left, rect.top - refRect.top, rect.width, rect.height);
}, _WysiwygDraggableKeywordManager_getUnderlyingKeywords = function _WysiwygDraggableKeywordManager_getUnderlyingKeywords() {
    const results = [];
    if (!__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f"))
        return results;
    const refRect = __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_instances, "m", _WysiwygDraggableKeywordManager_getRelativeRect).call(this, __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f").getBoundingClientRect());
    __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").forEach(([rect], element) => {
        if (element === __classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_target, "f"))
            return;
        if (isRectIntersect(rect, refRect)) {
            results.push(element);
        }
    });
    return results;
}, _WysiwygDraggableKeywordManager_parse = function _WysiwygDraggableKeywordManager_parse() {
    // Clear elements
    Array.from(__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_elements, "f").keys()).forEach((element) => this.delete(element));
    const container = document.createElement('div');
    container.innerHTML = this._textarea.value;
    container.querySelectorAll('p').forEach((source) => {
        const x = Number(source.style.left.slice(0, -2)) + OFFSET_KEYWORD_X;
        const y = Number(source.style.top.slice(0, -2)) + OFFSET_KEYWORD_Y;
        let content = source.innerHTML || '';
        const code = content.trim();
        if (__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_aliases, "f")[code.slice(1, -1)]) {
            content = content.replace(code, `#${__classPrivateFieldGet(this, _WysiwygDraggableKeywordManager_aliases, "f")[code.slice(1, -1)]}#`);
            // content = `#${this.#aliases[content.slice(1, -1)]}#`;
        }
        this.add(content, y, x);
    });
};
class WysiwygStyleManager {
    constructor(textarea, shadow) {
        _WysiwygStyleManager_onChange.set(this, () => {
            this._style.innerHTML = this._textarea.value;
        });
        this._textarea = document.querySelector(textarea.dataset.styles);
        this._style = shadow.getElementById('custom-styles');
    }
    initialize() {
        __classPrivateFieldGet(this, _WysiwygStyleManager_onChange, "f").call(this);
    }
    connect() {
        this._textarea.addEventListener('change', __classPrivateFieldGet(this, _WysiwygStyleManager_onChange, "f"));
    }
    disconnect() {
        this._textarea.removeEventListener('change', __classPrivateFieldGet(this, _WysiwygStyleManager_onChange, "f"));
    }
}
_WysiwygStyleManager_onChange = new WeakMap();
class WysiwygDraggableController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _WysiwygDraggableController_keyword.set(this, void 0);
        _WysiwygDraggableController_page.set(this, void 0);
        _WysiwygDraggableController_style.set(this, void 0);
        this.serialize = (0, async_1.throttle)(() => {
            const content = [
                __classPrivateFieldGet(this, _WysiwygDraggableController_page, "f").serialize(),
                __classPrivateFieldGet(this, _WysiwygDraggableController_keyword, "f").serialize(),
            ].join('\n');
            this.element.querySelector('textarea').value = `<div style="position:relative;width:${PAGE_OUTPUT_WIDTH}px;height:${PAGE_OUTPUT_HEIGHT}px;">${content}</div>`;
        }, 150);
    }
    initialize() {
        const textarea = this.element.querySelector('textarea');
        textarea.before((0, dom_1.createModel)(TEMPLATE_FIELD, { id: textarea.id }));
        const content = document.getElementById(`${textarea.id}_wysiwyg_content`);
        const shadow = content.attachShadow({ mode: 'open' });
        shadow.appendChild(TEMPLATE_CONTENT.content.cloneNode(true));
        const viewer = shadow.getElementById('content');
        __classPrivateFieldSet(this, _WysiwygDraggableController_keyword, new WysiwygDraggableKeywordManager(this, textarea, viewer), "f");
        __classPrivateFieldSet(this, _WysiwygDraggableController_page, new WysiwygDraggablePageManager(this, __classPrivateFieldGet(this, _WysiwygDraggableController_keyword, "f"), textarea, viewer), "f");
        __classPrivateFieldSet(this, _WysiwygDraggableController_style, new WysiwygStyleManager(textarea, shadow), "f");
        fetchKeywords().then(() => {
            return __classPrivateFieldGet(this, _WysiwygDraggableController_page, "f").initialize();
        }).then(() => {
            return __classPrivateFieldGet(this, _WysiwygDraggableController_style, "f").initialize();
        }).then(() => {
            return __classPrivateFieldGet(this, _WysiwygDraggableController_keyword, "f").initialize();
        }).then(() => {
            this.element.classList.add('ready');
        });
    }
    connect() {
        __classPrivateFieldGet(this, _WysiwygDraggableController_keyword, "f").connect();
        __classPrivateFieldGet(this, _WysiwygDraggableController_style, "f").connect();
    }
    disconnect() {
        __classPrivateFieldGet(this, _WysiwygDraggableController_keyword, "f").disconnect();
        __classPrivateFieldGet(this, _WysiwygDraggableController_style, "f").disconnect();
    }
}
exports.default = WysiwygDraggableController;
_WysiwygDraggableController_keyword = new WeakMap(), _WysiwygDraggableController_page = new WeakMap(), _WysiwygDraggableController_style = new WeakMap();
