import { Controller } from "@hotwired/stimulus";

export default class IconViewerController extends Controller {
    file = this.element.querySelector('input[type="file"]');
    icon = this.element.querySelector('input[data-controller~="form--select-fontawesome"]');
    viewer = this.element.querySelector('.viewer');

    connect() {
        this.file.addEventListener('change', this.onChangeFileInput);
        this.icon.addEventListener('change', this.onChangeIconInput);
    }

    disconnect() {
        this.file.removeEventListener('change', this.onChangeFileInput);
        this.icon.removeEventListener('change', this.onChangeIconInput);
    }

    onChangeFileInput = (evt) => {
        if (evt.target.files && evt.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener('load', (evt) => {
                this.viewer.innerHTML = `<img src="${evt.target.result}" alt="${evt.target.value}" style="height: 2.75rem;" />`;
            });

            reader.readAsDataURL(evt.target.files[0]);
        }
    };

    onChangeIconInput = (evt) => {
        if (!this.viewer.querySelector('img')) {
            this.viewer.innerHTML = `<span class="${evt.target.value} fa-fw" style="font-size: 2rem;"></span>`;
        }
    };
}
