import { Controller } from "@hotwired/stimulus";

// @todo see https://www.youtube.com/watch?v=KrPz_wmBsAE
// @todo see https://codepen.io/kevinpowell/pen/yLaYxab

export default class LayoutMasonryController extends Controller {
    initialize() {
        this.masonry = new Masonry(this.element, { percentPosition: true });
    }

    connect() {
        this.timeout = window.setTimeout(() => {
            this.masonry.layout();
            this.timeout = window.setTimeout(() => {
                this.masonry.layout();
            }, 1000);
        }, 500);

        document.addEventListener('DOMContentLoaded', () => {
            this.masonry.layout();
        });
    }

    disconnect() {
        window.clearTimeout(this.timeout);
        this.masonry.destroy();
    }
}
