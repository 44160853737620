import { Controller } from "@hotwired/stimulus";

export default class FormFeedbackController extends Controller {
    connect() {
        this.element.addEventListener('submit', this.onSubmit);
        // this.element.addEventListener('change', this.onChange);
        $(':input', this.element).on('change', this.onChange);
    }

    disconnect() {
        this.element.removeEventListener('submit', this.onSubmit);
    }

    onSubmit = () => {
        this.element.querySelectorAll('button[type="submit"]').forEach((button) => {
            button.disabled = true;
            button.classList.add('disabled');
        });
    }

    onChange = () => {
        this.element.querySelectorAll('button[type="submit"]').forEach((button) => {
            if (button.disabled) {
                button.disabled = false;
                button.classList.remove('disabled');
            }
        });
    }
}
