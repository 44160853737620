import { Controller } from "@hotwired/stimulus";

let prompter;
let listener;

window.addEventListener("beforeinstallprompt", (evt) => {
  evt.preventDefault();
  prompter = evt;
  listener = () => {
    if (prompter) {
      prompter.prompt();
      prompter.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("Icon added to homescreen");
        } else {
          console.log("Icon not added to homescreen");
        }
        prompter = null;
      });
    }
  };
});

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.ath-external-link').forEach((link) => {
    link.addEventListener('click', (evt) => {
      evt.preventDefault();
      $(link).popover('show');
    });
  });
});

export default class AddToHomeScreenController extends Controller {
  initialize() {
    this.modal = document.getElementById('modal-ath');

    const navigators = {
      android: /Android/i.test(navigator.userAgent),
      ios: /(iPad|iPhone|iPod)/i.test(navigator.userAgent),
      firefox: /Firefox/i.test(navigator.userAgent),
      edge: /Edge/i.test(navigator.userAgent),
      opera: /OPR/i.test(navigator.userAgent),
      safari: /Safari/i.test(navigator.userAgent) && /Apple Computer, Inc/i.test(navigator.vendor),
      chrome: !/OPR/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent) && /Google Inc/i.test(navigator.vendor),
    };

    $('#ath-tabs a').css({ 'user-select': 'none' });
    if (navigators.android) {
      $('#ath-chrome-tab').tab('show');
    } else if (navigators.ios) {
      $('#ath-safari-tab').tab('show');
    } else if (navigators.safari) {
      $('#ath-ios-tab').tab('show');
    } else {
      $('#ath-android-tab').tab('show');
    }

    if (navigators.android || navigators.ios) {
      document.getElementById('ath-web-content').classList.add('d-none');
    } else {
      document.getElementById('ath-mobile-content').classList.add('d-none');
    }

    if (navigators.android) {
      $('#ath-mobile-tab').tab('show');
      $('#ath-android-tab').tab('show');
    } else if (navigators.ios) {
      $('#ath-mobile-tab').tab('show');
      $('#ath-ios-tab').tab('show');
    } else if (navigators.firefox) {
      $('#ath-web-tab').tab('show');
      $('#ath-firefox-tab').tab('show');
    } else if (navigators.edge) {
      $('#ath-web-tab').tab('show');
      $('#ath-edge-tab').tab('show');
    } else if (navigators.safari) {
      $('#ath-web-tab').tab('show');
      $('#ath-safari-tab').tab('show');
    } else if (navigators.opera) {
      $('#ath-web-tab').tab('show');
      $('#ath-opera-tab').tab('show');
    } else if (navigators.chrome) {
      $('#ath-web-tab').tab('show');
      $('#ath-chrome-tab').tab('show');
    } else {
      $('#ath-web-tab').tab('show');
      $('#ath-web-other-tab').tab('show');
    }
  }

  connect() {
    this.element.addEventListener('click', this.onClick);
    $(document).on('click', '#modal-ath #main-tabs li a', this.onClickMainTab);
  }

  disconnect() {
    this.element.removeEventListener('click', this.onClick);
    $(document).off('click', '#modal-ath #main-tabs li a', this.onClickMainTab);
  }

  onClickMainTab = (evt) => {
    if (evt.currentTarget.matches('#ath-mobile-tab')) {
      document.getElementById('ath-mobile-content').classList.remove('d-none');
      document.getElementById('ath-web-content').classList.add('d-none');
    } else {
      document.getElementById('ath-mobile-content').classList.add('d-none');
      document.getElementById('ath-web-content').classList.remove('d-none');
    }
  };

  onClick = (evt) => {
    evt.preventDefault();
    if (listener) {
      listener();
    } else {
      $(this.modal).modal('show');
    }
  };
}
