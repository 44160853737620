"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FormPinCodeController_instances, _FormPinCodeController__form, _FormPinCodeController__input, _FormPinCodeController__container, _FormPinCodeController__inputs, _FormPinCodeController_count, _FormPinCodeController_appendInput, _FormPinCodeController_onFormSubmit, _FormPinCodeController_onInputClick, _FormPinCodeController_onInputKeyDown, _FormPinCodeController_onInputPaste;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
require("./pin-code_controller.scss");
const PIN_CODE_DEFAULT_LENGTH = 6;
class FormPinCodeController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _FormPinCodeController_instances.add(this);
        _FormPinCodeController__form.set(this, void 0);
        _FormPinCodeController__input.set(this, void 0);
        _FormPinCodeController__container.set(this, void 0);
        _FormPinCodeController__inputs.set(this, []);
        _FormPinCodeController_count.set(this, PIN_CODE_DEFAULT_LENGTH);
        _FormPinCodeController_onFormSubmit.set(this, () => {
            const button = __classPrivateFieldGet(this, _FormPinCodeController__form, "f").querySelector("button[type=submit]");
            button.classList.add('disabled');
            button.setAttribute('disabled', 'disabled');
            const icon = button.querySelector(".fal, .fas, .far, .fat, .fad");
            icon.remove();
            const loader = document.createElement('span');
            loader.classList.add('spinner-grow', 'spinner-grow-sm');
            button.prepend(loader);
        });
        _FormPinCodeController_onInputClick.set(this, () => {
            var _a;
            const length = __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value.length;
            (_a = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[Math.max(0, Math.min(__classPrivateFieldGet(this, _FormPinCodeController__inputs, "f").length - 1, length))]) === null || _a === void 0 ? void 0 : _a.focus();
        });
        _FormPinCodeController_onInputKeyDown.set(this, (evt) => {
            const target = evt.target;
            const previous = target.previousElementSibling;
            const next = target.nextElementSibling;
            if ('Backspace' === evt.code) {
                evt.preventDefault();
                evt.stopPropagation();
                let nextSibling = next;
                while (nextSibling) {
                    nextSibling.value = '';
                    nextSibling = nextSibling.nextElementSibling;
                }
                if (previous) {
                    previous.value = '';
                    previous.focus();
                }
                __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value = __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value.slice(0, -1);
            }
            else if ('0123456789'.split('').includes(evt.key)) {
                evt.preventDefault();
                evt.stopPropagation();
                __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value += evt.key;
                target.value = evt.key;
                next === null || next === void 0 ? void 0 : next.focus();
                if (__classPrivateFieldGet(this, _FormPinCodeController_count, "f") === __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value.length) {
                    __classPrivateFieldGet(this, _FormPinCodeController__form, "f").submit();
                }
            }
            else if (!(evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        });
        _FormPinCodeController_onInputPaste.set(this, (evt) => {
            var _a, _b;
            evt.preventDefault();
            evt.stopPropagation();
            const chars = (_a = evt.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text').split('');
            if (!(chars === null || chars === void 0 ? void 0 : chars.length))
                return;
            const numbers = '0123456789'.split('');
            if (!chars.every(char => numbers.includes(char)))
                return;
            for (let idx = 0; idx < __classPrivateFieldGet(this, _FormPinCodeController_count, "f"); idx++) {
                __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx].value = chars[idx];
            }
            __classPrivateFieldGet(this, _FormPinCodeController__input, "f").value = chars.join('').slice(0, __classPrivateFieldGet(this, _FormPinCodeController_count, "f"));
            (_b = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[__classPrivateFieldGet(this, _FormPinCodeController__input, "f").value.length - 1]) === null || _b === void 0 ? void 0 : _b.focus();
        });
    }
    initialize() {
        __classPrivateFieldSet(this, _FormPinCodeController__form, this.element.closest('form'), "f");
        __classPrivateFieldSet(this, _FormPinCodeController__input, this.element.querySelector('input:not(.pin-code-field)'), "f");
        __classPrivateFieldGet(this, _FormPinCodeController__input, "f").classList.add('hidden');
        __classPrivateFieldSet(this, _FormPinCodeController__container, document.createElement('div'), "f");
        __classPrivateFieldGet(this, _FormPinCodeController__container, "f").classList.add('form-pincode');
        this.element.appendChild(__classPrivateFieldGet(this, _FormPinCodeController__container, "f"));
        if (!Number.isNaN(Number(__classPrivateFieldGet(this, _FormPinCodeController__input, "f").dataset.pinCodeLength))) {
            __classPrivateFieldSet(this, _FormPinCodeController_count, Math.floor(Number(__classPrivateFieldGet(this, _FormPinCodeController__input, "f").dataset.pinCodeLength)), "f");
        }
        for (let idx = 1; idx <= __classPrivateFieldGet(this, _FormPinCodeController_count, "f"); idx++) {
            __classPrivateFieldGet(this, _FormPinCodeController_instances, "m", _FormPinCodeController_appendInput).call(this, idx);
        }
    }
    connect() {
        var _a, _b, _c;
        __classPrivateFieldGet(this, _FormPinCodeController__form, "f").addEventListener('submit', __classPrivateFieldGet(this, _FormPinCodeController_onFormSubmit, "f"));
        for (let idx = 0; idx < __classPrivateFieldGet(this, _FormPinCodeController_count, "f"); idx++) {
            (_a = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _a === void 0 ? void 0 : _a.addEventListener('click', __classPrivateFieldGet(this, _FormPinCodeController_onInputClick, "f"));
            (_b = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', __classPrivateFieldGet(this, _FormPinCodeController_onInputKeyDown, "f"));
            (_c = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _c === void 0 ? void 0 : _c.addEventListener('paste', __classPrivateFieldGet(this, _FormPinCodeController_onInputPaste, "f"));
        }
    }
    disconnect() {
        var _a, _b, _c;
        __classPrivateFieldGet(this, _FormPinCodeController__form, "f").removeEventListener('submit', __classPrivateFieldGet(this, _FormPinCodeController_onFormSubmit, "f"));
        for (let idx = 0; idx < __classPrivateFieldGet(this, _FormPinCodeController_count, "f"); idx++) {
            (_a = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', __classPrivateFieldGet(this, _FormPinCodeController_onInputClick, "f"));
            (_b = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _b === void 0 ? void 0 : _b.removeEventListener('keydown', __classPrivateFieldGet(this, _FormPinCodeController_onInputKeyDown, "f"));
            (_c = __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f")[idx]) === null || _c === void 0 ? void 0 : _c.removeEventListener('paste', __classPrivateFieldGet(this, _FormPinCodeController_onInputPaste, "f"));
        }
    }
}
exports.default = FormPinCodeController;
_FormPinCodeController__form = new WeakMap(), _FormPinCodeController__input = new WeakMap(), _FormPinCodeController__container = new WeakMap(), _FormPinCodeController__inputs = new WeakMap(), _FormPinCodeController_count = new WeakMap(), _FormPinCodeController_onFormSubmit = new WeakMap(), _FormPinCodeController_onInputClick = new WeakMap(), _FormPinCodeController_onInputKeyDown = new WeakMap(), _FormPinCodeController_onInputPaste = new WeakMap(), _FormPinCodeController_instances = new WeakSet(), _FormPinCodeController_appendInput = function _FormPinCodeController_appendInput(idx) {
    let input = document.createElement('input');
    input.type = 'tel';
    input.name = `pincode-${idx}`;
    input.classList.add('pin-code-field');
    input.setAttribute('maxlength', String(1));
    input.setAttribute('tabindex', String(idx));
    input.setAttribute('placeholder', '·');
    input.setAttribute('autocomplete', 'off');
    __classPrivateFieldGet(this, _FormPinCodeController__container, "f").appendChild(input);
    __classPrivateFieldGet(this, _FormPinCodeController__inputs, "f").push(input);
};
