import { Controller } from "@hotwired/stimulus";
import { parseDataTargetSelector as parseTarget, queryDataTargetSelector as queryTarget } from "#utils/dom";
import { modify, setFieldValue } from "#utils/form";

export default class CollectionController extends Controller {
    static targets = ['container', 'item', 'adder', 'suppressor'];
    form = this.element.closest('form');
    container = queryTarget('container', 'form--collection', this.element);
    adder = queryTarget(this.element.dataset.adder || 'adder', 'form--collection', this.element);
    multipleAdder = queryTarget(this.element.dataset.multipleAdder, 'form--collection');

    initialize() {
        $(this).data('controller-collection', this);
    }

    connect() {
        if (this.element.dataset.prototype) {
            this.adder?.addEventListener('click', this.onClickAdd);
            this.multipleAdder?.addEventListener('submit', this.onClickAddMultiple);
        }

        this.element.addEventListener('click', this.onClickDelete);
        this.element.addEventListener('form-collection:add', this.onClickAdd);
    }

    disconnect() {
        if (this.element.dataset.prototype) {
            this.adder?.removeEventListener('click', this.onClickAdd);
            this.multipleAdder?.removeEventListener('submit', this.onClickAddMultiple);
        }

        this.element.removeEventListener('click', this.onClickDelete);
        this.element.removeEventListener('form-collection:add', this.onClickAdd);
    }

    onClickAdd = (evt) => {
        evt.preventDefault();
        const proto = this.renderPrototype();
        this.container.append(proto);
        initFormUi($(proto));

        Promise.resolve().then(() => {
            if (this.multipleAdder) {
                return modify(this.form, [
                    this.element.dataset.multipleAdder,
                ]);
            }
        }).then(() => {
            this.element.dispatchEvent(new Event('form-collection:added'));
        });
    };

    onClickAddMultiple = (evt) => {
        evt.preventDefault();
        const data = new FormData(evt.target);
        const values = data.getAll(`${evt.target.dataset.input || 'input'}[]`);
        values.forEach((value) => {
            const proto = this.renderPrototype();
            const input = queryTarget('multiple-input', 'form--collection', proto);
            if (input) {
                input.removeAttribute('disabled');
                input.removeAttribute('readonly');
                setFieldValue(input, { value });
            }

            this.container.append(proto);
        });

        modify(this.form, [
            parseTarget('container', 'form--collection'),
            this.element.dataset.multipleAdder,
        ]).then(() => {
            this.element.dispatchEvent(new Event('form-collection:added'));
        });
    };

    onClickDelete = (evt) => {
        if (!evt.target.matches(parseTarget('suppressor', 'form--collection'))) return;
        evt.preventDefault();
        evt.target.closest(parseTarget('item', 'form--collection')).remove();

        this.element.dispatchEvent(new Event('form-collection:deleted'));
    };

    renderPrototype = () => {
        let { prototype } = this.element.dataset;
        if (!prototype) return;
        const idx = Math.random().toString(36).slice(2, 9);
        const item = $(prototype).get(0);

        // /!\ Replace only first occurrence of __name__
        item.querySelectorAll('[id*=__name__]').forEach((node) => {
            node.setAttribute('id', node.getAttribute('id').replace(/__name__/, idx));
        });
        item.querySelectorAll('[name*=__name__]').forEach((node) => {
            node.setAttribute('name', node.getAttribute('name').replace(/__name__/, idx));
        });
        item.querySelectorAll('label[for*=__name__]').forEach((node) => {
            node.setAttribute('for', node.getAttribute('for').replace(/__name__/, idx));
        });

        const input_position = queryTarget('position', 'form--collection', item);
        if (input_position) input_position.value = 5e3 + this.container.querySelectorAll('[data-form--collection-target="item"]').length;

        const details = item.querySelector('details');
        if (details) details.open = true;

        return item;
    };
}
