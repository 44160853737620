"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageToBase64 = exports.pdfToBase64 = exports.fileToBytes = exports.fileToBase64 = void 0;
let _promisePdfLib;
function getPdfLib() {
    if (_promisePdfLib)
        return _promisePdfLib;
    return _promisePdfLib = Promise.resolve().then(() => __importStar(require('pdfjs-dist'))).then((pdfjs) => {
        pdfjs.GlobalWorkerOptions.workerSrc = '/build/pdf.worker.mjs';
        return pdfjs;
    });
}
function fileToBase64(file) {
    return __awaiter(this, void 0, void 0, function* () {
        if (file.type.startsWith('image/')) {
            const base64 = yield imageToBase64(file);
            return [base64];
        }
        if (file.type === 'application/pdf') {
            return pdfToBase64(file);
        }
        return Promise.reject(new Error(`Unsupported file type ${file.type}`));
    });
}
exports.fileToBase64 = fileToBase64;
function fileToBytes(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!reader.result) {
                reject(new Error('Empty file'));
            }
            else {
                resolve(new Uint8Array(reader.result));
            }
        });
        reader.addEventListener('error', (error) => {
            reject(error);
        });
        reader.readAsArrayBuffer(file);
    });
}
exports.fileToBytes = fileToBytes;
function pdfToBase64(file) {
    return __awaiter(this, void 0, void 0, function* () {
        if ('application/pdf' !== file.type) {
            return Promise.reject(new Error(`Unsupported PDF file type ${file.type}`));
        }
        const pdfjs = yield getPdfLib();
        const bytes = yield fileToBytes(file);
        const doc = yield pdfjs.getDocument(bytes).promise;
        return Promise.all(Array.from({ length: doc.numPages }, (_, idx) => __awaiter(this, void 0, void 0, function* () {
            const page = yield doc.getPage(idx + 1);
            const viewport = page.getViewport({ scale: 1 });
            // Prepare canvas using PDF page dimensions
            const canvas = document.createElement('canvas');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // Render PDF page into canvas context
            yield page.render({ canvasContext: canvas.getContext('2d'), viewport }).promise;
            return canvas.toDataURL('image/png');
        })));
    });
}
exports.pdfToBase64 = pdfToBase64;
function imageToBase64(file) {
    if (!file.type.startsWith('image/')) {
        return Promise.reject(new Error(`Unsupported image file type ${file.type}`));
    }
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!reader.result) {
                reject(new Error('Empty file'));
            }
            else {
                resolve(reader.result.toString());
            }
        });
        reader.addEventListener('error', (error) => {
            reject(error);
        });
        reader.readAsDataURL(file);
    });
}
exports.imageToBase64 = imageToBase64;
