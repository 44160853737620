import { Controller } from "@hotwired/stimulus";
import './ssn_controller.scss';

const PREPEND = true;
const APPEND = false;

export default class FormSsnController extends Controller {
    connect() {
        this.parent = this.createParent();
        this.createInput('field-ssn-civility', 0, 1, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-year', 1, 3, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-month', 3, 5, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-department', 5, 7, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-city', 7, 10, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-number', 10, 13, !this.element.classList.contains('is-invalid'));
        this.createInput('field-ssn-key', 13, 15, !this.element.classList.contains('is-invalid'));

        $(this.parent.closest('form')).on('change', '[name="wizard_employee_personal[civility]"]', this.onChangeCivility);
        $(this.parent.closest('form')).on('change', '[name="wizard_employee_personal[birthDate]"]', this.onChangeBirthDate);
        $(this.parent.closest('form')).on('change', '[id="wizard_employee_personal_frenchBirthCity_autocomplete"]', this.onChangeCity);
    }

    disconnect() {
        $(this.parent.closest('form')).off('change', '[name="wizard_employee_personal[civility]"]', this.onChangeCivility);
        $(this.parent.closest('form')).off('change', '[name="wizard_employee_personal[birthDate]"]', this.onChangeBirthDate);
        $(this.parent.closest('form')).off('change', '[id="wizard_employee_personal_frenchBirthCity_autocomplete"]', this.onChangeCity);
        this.parent.remove();
    }

    createField(className, value, prependOrAppend) {
        const container = document.createElement('div');
        this.parent.appendChild(container);

        container.classList.add(className);
        container.dataset.strLength = value.length;
        if (prependOrAppend === PREPEND) {
            container.classList.add('input-group-prepend');
        } else if (prependOrAppend === APPEND) {
            container.classList.add('input-group-append');
            container.classList.add('input-group-continue');
        }

        const viewer = document.createElement('span');
        container.appendChild(viewer);

        viewer.classList.add('input-group-text');
        viewer.style.color = '#495057';
        viewer.innerText = value;

        return container;
    }

    createInput(className, start, end, valid = true) {
        const length = end - start;
        const value = this.getFieldValue(start, end);
        const input = document.createElement('input');
        this.parent.appendChild(input);
        let pattern = Array(length).fill('\\d').join('');
        if (className === 'field-ssn-department') {
            pattern = '\\d[\\dABab]';
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'number');
            input.setAttribute('max', Array(length).fill('9').join(''));
        }

        input.classList.add(className);
        input.classList.add('form-control');
        input.setAttribute('pattern', pattern);
        input.setAttribute('required', 'required');
        input.value = value;
        input.dispatchEvent(new Event('change'));
        if (!valid) {
            input.classList.add('is-invalid');
        }

        if (this.element.value.length < start) {
            input.setAttribute('disabled', 'disabled');
        } else {
            const is_valid = (value, key) => {
                if (className !== 'field-ssn-department') return !Number.isNaN(Number(key));
                if (!value.length) return !Number.isNaN(Number(key));
                const regex = /^(\d\d?|2[AB])$/i;
                return regex.test(`${value}${key}`.slice(-2));
            };
            input.addEventListener('keydown', (event) => {
                if (!['Backspace', 'Delete'].includes(event.code)) {
                    if (!is_valid(input.value, event.key)) {
                        event.preventDefault();
                        event.stopPropagation();
                        return false;
                    }
                    if (input.value.length >= (end - start)) {
                        input.value = input.value.slice(1);
                    }
                    if (className !== 'field-ssn-key') {
                        this.parent.querySelector('.field-ssn-key').value = '';
                    }
                }
                return true;
            });
            input.addEventListener('blur', () => {
                input.value = input.value.padStart(end - start, '0');
            });
            input.addEventListener('change', () => {
                input.value = input.value.toUpperCase();
                let value = this.element.value.slice(0, start) + input.value;
                if (input.value.length === end - start) {
                    value += this.element.value.slice(end);
                }
                this.element.value = value;
                $(this.element).trigger('change');
            });
        }

        return input;
    }

    createParent() {
        const parent = document.createElement('div');
        this.element.after(parent);

        parent.classList.add('field-ssn');
        parent.classList.add('input-group');

        return parent;
    }

    getFieldValue(start, end) {
        if (this.element.value.length <= start && start < 5) {
            return Array(end - start).fill('_').join('');
        }
        const value = this.element.value.slice(start, end);
        if (this.element.value.length < end) {
            return value;
        }
        return value.padStart(end - start, '0');
    }

    onChangeCivility = (evt) => {
        if (!evt.target.value) return;
        this.civility.value = evt.target.value ? (evt.target.value === '1' ? '1' : '2') : '';
        this.compute(true);
    };

    onChangeBirthDate = (evt) => {
        if (this.element.value.length < 1) return;
        if (!evt.target.value.match(/\d{2}\/\d{2}\/\d{4}/)) return;
        this.year.value = evt.target.value.slice(8, 10);
        this.month.value = evt.target.value.slice(3, 5);
        this.compute(true);
    };

    onChangeCity = (evt) => {
        if (this.element.value.length < 5) return;
        const info = $(evt.target).data('info');
        if (!info) return;
        this.department.value = info.department_code;
        this.city.value = info.city_code;
        this.compute(true);
    };

    compute(forceComputeKey = false) {
        let value = '';
        if (this.civility.value.length === 1) {
            value += this.civility.value;
            if (this.year.value.length === 2) {
                value += this.year.value;
                if (this.month.value.length === 2) {
                    value += this.month.value;
                    if (this.department.value.length === 2) {
                        value += this.department.value;
                        if (this.city.value.length === 3) {
                            value += this.city.value;
                            if (this.number.value.length === 3) {
                                value += this.number.value;
                                if (forceComputeKey) {
                                    value += this.computeKey(value);
                                } else if (this.key.value.length === 2) {
                                    value += this.key.value;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (value !== this.element.value) {
            this.element.value = value;
            $(this.element).trigger('change');
        }
    }

    computeKey(value) {
        value = Number(value.replace('2A', '19').replace('2B', '18'));
        value = 97 - (value % 97);
        return String(value).padStart(2, '0');
    }

    get civility() {
        return this.parent.querySelector('.field-ssn-civility');
    }

    get year() {
        return this.parent.querySelector('.field-ssn-year');
    }

    get month() {
        return this.parent.querySelector('.field-ssn-month');
    }

    get department() {
        return this.parent.querySelector('.field-ssn-department');
    }

    get city() {
        return this.parent.querySelector('.field-ssn-city');
    }

    get number() {
        return this.parent.querySelector('.field-ssn-number');
    }

    get key() {
        return this.parent.querySelector('.field-ssn-number');
    }
}
