"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const stimulus_1 = require("@hotwired/stimulus");
const react_1 = require("react");
const ReactDOM = __importStar(require("react-dom"));
require("./frozen-zone-configuration_controller.scss");
// @ts-ignore
const { $, moment } = window;
moment.locale(document.documentElement.lang);
const DATE_FORNAT = 'DD/MM/YYYY HH:mm:ss';
let RESET = false;
let ENDPOINTS = {
    list: '#',
    edit: '#',
    delete: '#',
};
function getFrozenZones(year) {
    return fetch(ENDPOINTS['list'].replace('1970', year.toString())).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json().then((frozenZones) => {
            return frozenZones.map((frozenZone) => ({
                id: parseInt(frozenZone.id),
                year: parseInt(frozenZone.year),
                month: parseInt(frozenZone.month),
                from: moment(frozenZone.from, DATE_FORNAT),
                to: moment(frozenZone.to, DATE_FORNAT),
            }));
        });
    });
}
class FrozenZoneConfigurationController extends stimulus_1.Controller {
    initialize() {
        var _a, _b, _c;
        ENDPOINTS = {
            list: (_a = this.element.getAttribute('data-endpoint-list')) !== null && _a !== void 0 ? _a : '#',
            edit: (_b = this.element.getAttribute('data-endpoint-edit')) !== null && _b !== void 0 ? _b : '#',
            delete: (_c = this.element.getAttribute('data-endpoint-delete')) !== null && _c !== void 0 ? _c : '#',
        };
        initializeVisibility();
        document.addEventListener('sirh::pay_configuration_calendar_rendered', function (evt) {
            const customEvent = evt;
            render(customEvent.detail.year, false);
        }, false);
        document.addEventListener('sirh::pay_configuration_imported', function (evt) {
            RESET = true;
            const customEvent = evt;
            document.dispatchEvent(new CustomEvent('sirh::pay_configuration_calendar_rendered', {
                detail: {
                    year: customEvent.detail.year
                }
            }));
        });
        document.addEventListener('sirh::pay_configuration_calendar_legend_rendered', function (evt) {
            initCalendarLegend();
        }, false);
    }
}
exports.default = FrozenZoneConfigurationController;
function render(year, reset) {
    RESET = reset;
    ReactDOM.render((0, jsx_runtime_1.jsx)(FrozenZoneForm, { year: year }), document.getElementById('frozen-zones-list'));
}
function isEnabled() {
    const input = document.querySelector('#rhsuite_pay_site_configuration_period_hasFrozenZone');
    return input.checked;
}
function initializeVisibility() {
    const input = document.querySelector('#rhsuite_pay_site_configuration_period_hasFrozenZone');
    const form = input === null || input === void 0 ? void 0 : input.closest('form');
    form === null || form === void 0 ? void 0 : form.addEventListener('sirh::form-ajax--submitted', (evt) => {
        handleVisibility(evt);
        const element = document.getElementById('period-year');
        render((null === element) ? moment().year() : parseInt(element.value), true);
    });
    handleVisibility(new Event('init'));
}
function handleVisibility(evt) {
    const panel = document.querySelector('.frozen-zones-panels');
    if (isEnabled()) {
        panel === null || panel === void 0 ? void 0 : panel.classList.remove('hidden');
    }
    else {
        panel === null || panel === void 0 ? void 0 : panel.classList.add('hidden');
        document.querySelectorAll('.day-frozen-zone').forEach((node) => {
            node.classList.remove('day-frozen-zone');
        });
    }
    initCalendarLegend();
}
function initCalendarLegend() {
    const placeholder = document.querySelector('.jla-calendar-legend');
    let legend = placeholder === null || placeholder === void 0 ? void 0 : placeholder.querySelector('.legend-frozen-zone');
    if (null === legend) {
        legend = document.createElement('p');
        legend.classList.add('legend-frozen-zone', 'd-flex', 'align-items-center');
        legend.textContent = 'Frozen zone';
        placeholder === null || placeholder === void 0 ? void 0 : placeholder.append(legend);
    }
    if (isEnabled()) {
        legend === null || legend === void 0 ? void 0 : legend.classList.remove('hidden');
    }
    else {
        legend === null || legend === void 0 ? void 0 : legend.classList.add('hidden');
    }
}
function FrozenZoneForm(props) {
    const [frozenZones, setFrozenZones] = (0, react_1.useState)(undefined);
    const [year, setYear] = (0, react_1.useState)(props.year);
    if (RESET) {
        setFrozenZones(undefined);
        RESET = false;
    }
    (0, react_1.useEffect)(() => {
        const container = document.getElementById('frozen-zone-add-form');
        const form = container.querySelector('form');
        const input_year = form.querySelector('input[name="rhsuite_planning_pay_frozen_zone[year]"]');
        input_year.value = year.toString();
    }, [year]);
    (0, react_1.useEffect)(() => {
        if (frozenZones === undefined) {
            getFrozenZones(year).then((frozenZones) => {
                setFrozenZones(frozenZones);
            });
        }
    }, [frozenZones]);
    const displayFrozenZones = (frozenZones) => {
        if (undefined === frozenZones || !isEnabled()) {
            return;
        }
        frozenZones.forEach((frozenZone) => {
            displayFrozenZone(frozenZone);
        });
    };
    const displayFrozenZone = (frozenZone) => {
        const container = document.querySelector('.jla-calendar');
        let from = frozenZone.from.clone().startOf('day');
        const to = frozenZone.to.clone().startOf('day');
        while (from.isSameOrBefore(to)) {
            const node = container === null || container === void 0 ? void 0 : container.querySelector(`.jla-day[data-date="${from.format('YYYY-MM-DD')}"]`);
            if (node) {
                node === null || node === void 0 ? void 0 : node.classList.add('day-frozen-zone');
                node.dataset.id = frozenZone.id;
            }
            from.add(1, 'days');
        }
    };
    const handleHover = (display, frozenZones, current) => {
        var _a;
        const container = document.querySelector('.jla-calendar');
        (_a = container === null || container === void 0 ? void 0 : container.querySelectorAll('.day-frozen-zone')) === null || _a === void 0 ? void 0 : _a.forEach((node, index) => {
            node.classList.remove('day-frozen-zone');
        });
        if (display) {
            displayFrozenZone(current);
        }
        else {
            displayFrozenZones(frozenZones);
        }
    };
    const handleEdit = (month) => {
        return fetch(`${ENDPOINTS.edit}?month=${month}&year=${year}`, {
            method: 'GET',
            credentials: 'same-origin',
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.text().then((html) => {
                const $modal = $('<div class="modal fade"><div class="modal-dialog modal-dialog-centered modal-md"><div class="modal-content"><div class="modal-body"></div></div></div></div>');
                $(document.body).append($modal);
                $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">Modifier une frozen zone</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
                $modal.find('.modal-body').html(html);
                $modal.on("shown.bs.modal", (evt) => {
                    const $modal = $(evt.target).is('.modal') ? $(evt.target) : $(evt.target).closest('.modal');
                    (function initForm() {
                        const $form = $modal.find("form");
                        // @ts-ignore
                        window.initFormUi($form);
                        $form.on("submit", function (evt) {
                            evt.preventDefault();
                            handleForm($form).then(() => {
                                $modal.modal('hide');
                            }, (html) => {
                                $modal.find('.modal-body').html(html);
                                initForm();
                            });
                        });
                    })();
                });
                $modal.on('hidden.bs.modal', () => {
                    $modal.remove();
                });
                $modal.modal('show');
            });
        });
    };
    const handleDelete = (id) => {
        fetch(ENDPOINTS['delete'].replace('0', id.toString()), {
            method: 'POST',
            credentials: 'same-origin',
        }).then((response) => {
            var _a;
            if (!response.ok) {
                // @ts-ignore
                toastr.error('Erreur lors de la suppression de la frozen zone');
            }
            else {
                // @ts-ignore
                toastr.success('Frozen zone supprimée');
                document.querySelectorAll(`.jla-calendar .day-frozen-zone[data-id="${id}"]`).forEach((node) => {
                    node.classList.remove('day-frozen-zone');
                });
                (_a = document.querySelector(`#frozen-zones-list tr[data-id="${id}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
            }
        });
    };
    const handleForm = ($form) => {
        return new Promise((resolve, reject) => {
            $form.find('button[type="submit"]').prop('disabled', true);
            return fetch($form.attr('action'), {
                method: 'POST',
                body: new FormData($form[0]),
                credentials: 'same-origin',
                headers: { 'X-Requested-With': "XMLHttpRequest" }
            }).then((response) => {
                $form.find('button[type="submit"]').prop('disabled', false);
                if (!response.ok) {
                    return response.text().then((html) => {
                        reject(html);
                    });
                }
                else {
                    return response.json().then((frozenZone) => {
                        setFrozenZones(undefined);
                        resolve(frozenZone);
                    });
                }
            });
        });
    };
    (0, react_1.useEffect)(() => {
        const container = document.getElementById('frozen-zone-add-form');
        const form = container.querySelector('form');
        const select = form.querySelector('select[name="rhsuite_planning_pay_frozen_zone[month]"]');
        function getDateRange(month) {
            const frozenZone = frozenZones === null || frozenZones === void 0 ? void 0 : frozenZones.find((frozenZone) => frozenZone.month === month);
            if (frozenZone) {
                if (!form.querySelector('#alert-frozen-zone-edit')) {
                    $(form).prepend('<div id="alert-frozen-zone-edit" class="alert alert-warning"><span class="fal fa-exclamation-triangle fa-fw mr-1"></span>Une frozen zone existe pour ce mois-ci.<br />Vous allez modifier celle-ci en validant le formulaire.</div>');
                }
                return [frozenZone.from, frozenZone.to];
            }
            if (form.querySelector('#alert-frozen-zone-edit')) {
                $('#alert-frozen-zone-edit').remove();
            }
            const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
            let start = moment(date).startOf('month');
            if (start.day() < 4) {
                start.day(1);
            }
            else {
                start.day(8);
            }
            let end = moment(date).endOf('month');
            if (end.day() >= 4) {
                end.day(7);
            }
            else {
                end.day(0);
            }
            return [start, end];
        }
        const onChangeMonth = () => {
            const [start, end] = getDateRange(parseInt(select.value));
            $(form).find('input[name="rhsuite_planning_frozen_zone_period[range][from]"]').datepicker('setDate', start.format('DD/MM/YYYY'));
            $(form).find('input[name="rhsuite_planning_frozen_zone_period[range][to]"]').datepicker('setDate', end.format('DD/MM/YYYY'));
        };
        const onSubmit = (evt) => {
            evt.preventDefault();
            handleForm($(evt.target)).then(() => {
                const month = parseInt(select.value);
                if (frozenZones === null || frozenZones === void 0 ? void 0 : frozenZones.find((frozenZone) => frozenZone.month === month)) {
                    // @ts-ignore
                    toastr.success('Période modifiée avec succès');
                }
                else {
                    // @ts-ignore
                    toastr.success('Période ajoutée avec succès');
                }
            }, (html) => {
                container.innerHTML = html;
            }).finally(() => {
                // @ts-ignore
                window.initFormUi($(evt.target));
            });
        };
        select.addEventListener('change', onChangeMonth);
        form.addEventListener('submit', onSubmit);
        onChangeMonth();
        return () => {
            select.removeEventListener('change', onChangeMonth);
            form.removeEventListener('submit', onSubmit);
        };
    }, [year, frozenZones]);
    displayFrozenZones(frozenZones);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("table", Object.assign({ className: "table table-striped table-hover table-condensed" }, { children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { children: "Mois" }), (0, jsx_runtime_1.jsx)("th", { children: "D\u00E9but" }), (0, jsx_runtime_1.jsx)("th", { children: "Fin" }), (0, jsx_runtime_1.jsx)("th", {})] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: (frozenZones || []).filter((frozenZone) => {
                        return frozenZone.year === year;
                    }).map((frozenZone, idx) => {
                        let month = moment(`${year}-${frozenZone.month}-01`, 'YYYY-MM-DD').format('MMMM');
                        month = month.slice(0, 1).toUpperCase() + month.slice(1);
                        const onMouseEnterRow = () => {
                            handleHover(true, frozenZones, frozenZone);
                        };
                        const onMouseLeaveRow = () => {
                            handleHover(false, frozenZones, frozenZone);
                        };
                        const onClickEdit = (evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            handleEdit(frozenZone.month);
                        };
                        const onMouseDownEdit = (evt) => {
                            evt.stopPropagation();
                        };
                        const onClickDelete = (evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            handleDelete(frozenZone.id);
                        };
                        const onMouseDownDelete = (evt) => {
                            evt.stopPropagation();
                        };
                        return (0, jsx_runtime_1.jsxs)("tr", Object.assign({ "data-id": `${frozenZone.id}`, onMouseEnter: onMouseEnterRow, onMouseLeave: onMouseLeaveRow, onMouseDown: onMouseEnterRow, onMouseUp: onMouseLeaveRow }, { children: [(0, jsx_runtime_1.jsx)("td", { children: month }), (0, jsx_runtime_1.jsx)("td", { children: frozenZone.from.format(DATE_FORNAT) }), (0, jsx_runtime_1.jsx)("td", { children: frozenZone.to.format(DATE_FORNAT) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: "btn btn-primary btn-sm", onClick: onClickEdit, onMouseDown: onMouseDownEdit }, { children: (0, jsx_runtime_1.jsx)("i", { className: "fal fa-pencil fa-fw" }) })) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: "btn btn-danger btn-sm", onClick: onClickDelete, onMouseDown: onMouseDownDelete }, { children: (0, jsx_runtime_1.jsx)("i", { className: "fal fa-trash-alt fa-fw" }) })) })] }), `frozen-zone-item-${frozenZone.id}`);
                    }) })] })) });
}
