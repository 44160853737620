import $ from "jquery";

export default function initDocumentSelect2(node) {
  // Fix fields in collapse container
  $(node).find('.select2').each(function () {
    const $node = $(this);
    $node.removeClass('form-control custom-select');
    const $collapse = $node.closest('.collapse:not(.show)');
    if (!$collapse.length) {
      initSelect2($node);
    } else {
      const onCollapse = () => {
        $collapse.off('shown.bs.collapse', onCollapse);
        initSelect2($node);
      };
      $collapse.on('shown.bs.collapse', onCollapse);
    }
  });
}

export function initSelect2($select, config = {}) {
  const $parentModal = $select.closest('.modal');
  const $parent = $parentModal.length ? $parentModal : $(document.body);
  const isSelectedHidden = $select.hasAttr('data-select2-selected-hidden') && 'false' !== $select.attr('data-select2-selected-hidden');

  $select.select2({
    dropdownParent: $parent,
    minimumInputLength: 1000 < $select.find('option').length ? 2 : 0,
    width: '100%',
    templateResult(state) {
      if (isSelectedHidden && state.selected) {
        return null;
      }
      return state.text;
    },

    ...config,

    language: {
      inputTooShort(config) {
        return `Veuillez saisir au moins ${config.minimum} caractères pour afficher la liste`;
      },
      ...config.language,
    },
  });

  const $selection = $select.find('+ .select2.select2-container .select2-selection');
  if ($select.hasClass('is-invalid')) {
    $selection.addClass('is-invalid');
  }
  if ($select.hasClass('is-valid')) {
    $selection.addClass('is-valid');
  }

  $select.on('select2:open', () => {
    // Focus search input
    const field = $select.find('+ .select2.select2-container .select2-selection').attr('aria-owns');
    $(`.select2-search__field[aria-controls="${field}"]`).get(0).focus();
  });
}
