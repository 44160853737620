import LayoutController from "./layout_controller";

export default class LayoutCounterController extends LayoutController {
    initialize() {
        const endpoint = 'counters';
        const uri = this.getEndpointUri(endpoint);
        if (undefined === uri) {
            return;
        }

        const scope = this.getOption('scope');
        if (null === scope) {
            return;
        }
        const ttl = this.getOption('ttl', '');

        this
            .fetch(endpoint, uri.replace('scope', scope) + '?ttl=' + ttl)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
            })
            .then((counter) => {
                if (0 < parseInt(counter)) {
                    this.element.textContent = counter;
                }
            })
        ;
    }
}
