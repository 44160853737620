"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const stimulus_1 = require("@hotwired/stimulus");
const react_1 = require("react");
const ReactDOM = __importStar(require("react-dom"));
require("./configuration_controller.scss");
// @ts-ignore
const { $, moment } = window;
moment.locale(document.documentElement.lang);
let ENDPOINTS = {
    list: '#',
    types: '#',
    edit: '#',
    export: '#',
    import: '#',
};
const LIGHT = '#a0c0e5';
const MEDIUM = '#4086bf';
const DARK = '#2d5e86';
const TYPE_LABELS = {
    NORMAL: 'permanents',
    EXTRA: 'extras',
    PROVISION: 'de mise à disposition',
    NIGHT_HOURS: 'heures de nuits',
};
function getPeriods(year, type = 'NORMAL') {
    return fetch(ENDPOINTS['list'].replace('1970', year.toString()).replace('type', type)).then((response) => {
        if (!response.ok)
            throw new Error(response.statusText);
        return response.json().then((periods) => {
            return periods.map((period) => ({
                year: parseInt(period.year),
                month: parseInt(period.month),
                from: moment(period.from, 'DD/MM/YYYY'),
                to: moment(period.to, 'DD/MM/YYYY'),
                type: period.type,
            }));
        });
    });
}
function getTypes() {
    return fetch(ENDPOINTS['types']).then((response) => {
        if (!response.ok)
            throw new Error(response.statusText);
        return response.json();
    });
}
class PayConfigurationController extends stimulus_1.Controller {
    initialize() {
        var _a, _b, _c, _d, _e;
        ENDPOINTS = {
            list: (_a = this.element.getAttribute('data-endpoint-list')) !== null && _a !== void 0 ? _a : '#',
            types: (_b = this.element.getAttribute('data-endpoint-types')) !== null && _b !== void 0 ? _b : '#',
            edit: (_c = this.element.getAttribute('data-endpoint-edit')) !== null && _c !== void 0 ? _c : '#',
            export: (_d = this.element.getAttribute('data-endpoint-export')) !== null && _d !== void 0 ? _d : '#',
            import: (_e = this.element.getAttribute('data-endpoint-import')) !== null && _e !== void 0 ? _e : '#',
        };
        const year = (new Date()).getFullYear();
        getTypes().then((types) => {
            ReactDOM.render((0, jsx_runtime_1.jsx)(PeriodForm, { types: types, year: year }), document.getElementById('period-list'));
        });
    }
}
exports.default = PayConfigurationController;
function PeriodForm(props) {
    const [periods, setPeriods] = (0, react_1.useState)(undefined);
    const [hoverPeriod, setHoverPeriod] = (0, react_1.useState)(undefined);
    const [type, setType] = (0, react_1.useState)(props.types[0]);
    const [year, setYear] = (0, react_1.useState)(props.year);
    (0, react_1.useEffect)(() => {
        const container = document.getElementById('period-add-form');
        const form = container.querySelector('form');
        const input_year = form.querySelector('input[name="rhsuite_planning_pay_period[year]"]');
        const input_type = form.querySelector('input[name="rhsuite_planning_pay_period[type]"]');
        input_year.value = year.toString();
        if (input_type) {
            input_type.value = type;
        }
    }, [year, type]);
    (0, react_1.useEffect)(() => {
        if (periods === undefined) {
            getPeriods(year, type).then((periods) => {
                setPeriods(periods);
            });
        }
    }, [periods]);
    const handleEdit = (month) => {
        return fetch(`${ENDPOINTS.edit}?month=${month}&year=${year}&type=${type}`, {
            method: 'GET',
            credentials: 'same-origin',
        }).then((response) => {
            if (!response.ok)
                throw new Error(response.statusText);
            return response.text().then((html) => {
                const $modal = $('<div class="modal fade"><div class="modal-dialog modal-dialog-centered modal-md"><div class="modal-content"><div class="modal-body"></div></div></div></div>');
                $(document.body).append($modal);
                $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">Modifier une période de paie</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
                $modal.find('.modal-body').html(html);
                $modal.on("shown.bs.modal", (evt) => {
                    const $modal = $(evt.target).is('.modal') ? $(evt.target) : $(evt.target).closest('.modal');
                    (function initForm() {
                        const $form = $modal.find("form");
                        // @ts-ignore
                        window.initFormUi($form);
                        $form.on("submit", function (evt) {
                            evt.preventDefault();
                            handleForm($form).then(() => {
                                $modal.modal('hide');
                            }, (html) => {
                                $modal.find('.modal-body').html(html);
                                initForm();
                            });
                        });
                    })();
                });
                $modal.on('hidden.bs.modal', () => {
                    $modal.remove();
                });
                $modal.modal('show');
            });
        });
    };
    const handleForm = ($form) => {
        return new Promise((resolve, reject) => {
            $form.find('button[type="submit"]').prop('disabled', true);
            return fetch($form.attr('action'), {
                method: 'POST',
                body: new FormData($form[0]),
                credentials: 'same-origin',
            }).then((response) => {
                $form.find('button[type="submit"]').prop('disabled', false);
                if (!response.ok) {
                    return response.text().then((html) => {
                        reject(html);
                    });
                }
                else {
                    return response.json().then((period) => {
                        setPeriods(undefined);
                        resolve(period);
                    });
                }
            });
        });
    };
    (0, react_1.useEffect)(() => {
        const panel = document.getElementById('panel-import-export');
        if (!panel)
            return;
        const btnImport = document.getElementById('btn-submit-import');
        const btnExport = document.getElementById('btn-submit-export');
        const onClickImport = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            const data = new FormData();
            data.append('type', type);
            data.append('year', year.toString());
            const select = document.getElementById('import-site');
            data.append(`site`, $(select).val());
            fetch(ENDPOINTS.import, {
                method: 'POST',
                body: data,
            }).then((response) => {
                if (!response.ok)
                    throw new Error(response.statusText);
                return response.text();
            }).then(() => {
                // @ts-ignore
                toastr.success('Période importée avec succès.');
                setPeriods(undefined);
                setHoverPeriod(undefined);
                document.dispatchEvent(new CustomEvent('sirh::pay_configuration_imported', {
                    detail: {
                        type: type,
                        year: year,
                    }
                }));
            }, () => {
                // @ts-ignore
                toastr.success('Une erreur s\'est produite pendant l\'importation.');
            });
        };
        const onClickExport = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            const data = new FormData();
            data.append('type', type);
            data.append('year', year.toString());
            const select = document.getElementById('export-sites');
            const values = $(select).val();
            for (let idx = 0; idx < values.length; idx++) {
                data.append(`sites[]`, values[idx]);
            }
            fetch(ENDPOINTS.export, {
                method: 'POST',
                body: data,
            }).then((response) => {
                if (!response.ok)
                    throw new Error(response.statusText);
                return response.text();
            }).then(() => {
                // @ts-ignore
                toastr.success('Période exportée avec succès.');
            }, () => {
                // @ts-ignore
                toastr.success('Une erreur s\'est produite pendant l\'exportation.');
            });
        };
        btnImport.addEventListener('click', onClickImport);
        btnExport.addEventListener('click', onClickExport);
        return () => {
            btnImport.removeEventListener('click', onClickImport);
            btnExport.removeEventListener('click', onClickExport);
        };
    }, [year, type]);
    (0, react_1.useEffect)(() => {
        const container = document.getElementById('period-add-form');
        const form = container.querySelector('form');
        const select = form.querySelector('select[name="rhsuite_planning_pay_period[month]"]');
        function getDateRange(month) {
            const period = periods === null || periods === void 0 ? void 0 : periods.find((period) => period.month === month);
            if (period) {
                if (!form.querySelector('#alert-period-edit')) {
                    $(form).prepend('<div id="alert-period-edit" class="alert alert-warning"><span class="fal fa-exclamation-triangle fa-fw mr-1"></span>Une période existe pour ce mois-ci.<br />Vous allez modifier celle-ci en validant le formulaire.</div>');
                }
                return [period.from, period.to];
            }
            if (form.querySelector('#alert-period-edit')) {
                $('#alert-period-edit').remove();
            }
            const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
            let start = moment(date).startOf('month');
            if (start.day() < 4) {
                start.day(1);
            }
            else {
                start.day(8);
            }
            let end = moment(date).endOf('month');
            if (end.day() >= 4) {
                end.day(7);
            }
            else {
                end.day(0);
            }
            return [start, end];
        }
        const onChangeMonth = () => {
            const [start, end] = getDateRange(parseInt(select.value));
            $(form).find('input[name="rhsuite_planning_pay_period[range][from]"]').datepicker('setDate', start.format('DD/MM/YYYY'));
            $(form).find('input[name="rhsuite_planning_pay_period[range][to]"]').datepicker('setDate', end.format('DD/MM/YYYY'));
        };
        const onSubmit = (evt) => {
            evt.preventDefault();
            handleForm($(evt.target)).then(() => {
                const month = parseInt(select.value);
                if (periods === null || periods === void 0 ? void 0 : periods.find((period) => period.month === month)) {
                    // @ts-ignore
                    toastr.success('Période modifiée avec succès');
                }
                else {
                    // @ts-ignore
                    toastr.success('Période ajoutée avec succès');
                }
            }, (html) => {
                container.innerHTML = html;
            }).finally(() => {
                // @ts-ignore
                window.initFormUi($(evt.target));
            });
        };
        select.addEventListener('change', onChangeMonth);
        form.addEventListener('submit', onSubmit);
        onChangeMonth();
        return () => {
            select.removeEventListener('change', onChangeMonth);
            form.removeEventListener('submit', onSubmit);
        };
    }, [year, type, periods]);
    return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [ReactDOM.createPortal((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "jla-calendar-container" }, { children: [(0, jsx_runtime_1.jsx)(CalendarLegend, {}), (0, jsx_runtime_1.jsx)(Calendar, { hovered: hoverPeriod, periods: periods, year: year })] })), document.getElementById('period-viewer')), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "form-inline py-1" }, { children: [(0, jsx_runtime_1.jsx)("label", Object.assign({ htmlFor: "period-type", className: "my-1 mr-2" }, { children: "Pour les contrats" })), (0, jsx_runtime_1.jsx)("select", Object.assign({ id: "period-type", className: "custom-select border-top-0 border-left-0 border-right-0", value: type, onChange: (evt) => {
                            setType(evt.target.value);
                            setPeriods(undefined);
                        } }, { children: props.types.map((type, idx) => {
                            const label = TYPE_LABELS[type];
                            return (0, jsx_runtime_1.jsx)("option", Object.assign({ value: type }, { children: label }), `period-type-${idx}`);
                        }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "form-inline py-1" }, { children: [(0, jsx_runtime_1.jsx)("label", Object.assign({ className: "my-1 mr-2", htmlFor: "period-year" }, { children: "Pour l'ann\u00E9e" })), (0, jsx_runtime_1.jsx)("input", { type: "number", id: "period-year", className: "form-control", value: year, onChange: (evt) => {
                            setYear(parseInt(evt.target.value));
                            setPeriods(undefined);
                        } })] })), (0, jsx_runtime_1.jsxs)("table", Object.assign({ className: "table table-striped table-hover table-condensed" }, { children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { children: "Mois" }), (0, jsx_runtime_1.jsx)("th", { children: "D\u00E9but" }), (0, jsx_runtime_1.jsx)("th", { children: "Fin" }), (0, jsx_runtime_1.jsx)("th", {})] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: (periods || []).filter((period) => {
                            return period.year === year;
                        }).map((period, idx) => {
                            let month = moment(`${year}-${period.month}-01`, 'YYYY-MM-DD').format('MMMM');
                            month = month.slice(0, 1).toUpperCase() + month.slice(1);
                            const onMouseDownRow = () => {
                                setHoverPeriod(period);
                            };
                            const onMouseUpRow = () => {
                                setHoverPeriod((p) => (p && p.month === period.month) ? undefined : p);
                            };
                            const onMouseEnterRow = () => {
                                setHoverPeriod(period);
                            };
                            const onMouseLeaveRow = () => {
                                setHoverPeriod((p) => (p && p.month === period.month) ? undefined : p);
                            };
                            const onClickEdit = (evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                handleEdit(period.month);
                            };
                            const onMouseDownEdit = (evt) => {
                                evt.stopPropagation();
                            };
                            return (0, jsx_runtime_1.jsxs)("tr", Object.assign({ onMouseEnter: onMouseEnterRow, onMouseLeave: onMouseLeaveRow, onMouseDown: onMouseDownRow, onMouseUp: onMouseUpRow }, { children: [(0, jsx_runtime_1.jsx)("td", { children: month }), (0, jsx_runtime_1.jsx)("td", { children: period.from.format('DD/MM/YYYY') }), (0, jsx_runtime_1.jsx)("td", { children: period.to.format('DD/MM/YYYY') }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ type: "button", className: "btn btn-primary btn-sm", onClick: onClickEdit, onMouseDown: onMouseDownEdit }, { children: (0, jsx_runtime_1.jsx)("i", { className: "fal fa-pencil fa-fw" }) })) })] }), `period-item-${idx}`);
                        }) })] }))] });
}
function Calendar(props) {
    const [year, setYear] = (0, react_1.useState)(props.year);
    (0, react_1.useEffect)(() => {
        document.dispatchEvent(new CustomEvent('sirh::pay_configuration_calendar_rendered', {
            detail: {
                year: year,
            }
        }));
    }, [year]);
    return (0, jsx_runtime_1.jsx)("article", Object.assign({ className: "jla-calendar" }, { children: [...Array(12)].map((_, month) => {
            const date = moment(`${props.year}-${month + 1}-01`, 'YYYY-MM-DD');
            return (0, jsx_runtime_1.jsx)(Month, { hovered: props.hovered, periods: props.periods, date: date }, `month-${props.year}-${month}`);
        }) }));
}
function CalendarLegend() {
    (0, react_1.useEffect)(() => {
        document.dispatchEvent(new Event('sirh::pay_configuration_calendar_legend_rendered'));
    });
    return (0, jsx_runtime_1.jsxs)("header", Object.assign({ className: "jla-calendar-legend" }, { children: [(0, jsx_runtime_1.jsxs)("p", Object.assign({ className: "d-flex align-items-center" }, { children: [(0, jsx_runtime_1.jsx)("span", { className: "d-inline-block mr-2", style: { width: '1rem', height: '1rem', background: `linear-gradient(to bottom right, ${LIGHT} 0%, ${LIGHT} 50%, ${DARK} 50%, ${DARK} 100%)` } }), "Inclus dans une p\u00E9riode de paie"] })), (0, jsx_runtime_1.jsxs)("p", Object.assign({ className: "d-flex align-items-center" }, { children: [(0, jsx_runtime_1.jsx)("span", { className: "d-inline-block mr-2", style: { width: '1rem', height: '1rem', background: '#FFC107' } }), "Sans p\u00E9riode de paie"] })), (0, jsx_runtime_1.jsxs)("p", Object.assign({ className: "d-flex align-items-center" }, { children: [(0, jsx_runtime_1.jsx)("span", { className: "d-inline-block mr-2", style: { width: '1rem', height: '1rem', background: '#DC3545' } }), "P\u00E9riodes de paie se chevauchant"] }))] }));
}
function Month(props) {
    const nbDays = props.date.daysInMonth();
    const nbWeeks = Math.ceil(((props.date.day() || 7) + nbDays - 1) / 7);
    let title = props.date.format('MMMM');
    title = title.slice(0, 1).toUpperCase() + title.slice(1);
    return (0, jsx_runtime_1.jsxs)("article", Object.assign({ className: "jla-month" }, { children: [(0, jsx_runtime_1.jsx)("header", Object.assign({ className: "text-center" }, { children: title })), (0, jsx_runtime_1.jsxs)("section", Object.assign({ className: "jla-day-indicator" }, { children: [(0, jsx_runtime_1.jsx)("span", { children: "Lu" }), (0, jsx_runtime_1.jsx)("span", { children: "Ma" }), (0, jsx_runtime_1.jsx)("span", { children: "Me" }), (0, jsx_runtime_1.jsx)("span", { children: "Je" }), (0, jsx_runtime_1.jsx)("span", { children: "Ve" }), (0, jsx_runtime_1.jsx)("span", { children: "Sa" }), (0, jsx_runtime_1.jsx)("span", { children: "Di" })] })), (0, jsx_runtime_1.jsx)("section", Object.assign({ className: "jla-week-container" }, { children: [...Array(nbWeeks)].map((_, week) => {
                    let start;
                    let end;
                    start = moment(props.date).add(week * 7, 'days');
                    if (moment(start).startOf('week').isBefore(moment(props.date).startOf('month'))) {
                        start = moment(start).startOf('month');
                    }
                    else {
                        start = moment(start).startOf('week');
                    }
                    if (moment(start).endOf('week').isAfter(moment(props.date).endOf('month'))) {
                        end = moment(start).endOf('month');
                    }
                    else {
                        end = moment(start).endOf('week');
                    }
                    return (0, jsx_runtime_1.jsx)(Week, { periods: props.periods, hovered: props.hovered, date: props.date, week: week, start: start, end: end }, `week-${start.format('YYYY-MM-WW')}`);
                }) }))] }));
}
function Week(props) {
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: [...Array(7)].map((_, day) => {
            var _a;
            const date = moment(props.start).startOf('week').add(day, 'days');
            const periods = ((_a = props.periods) === null || _a === void 0 ? void 0 : _a.filter((period) => {
                return period.from.isSameOrBefore(date) && period.to.isSameOrAfter(date);
            })) || undefined;
            return (0, jsx_runtime_1.jsx)(Day, { store: props.periods, periods: periods, hovered: props.hovered, week: props.week, day: day, start: props.start, end: props.end, date: date }, `jla-calendar-day-${props.start.format('YYYY-MM')}-${date.format('MM-DD')}`);
        }) });
}
function Day(props) {
    var _a;
    if (!(props.date.isSameOrAfter(props.start, 'day') && props.date.isSameOrBefore(props.end, 'day'))) {
        return (0, jsx_runtime_1.jsx)("div", { className: "jla-day" });
    }
    if (!props.store || !props.periods) {
        return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "jla-day", "data-date": props.date.format('YYYY-MM-DD'), style: { background: '#FFF', color: '#000' } }, { children: props.date.format('DD') }));
    }
    if (props.periods.length === 0) {
        return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "jla-day", "data-date": props.date.format('YYYY-MM-DD'), style: { background: '#FFC107', color: '#000' } }, { children: props.date.format('DD') }));
    }
    if (props.periods.length > 1) {
        return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "jla-day", "data-date": props.date.format('YYYY-MM-DD'), style: { background: '#DC3545', color: '#FFF' } }, { children: props.date.format('DD') }));
    }
    const [period] = props.periods;
    // const duration = moment.duration(props.periods[0].to.diff(props.periods[0].from)).asDays();
    // const offset = Math.floor(1 + Math.abs(props.date.diff(props.periods[0].from, 'day')));
    // const background = `linear-gradient(to bottom right, ${LIGHT} -${offset * 100}%, ${DARK} ${(duration - offset - 1) * 100}%)`;
    const background = ((_a = props.hovered) === null || _a === void 0 ? void 0 : _a.month) === period.month ? DARK : (props.store.findIndex((p) => p.month === period.month) % 2 ? LIGHT : MEDIUM);
    return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "jla-day", "data-date": props.date.format('YYYY-MM-DD'), style: { background, color: '#000' } }, { children: props.date.format('DD') }));
}
