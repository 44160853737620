import LayoutController from "./layout_controller";

export default class LayoutDashboardController extends LayoutController {
    initialize() {
        const endpoint = 'dashboard';
        const uri = this.getEndpointUri(endpoint);
        if (undefined === uri) {
            return;
        }

        const btn = document.getElementById('btn-dashboard-refresh');
        const timeout = setTimeout(() => btn?.classList.remove('d-none'), 8000);
        btn?.addEventListener('click', () => document.location.reload(true));

        this
            .fetch(endpoint, uri)
            .then((response) => {
                if (!response.ok) throw new Error(response.statusText);
                return response.text();
            })
            .then((html) => {
                clearTimeout(timeout);
                this.element.innerHTML = html;
            })
            .catch(() => {
            })
        ;
    }
}
