import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class WizardEmployeeController extends Controller {
    initialize() {
        if (document.location.hash && document.location.hash.startsWith('#rh-wizard-tab')) {
            this.navigate(document.location.hash.slice(1));
        }
    }

    connect() {
        const steps = Array.from(document.querySelectorAll('.rh-wizard-pane'));
        for (let idx = 0; idx < steps.length; idx++) {
            $('form', steps[idx]).on('change', ':input[name]', this.onChange);
            $('form button[type="submit"]', steps[idx]).on('click', this.onSubmit);
        }

        document.querySelectorAll('.rh-wizard-tab').forEach((tab) => {
            tab.addEventListener('click', this.onNavigate);
        });
    }

    disconnect() {
        const steps = Array.from(document.querySelectorAll('.rh-wizard-pane'));
        for (let idx = 0; idx < steps.length; idx++) {
            $('form', steps[idx]).off('change', ':input[name]', this.onChange);
            $('form button[type="submit"]', steps[idx]).off('click', this.onSubmit);
        }

        document.querySelectorAll('.rh-wizard-tab').forEach((tab) => {
            tab.removeEventListener('click', this.onNavigate);
        });
    }

    checkValidity = (input) => {
        input.classList.remove('is-valid');
        input.classList.remove('is-invalid');
        input.classList.remove('valid');
        input.classList.remove('invalid');

        let valid = true;
        if (input.required && !input.value) {
            valid = false;
        } else if (input.pattern && !(new RegExp(input.pattern)).test(input.value)) {
            valid = false;
        }

        input.classList.add(valid ? 'is-valid' : 'is-invalid');
    };

    onChange = (event) => {
        this.checkValidity(event.currentTarget);
        const step = event.currentTarget.closest('.rh-wizard-pane');
        if (event.currentTarget.closest('[data-form-modifiers]')) {
            this.send(event, step, { input: event.currentTarget });
        }
    };

    onNavigate = (event) => {
        event.preventDefault();
        if (event.currentTarget.classList.contains('disabled')) return;
        const { id } = event.currentTarget;
        const step = document.querySelector('.rh-wizard-pane.show');
        return this.send(event, step)
            .then(() => this.navigate(id))
            .catch((error) => console.error(error));
    };

    onSubmit = (event) => {
        if (!['validate', 'continue'].includes(event.currentTarget.value)) {
            event.preventDefault();
            const step = event.currentTarget.closest('.rh-wizard-pane');
            return this.send(event, step, { button: event.currentTarget });
        }
    };

    navigate = (id) => {
        document.querySelector('.rh-wizard-indicator .active').classList.remove('active');
        document.querySelector('.rh-wizard-pane.fade.show').classList.remove('show');

        const tab = document.getElementById(id);
        const target = document.getElementById(tab.getAttribute('href').slice(1));
        tab.classList.add('active');
        target.classList.add('show');
    };

    send = (event, step, { button, input } = {}) => {
        const form = step.matches('form') ? step : step.querySelector('form');
        if (!form) return;
        if (this.xhr) {
            this.xhr.abort();
        } else {
            $('.rh-wizard-pane form').trigger('form_modifier.process_start');
        }

        const data = new FormData(form);
        if (button) data.set('submit', button.value);

        return new Promise((resolve, reject) => {
            this.xhr = new XMLHttpRequest();
            this.xhr.open(form.method || 'POST', form.action || document.location.href);
            this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            this.xhr.setRequestHeader('X-Wizard-Modifier', 'send');
            if (input) {
                this.xhr.setRequestHeader('X-Form-Modifier', 'fetch');
            }
            this.xhr.addEventListener('readystatechange', () => {
                if (this.xhr.readyState !== 4) return;
                const xhr = this.xhr;
                this.xhr = undefined;
                if ([301, 302].includes(xhr.status)) {
                    document.location.href = response.url;
                } else if (xhr.status === 200) {
                    resolve(xhr.responseText);
                } else if (xhr.status !== 0) {
                    reject(xhr.response);
                }
            });
            this.xhr.send(data);
        }).then((html) => {
            const $html = $('<div></div>').html(html);
            $('#alert-session-flash-container').html($html.find('#alert-session-flash-container').html());

            [['success', 'success'], ['error', 'danger']].forEach(([method, className]) => {
                $html.find(`#alert-session-flash-container .alert-${className}`).each(function () {
                    window.toastr[method]($(this).text());
                });
            });

            const STEPS = ['personal', 'contact', 'additional', 'files', 'social_dialog'];
            for (let idx = 0; idx < STEPS.length; idx++) {
                const name = `wizard_employee_${STEPS[idx]}`;
                // do not refresh form if change to avoid loose focus
                if (form.name === name && input) {
                    const updater = input.closest('[data-form-modifiers]');
                    if (updater) {
                        const targets = updater.dataset.formModifiers.split(',').map((selector) => selector.trim());
                        for (let idx = 0, len = targets.length; idx < len; idx++) {
                            const content = $html.find(targets[idx]).html();
                            const $target = $(targets[idx], form);
                            $target.html(content);
                            if ($target.length && content) {
                                initFormUi($target);
                            }
                        }
                    }
                } else {
                    const $pane = $html.find(`form[name="${name}"]`).closest('.rh-wizard-pane');
                    if ($pane.length) {
                        const pane = document.getElementById($pane.attr('id'));
                        pane.innerHTML = $pane.html();
                        window.initFormUi($(pane));
                    }
                }
            }

            const $subheader = $html.find('.rh-wizard-indicator');
            if ($subheader.length) {
                $('.rh-wizard-indicator').html($subheader.html());
            }

            const { id } = document.querySelector('.rh-wizard-indicator .rh-wizard-tab.active');
            return this.navigate(id);
        }, (error) => {
            console.log(error);
            throw new Error(error);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            $('.rh-wizard-pane form').trigger(FORM_MODIFIER_EVENT_PROCESS_END);

            this.disconnect();
            this.connect();
        });
    };
}

$.extend($.validator.messages, {
    required: "Ce champs est obligatoire.",
    email: "Email invalide.",
    url: "URL invalide.",
    date: "Date invalide.",
    number: "Nombre invalide.",
    digits: "Veuillez seulement renseigner des chiffres.",
    creditcard: "Votre numéro de carte est invalide.",
    equalTo: "La valeur n'est pas la même que celle renseigné précédemment",
    accept: "Fichier invalide, veuillez vérifier le format.",
    maxlength: $.validator.format("Chaine trop longue, ne pas dépasser {0}."),
    minlength: $.validator.format("Chaine trop courte, au moins {0}."),
    rangelength: $.validator.format("Chaine incorrecte, entre {0} et {1}."),
    range: $.validator.format("Nombre incorrecte, entre {0} et {1}."),
    max: $.validator.format("Nombre trop grand, ne pas dépasser {0}."),
    min: $.validator.format("Nombre trop petit, au moins {0}.")
});
