"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TableTree_ancestors, _TableTree_children, _TableTree_descendants;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRows = exports.toggleCollapse = exports.initRow = exports.initTable = void 0;
require("./table_tree.scss");
class TableTree {
    static setup(table) {
        if (TableTree.dictionary.has(table)) {
            return TableTree.dictionary.get(table);
        }
        const tree = new TableTree(table);
        console.log(tree);
        return tree;
    }
    constructor(table) {
        _TableTree_ancestors.set(this, new Map());
        _TableTree_children.set(this, new Map());
        _TableTree_descendants.set(this, new Map());
        this.rows = new Map();
        this.tree = new Map();
        TableTree.dictionary.set(table, this);
        this.table = table;
        this.setupTree();
    }
    append(row) {
        this.tree.set(row, row.getAttribute('data-parent'));
        this.rows.set(row.getAttribute('id'), row);
    }
    setupTree() {
        this.tree.clear();
        // Setup tree
        this.table.querySelectorAll('tbody tr').forEach((row) => {
            const id = row.getAttribute('id');
            this.tree.set(row, row.getAttribute('data-parent'));
            this.rows.set(id, row);
        });
    }
    getAncestors(row) {
        if (__classPrivateFieldGet(this, _TableTree_ancestors, "f").has(row)) {
            return __classPrivateFieldGet(this, _TableTree_ancestors, "f").get(row);
        }
        const ancestors = [];
        let parent = this.tree.get(row) || null;
        while (parent) {
            const ancestor = this.rows.get(parent);
            if (!ancestor)
                break;
            ancestors.unshift(ancestor);
            parent = this.tree.get(ancestor) || null;
        }
        __classPrivateFieldGet(this, _TableTree_ancestors, "f").set(row, ancestors);
        return ancestors;
    }
    getChildren(row) {
        if (__classPrivateFieldGet(this, _TableTree_children, "f").has(row)) {
            return __classPrivateFieldGet(this, _TableTree_children, "f").get(row);
        }
        const id = row.getAttribute('id');
        const entries = Array.from(this.tree.entries());
        const children = entries.filter(([, parent]) => parent === id).map(([row]) => row);
        __classPrivateFieldGet(this, _TableTree_children, "f").set(row, children);
        return children;
    }
    getDescendants(row, depth = 0) {
        if (__classPrivateFieldGet(this, _TableTree_descendants, "f").has(row)) {
            return __classPrivateFieldGet(this, _TableTree_descendants, "f").get(row);
        }
        if (depth > 100) {
            console.log(row);
            throw new Error('Maximum depth exceeded');
        }
        const children = this.getChildren(row);
        const descendants = children.reduce((acc, child) => {
            return [...acc, ...this.getDescendants(child, depth + 1)];
        }, children);
        __classPrivateFieldGet(this, _TableTree_descendants, "f").set(row, descendants);
        return descendants;
    }
}
_TableTree_ancestors = new WeakMap(), _TableTree_children = new WeakMap(), _TableTree_descendants = new WeakMap();
TableTree.dictionary = new Map();
function default_1(node) {
    $(node).find('table[data-table-tree]').each((idx, table) => {
        initTable(table, JSON.parse(table.getAttribute('data-table-tree') || '{}'));
    });
}
exports.default = default_1;
function initTable(table, { colors } = {}) {
    TableTree.setup(table);
    if (!table.classList.contains('table-tree')) {
        table.classList.add('table-tree');
    }
    table.querySelectorAll('tbody tr').forEach((element) => {
        const row = element;
        initRow(table, row, { colors });
    });
}
exports.initTable = initTable;
function initRow(table, row, props = {}) {
    var _a;
    const tree = TableTree.setup(table);
    // Add collapse show class by default
    row.classList.add('collapse', 'show');
    const { colors = [] } = props;
    // Temporary save indicator content
    const indicator = row.querySelector('.table-tree-indicator');
    const content = indicator.innerHTML.trim();
    const levels = [];
    indicator.innerHTML = '';
    // Save last level indicator
    const parents = [...tree.getAncestors(row), row];
    let container = indicator;
    // Initialize level indicators (bars on left)
    for (let idx = 0; idx < parents.length - 1; idx++) {
        const isDeepest = idx === parents.length - 2;
        const isLast = ((_a = parents[idx + 1]) === null || _a === void 0 ? void 0 : _a.getAttribute('data-last')) == 'true';
        const children = tree.getChildren(parents[idx]);
        const child = document.createElement('span');
        container.appendChild(child);
        levels.push(child);
        child.setAttribute('data-parent', parents[idx].getAttribute('id') || '');
        child.classList.add('table-tree-level');
        child.classList.add(isLast && !isDeepest ? 'border-transparent' : `border-${colors[idx] || 'dark'}`);
        if (children.length && isDeepest) {
            child.classList.add('table-tree-cross');
            if (isLast) {
                child.classList.add('table-tree-last');
            }
        }
        container = child;
    }
    // Setup last level indicator with content
    const hasChildren = tree.getChildren(row).length;
    const label = document.createElement('span');
    container.appendChild(label);
    label.innerHTML = content;
    label.classList.add(hasChildren ? 'table-tree-toggle' : 'table-tree-label', `border-${colors[parents.length] || 'dark'}`);
    if (hasChildren && !row.hasAttribute('aria-expanded')) {
        row.setAttribute('aria-expanded', 'true');
    }
    if (row.hasAttribute('aria-expanded') && row.getAttribute('aria-expanded') !== "true") {
        toggleCollapse(table, row);
    }
    indicator.addEventListener('click', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (!table.classList.contains('table-locked')) {
            const visible = row.getAttribute('aria-expanded') === "true";
            row.setAttribute('aria-expanded', !visible ? "true" : "false");
            toggleCollapse(table, row, !visible, evt.ctrlKey || evt.metaKey);
        }
    });
}
exports.initRow = initRow;
function toggleCollapse(table, parent, visible = false, toggleChildren = false) {
    const tree = TableTree.setup(table);
    const children = tree.getChildren(parent);
    const expanded = parent.getAttribute('aria-expanded') !== "false";
    children.forEach((row) => {
        row.classList.toggle('show', visible);
    });
    if (!visible || !expanded) {
        const descendants = tree.getDescendants(parent);
        descendants.forEach((row) => {
            row.classList.toggle('show', false);
            row.setAttribute('aria-expanded', 'false');
        });
    }
}
exports.toggleCollapse = toggleCollapse;
function updateRows(table, rows) {
    const tree = TableTree.setup(table);
    const updates = [];
    rows.forEach((row) => {
        const current = row.id && document.getElementById(row.id);
        if (!current) {
            let container = table.querySelector(`tr[id=${row.dataset.parent}]`);
            let children = table.querySelectorAll(`tr[id][data-parent="${container.id}"]`);
            while (children.length) {
                container = children[children.length - 1];
                children = table.querySelectorAll(`tr[data-parent="${container.id}"]`);
            }
            container === null || container === void 0 ? void 0 : container.after(row);
            tree.append(row);
        }
        else {
            const expanded = (current === null || current === void 0 ? void 0 : current.getAttribute('aria-expanded')) !== 'false';
            current.setAttribute('aria-expanded', expanded ? 'true' : 'false');
            current.innerHTML = row.innerHTML;
            row = current;
        }
        const ancestors = tree.getAncestors(row);
        let visible = true;
        for (let idx = 0; idx < ancestors.length; idx++) {
            const ancestor = ancestors[idx];
            if (ancestor.getAttribute('aria-expanded') === "false") {
                visible = false;
                break;
            }
        }
        if (!visible) {
            row.classList.remove('show');
        }
        updates.push(row);
    });
    return updates;
}
exports.updateRows = updateRows;
