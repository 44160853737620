"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const modal_1 = require("#assets/utils/modal");
class ButtonTableRowDeleteController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.onClick = (evt) => {
            evt.preventDefault();
            if (this.element.disabled)
                return;
            const icon = this.element.querySelector('.fal, .fas, .far, .fat, .fad');
            const names = (icon === null || icon === void 0 ? void 0 : icon.className.split(' ').filter((className) => className.match(/^fa-/))) || [];
            icon === null || icon === void 0 ? void 0 : icon.classList.remove(...names);
            icon === null || icon === void 0 ? void 0 : icon.classList.add('fa-sync-alt', 'fa-spin', 'disabled');
            this.element.disabled = true;
            new Promise((resolve, reject) => {
                const modal = (0, modal_1.confirm)(this.element.dataset.message || 'Êtes-vous sûr de vouloir supprimer cet élément ?', () => {
                    var _a;
                    // @ts-ignore no href on button, but meh
                    const url = this.element.href || this.element.dataset.target;
                    if (!url) {
                        (_a = this.element.closest('tr, li')) === null || _a === void 0 ? void 0 : _a.remove();
                        return resolve();
                    }
                    return fetch(url, {
                        method: 'GET',
                        headers: { 'X-Requested-With': 'XMLHttpRequest' },
                        credentials: 'same-origin',
                    }).then((response) => __awaiter(this, void 0, void 0, function* () {
                        var _b;
                        if (!response.ok) {
                            window.toastr.error('Une erreur est survenue');
                            reject(response.statusText);
                        }
                        else {
                            const message = yield response.text();
                            (_b = this.element.closest('tr, li')) === null || _b === void 0 ? void 0 : _b.remove();
                            window.toastr.success(message);
                            resolve();
                        }
                    }));
                }, {
                    title: this.element.dataset.title,
                    colorYes: "danger",
                    labelNo: this.element.dataset.action === 'cancel'
                        ? `<span class="fal fa-times mr-1"></span> Fermer`
                        : `<span class="fal fa-undo-alt mr-1"></span> Annuler`,
                    labelYes: this.element.dataset.action === 'cancel'
                        ? `<span class="fal fa-undo-alt mr-1"></span> Annuler`
                        : `<span class="fal fa-trash-alt mr-1"></span> Supprimer`,
                });
                $(modal).on('hidden.bs.modal', () => {
                    resolve();
                });
            }).finally(() => {
                icon === null || icon === void 0 ? void 0 : icon.classList.remove('fa-sync-alt', 'fa-spin', 'disabled');
                icon === null || icon === void 0 ? void 0 : icon.classList.add(...names);
                this.element.disabled = false;
            });
        };
    }
    connect() {
        // @ts-ignore WUT ? MouseEvent is not compatible with Event ?!
        this.element.addEventListener('click', this.onClick);
    }
    disconnect() {
        // @ts-ignore same here as above
        this.element.removeEventListener('click', this.onClick);
    }
}
exports.default = ButtonTableRowDeleteController;
