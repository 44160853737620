"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _DualListSelectController_actives, _DualListSelectController_dictionary, _DualListSelectController_direction, _DualListSelectController_groups, _DualListSelectController_observer, _DualListSelectController__container, _DualListSelectController__btnMoveBackward, _DualListSelectController__btnMoveForward, _DualListSelectController__listLeft, _DualListSelectController__listRight, _DualListSelectController_onClickMove, _DualListSelectController_onQuerySearch, _DualListSelectController_onQueryInput, _DualListSelectController_onSelectMutations;
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
const stimulus_1 = require("@hotwired/stimulus");
require("./dual_list_controller.scss");
// @todo remove when health mutual will be merged...
function debounce(callback, delay = 300) {
    let timeout = 0;
    return function trigger(...args) {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            callback(...args);
        }, delay);
    };
}
exports.debounce = debounce;
const DUAL_LIST_DEFAULT_DIRECTION = 'forward';
const DUAL_LIST_DIRECTION_BACKWARD = false;
const DUAL_LIST_DIRECTION_FORWARD = true;
const DUAL_LIST_TEMPLATE = document.createElement('template');
DUAL_LIST_TEMPLATE.innerHTML = `
<div class="select-dual-list">
  <div class="select-dual-list--container">
    <div class="select-dual-list--actions">
      <button type="button" class="btn btn-sm border" data-direction="right">
        <span class="fal fa-chevron-double-right fa-fw"></span>
      </button>
      <button type="button" class="btn btn-sm border" data-direction="left">
        <span class="fal fa-chevron-double-left fa-fw"></span>
      </button>
    </div>
    <div class="select-dual-list--side select-dual-list--left">
      <h6></h6>
      <div class="form-floating mb-2">
        <input type="search" class="form-control select-dual-list--query" placeholder="" />
        <label for="">Rechercher...</label>
      </div>
      <ul class="select-dual-list--list">
      </ul>
    </div>
    <div class="select-dual-list--side select-dual-list--right">
      <h6></h6>
      <div class="form-floating mb-2">
        <input type="search" class="form-control select-dual-list--query" placeholder="" />
        <label for="">Rechercher...</label>
      </div>
      <ul class="select-dual-list--list">
      </ul>
    </div>
  </div>
</div>
`.replace(/>[\n ] *</g, '><').trim();
class DualListSelectController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _DualListSelectController_actives.set(this, void 0);
        _DualListSelectController_dictionary.set(this, void 0);
        _DualListSelectController_direction.set(this, true);
        _DualListSelectController_groups.set(this, void 0);
        _DualListSelectController_observer.set(this, void 0);
        _DualListSelectController__container.set(this, void 0);
        _DualListSelectController__btnMoveBackward.set(this, void 0);
        _DualListSelectController__btnMoveForward.set(this, void 0);
        _DualListSelectController__listLeft.set(this, void 0);
        _DualListSelectController__listRight.set(this, void 0);
        _DualListSelectController_onClickMove.set(this, (direction) => () => {
            __classPrivateFieldGet(this, _DualListSelectController_actives, "f").forEach((value) => {
                this.move(value, this.getDirection(direction));
            });
            this.reorder();
        });
        _DualListSelectController_onQuerySearch.set(this, (evt) => {
            const input = evt.target;
            const query = input.value;
            if (!query)
                __classPrivateFieldGet(this, _DualListSelectController_onQueryInput, "f").call(this, evt);
        });
        _DualListSelectController_onQueryInput.set(this, debounce((evt) => {
            const input = evt.target;
            const query = input.value;
            const list = input.closest('.select-dual-list--side').querySelector('.select-dual-list--list');
            if (!query) {
                list.querySelectorAll('.select-dual-list--item.d-none').forEach((item) => item.classList.remove('d-none'));
            }
            else {
                list.querySelectorAll('.select-dual-list--item').forEach((item) => item.classList.toggle('d-none', !item.textContent.toLowerCase().includes(query)));
            }
        }, 100));
        _DualListSelectController_onSelectMutations.set(this, (mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === 'childList') {
                    this.setup();
                    this.render();
                    this.reorder();
                    break;
                }
            }
        });
    }
    static createGroupItem(group) {
        const item = document.createElement('li');
        item.classList.add('select-dual-list--group');
        item.dataset.label = group;
        item.textContent = group;
        return item;
    }
    static createChoiceItem(controller, option, group) {
        const item = document.createElement('li');
        item.classList.add('select-dual-list--item');
        item.dataset.group = group === null || group === void 0 ? void 0 : group.trim();
        item.dataset.value = option.value.trim();
        item.textContent = option.textContent.trim();
        item.addEventListener('click', (evt) => {
            if (!evt.altKey) {
                controller.toggle(option.value);
            }
            else {
                controller.move(option.value, !option.selected);
                controller.reorder();
            }
        });
        return item;
    }
    initialize() {
        var _a;
        __classPrivateFieldSet(this, _DualListSelectController_direction, !this.element.dataset.selectDualListDirection || this.element.dataset.selectDualListDirection === DUAL_LIST_DEFAULT_DIRECTION, "f");
        __classPrivateFieldSet(this, _DualListSelectController_observer, new MutationObserver(__classPrivateFieldGet(this, _DualListSelectController_onSelectMutations, "f")), "f");
        __classPrivateFieldSet(this, _DualListSelectController__container, DUAL_LIST_TEMPLATE.content.cloneNode(true).firstChild, "f");
        __classPrivateFieldSet(this, _DualListSelectController__listLeft, __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelector('.select-dual-list--left ul.select-dual-list--list'), "f");
        __classPrivateFieldSet(this, _DualListSelectController__listRight, __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelector('.select-dual-list--right ul.select-dual-list--list'), "f");
        __classPrivateFieldSet(this, _DualListSelectController__btnMoveBackward, __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelector('[data-direction="left"]'), "f");
        __classPrivateFieldSet(this, _DualListSelectController__btnMoveForward, __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelector('[data-direction="right"]'), "f");
        if (DUAL_LIST_DIRECTION_BACKWARD === __classPrivateFieldGet(this, _DualListSelectController_direction, "f")) {
            __classPrivateFieldGet(this, _DualListSelectController__btnMoveForward, "f").before(__classPrivateFieldGet(this, _DualListSelectController__btnMoveBackward, "f"));
        }
        const label = (_a = this.element.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('label');
        const titleLabel = (__classPrivateFieldGet(this, _DualListSelectController_direction, "f") === DUAL_LIST_DIRECTION_FORWARD ? __classPrivateFieldGet(this, _DualListSelectController__listRight, "f") : __classPrivateFieldGet(this, _DualListSelectController__listLeft, "f")).parentElement.querySelector('h6');
        titleLabel.innerHTML = (label === null || label === void 0 ? void 0 : label.innerHTML) || 'Éléments sélectionnés';
        if (label === null || label === void 0 ? void 0 : label.classList.contains('required')) {
            titleLabel.innerHTML += '*';
        }
        const placeholder = this.element.dataset.selectDualListPlaceholder;
        const titlePlaceholder = (__classPrivateFieldGet(this, _DualListSelectController_direction, "f") === DUAL_LIST_DIRECTION_FORWARD ? __classPrivateFieldGet(this, _DualListSelectController__listLeft, "f") : __classPrivateFieldGet(this, _DualListSelectController__listRight, "f")).parentElement.querySelector('h6');
        titlePlaceholder.innerHTML = placeholder || 'Liste de choix';
        this.element.before(__classPrivateFieldGet(this, _DualListSelectController__container, "f"));
        this.setup();
        this.render();
        this.reorder();
    }
    connect() {
        __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelectorAll('.select-dual-list--query').forEach((input) => {
            input.addEventListener('search', __classPrivateFieldGet(this, _DualListSelectController_onQuerySearch, "f"));
            input.addEventListener('keydown', __classPrivateFieldGet(this, _DualListSelectController_onQueryInput, "f"));
        });
        __classPrivateFieldGet(this, _DualListSelectController__btnMoveBackward, "f").addEventListener('click', __classPrivateFieldGet(this, _DualListSelectController_onClickMove, "f").call(this, DUAL_LIST_DIRECTION_BACKWARD));
        __classPrivateFieldGet(this, _DualListSelectController__btnMoveForward, "f").addEventListener('click', __classPrivateFieldGet(this, _DualListSelectController_onClickMove, "f").call(this, DUAL_LIST_DIRECTION_FORWARD));
        __classPrivateFieldGet(this, _DualListSelectController_observer, "f").observe(this.element, { childList: true, subtree: true });
    }
    disconnect() {
        __classPrivateFieldGet(this, _DualListSelectController__container, "f").querySelectorAll('.select-dual-list--query').forEach((input) => {
            input.removeEventListener('search', __classPrivateFieldGet(this, _DualListSelectController_onQuerySearch, "f"));
            input.removeEventListener('keydown', __classPrivateFieldGet(this, _DualListSelectController_onQueryInput, "f"));
        });
        __classPrivateFieldGet(this, _DualListSelectController__btnMoveBackward, "f").removeEventListener('click', __classPrivateFieldGet(this, _DualListSelectController_onClickMove, "f").call(this, DUAL_LIST_DIRECTION_BACKWARD));
        __classPrivateFieldGet(this, _DualListSelectController__btnMoveForward, "f").removeEventListener('click', __classPrivateFieldGet(this, _DualListSelectController_onClickMove, "f").call(this, DUAL_LIST_DIRECTION_FORWARD));
        __classPrivateFieldGet(this, _DualListSelectController_observer, "f").disconnect();
    }
    getDirection(selected) {
        return __classPrivateFieldGet(this, _DualListSelectController_direction, "f") === DUAL_LIST_DIRECTION_FORWARD ? selected : !selected;
    }
    getList(selected) {
        return this.getDirection(selected) ? __classPrivateFieldGet(this, _DualListSelectController__listRight, "f") : __classPrivateFieldGet(this, _DualListSelectController__listLeft, "f");
    }
    move(value, selected) {
        var _a;
        const opt = __classPrivateFieldGet(this, _DualListSelectController_dictionary, "f").get(value);
        opt.selected = selected;
        __classPrivateFieldGet(this, _DualListSelectController_dictionary, "f").set(value, opt);
        if (selected) {
            opt.option.setAttribute('selected', 'selected');
        }
        else {
            opt.option.removeAttribute('selected');
        }
        const list = this.getList(selected);
        // Add group header if not exists in list
        if (opt.group) {
            const group = list.querySelector(`li.select-dual-list--group[data-label="${opt.group}"]`);
            if (!group) {
                list.appendChild(DualListSelectController.createGroupItem(opt.group));
            }
        }
        list.appendChild(opt.item);
        opt.item.classList.toggle('selected', false);
        __classPrivateFieldGet(this, _DualListSelectController_actives, "f").delete(value);
        // Remove group header in inverse list if no more items
        if (opt.group) {
            const inverseList = this.getList(!selected);
            if (!inverseList.querySelectorAll(`li.select-dual-list--item[data-group="${opt.group}"]`).length) {
                (_a = inverseList.querySelector(`li.select-dual-list--group[data-label="${opt.group}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
            }
        }
    }
    render() {
        Array.from(__classPrivateFieldGet(this, _DualListSelectController_dictionary, "f").values()).forEach((opt) => {
            const list = this.getList(opt.selected);
            if (opt.group && !list.querySelector(`li.select-dual-list--group[data-label="${opt.group}"]`)) {
                list.appendChild(DualListSelectController.createGroupItem(opt.group));
            }
            list.appendChild(opt.item);
        });
    }
    reorder() {
        function sortAlphabetically(a, b) {
            return a.localeCompare(b);
        }
        [__classPrivateFieldGet(this, _DualListSelectController__listLeft, "f"), __classPrivateFieldGet(this, _DualListSelectController__listRight, "f")].forEach((list) => {
            // Option without group first
            Array.from(list.querySelectorAll('li.select-dual-list--item:not([data-group])'))
                .sort((a, b) => sortAlphabetically(a.textContent, b.textContent))
                .forEach((item) => list.appendChild(item));
            Array.from(__classPrivateFieldGet(this, _DualListSelectController_groups, "f").values()).sort(sortAlphabetically).forEach((label) => {
                const title = list.querySelector(`li.select-dual-list--group[data-label="${label}"]`);
                if (!title)
                    return;
                list.appendChild(title);
                Array.from(list.querySelectorAll(`li.select-dual-list--item[data-group="${label}"]`))
                    .sort((a, b) => sortAlphabetically(a.textContent, b.textContent))
                    .forEach((item) => list.appendChild(item));
            });
        });
    }
    setup() {
        [__classPrivateFieldGet(this, _DualListSelectController__listLeft, "f"), __classPrivateFieldGet(this, _DualListSelectController__listRight, "f")].forEach((element) => element.innerHTML = '');
        __classPrivateFieldSet(this, _DualListSelectController_actives, new Set(), "f");
        __classPrivateFieldSet(this, _DualListSelectController_dictionary, new Map(), "f");
        __classPrivateFieldSet(this, _DualListSelectController_groups, new Set(), "f");
        const setupGroup = (optgroup) => {
            const label = optgroup.label;
            __classPrivateFieldGet(this, _DualListSelectController_groups, "f").add(label);
            optgroup.childNodes.forEach((node) => {
                if (node instanceof HTMLOptionElement) {
                    setupOption(node, label);
                }
            });
        };
        const setupOption = (option, group) => {
            const item = DualListSelectController.createChoiceItem(this, option, group);
            __classPrivateFieldGet(this, _DualListSelectController_dictionary, "f").set(option.value, { group, item, label: item.textContent, option, selected: option.selected, value: option.value });
        };
        this.element.childNodes.forEach((node) => {
            if (node instanceof HTMLOptGroupElement) {
                setupGroup(node);
            }
            else if (node instanceof HTMLOptionElement) {
                setupOption(node);
            }
        });
    }
    toggle(value, forced) {
        const { item } = __classPrivateFieldGet(this, _DualListSelectController_dictionary, "f").get(value);
        if (undefined === forced) {
            forced = __classPrivateFieldGet(this, _DualListSelectController_actives, "f").has(value);
        }
        if (forced) {
            __classPrivateFieldGet(this, _DualListSelectController_actives, "f").delete(value);
            item.classList.remove('selected');
        }
        else {
            __classPrivateFieldGet(this, _DualListSelectController_actives, "f").add(value);
            item.classList.add('selected');
        }
    }
}
exports.default = DualListSelectController;
_DualListSelectController_actives = new WeakMap(), _DualListSelectController_dictionary = new WeakMap(), _DualListSelectController_direction = new WeakMap(), _DualListSelectController_groups = new WeakMap(), _DualListSelectController_observer = new WeakMap(), _DualListSelectController__container = new WeakMap(), _DualListSelectController__btnMoveBackward = new WeakMap(), _DualListSelectController__btnMoveForward = new WeakMap(), _DualListSelectController__listLeft = new WeakMap(), _DualListSelectController__listRight = new WeakMap(), _DualListSelectController_onClickMove = new WeakMap(), _DualListSelectController_onQuerySearch = new WeakMap(), _DualListSelectController_onQueryInput = new WeakMap(), _DualListSelectController_onSelectMutations = new WeakMap();
