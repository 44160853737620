import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { getIndex } from "#utils/dom";
import "./sortable_controller.scss";

export default class ListSortableController extends Controller {
    static targets = ['input', 'item', 'selector'];

    initialize() {
        this.sortable = new Sortable(this.element, {
            handle: '[data-list--sortable-target~="selector"]',
            draggable: '[data-list--sortable-target~="item"]',
            group: this.element.dataset['list-SortableGroup'],
            direction: 'vertical',
            multiple: true,
            onEnd: this.onEnd
        });
    }

    onEnd = () => {
        this.element.dispatchEvent(new Event('list--sortable::change'));
        if (!this.element.querySelector('[data-list--sortable-target="input"]')) return;
        this.element.querySelectorAll('[data-list--sortable-target="input"]').forEach((input) => {
            input.value = getIndex(input.closest('[data-list--sortable-target="item"]'));
        });
    }
}
