const dropdownSearch = ($container) => {
    const $search = $('<input type="text" class="form-control" />');
    const $item = $('<div class="query"></div>');
    $item.append($search);
    $container.find('.dropdown-menu').prepend($item);
    $search.on('input', function (event) {
        if (!event.target.value) {
            $container.find('.dropdown-item[data-value]').removeClass('d-none');
        } else {
            const value = event.target.value.toLowerCase();
            $container.find('.dropdown-item[data-value]').each(function () {
                const $choice = $(this);
                if ($choice.text().toLowerCase().includes(value)) {
                    $choice.removeClass('d-none');
                } else {
                    $choice.addClass('d-none');
                }
            });
        }
    });
};

window.initSiteFilter = function initSiteFilter() {
    const $container = $('#site_filter');
    dropdownSearch($container);

    $container.find('.dropdown-menu a').on('click', function (evt) {
        evt.preventDefault();

        $container.find('select').val($(this).attr('data-value'));
        $container.find('form').submit();
    });
}

window.initServiceFilter = function initServiceFilter() {
    const $container = $('#service_filter');
    dropdownSearch($container);

    $container.find('.dropdown-menu a').on('click', function (evt) {
        evt.preventDefault();

        $container.find('select').val($(this).attr('data-value'));
        $container.find('form').submit();
    });
}

window.initValidContractFilter = function initValidContractFilter() {
    const $container = $('#contract_filter');
    dropdownSearch($container);

    $container.find('.dropdown-menu a').on('click', function (evt) {
        evt.preventDefault();

        $container.find('select').val($(this).attr('data-value'));
        $container.find('form').submit();
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('site_filter')) window.initSiteFilter();
    if (document.getElementById('service_filter')) window.initServiceFilter();
    if (document.getElementById('contract_filter')) window.initValidContractFilter();
});
