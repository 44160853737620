const $ = require("jquery");
import initTableTree from "./table_tree";
import initXhrList from "./xhr_list";

export default function initXhrContent(node) {
  $('[data-xhr-content]', node).each(function () {
    const $node = $(this);
    const url = $node.data('xhr-content');
    if (!url) return;

    function retrieve() {
      const json = $node.data('xhr-json');
      let data = undefined;
      if (json) {
        const keys = Object.keys(json);
        data = new FormData();
        for (let idx = 0; idx < keys.length; idx++) {
          const key = keys[idx];
          data.set(key, json[key]);
        }
      }

      return window.fetch(url, {
        method: data ? 'POST' : 'GET',
        credentials: 'same-origin',
        body: data,
        headers: {
          'X-Fetch': 'xhr-content',
          'X-Requested-With': 'XMLHttpRequest',
        }
      }).then((response) => {
        if (response.ok) return response.text();
        throw new Error(response.statusText);
      });
    }

    if ($node.is('button')) {
      $node.on('click', () => {
        $node.prop('disabled', true);
        const $container = $($node.data('xhr-target'));
        retrieve().then((html) => {
          $container.html(html);
          initContent($container);
        }).then(() => {
          $container.get(0)?.dispatchEvent(new CustomEvent('sirh::xhr-content'));
        }).catch((error) => {
          $container.get(0)?.dispatchEvent(new CustomEvent('sirh::xhr-content', { detail: error }));
        }).finally(() => {
          $node.prop('disabled', false);
        });
      });
    } else {
      retrieve().then((html) => {
        $node.html(html);
        initContent($node);
      }).then(() => {
        $node.get(0)?.dispatchEvent(new CustomEvent('sirh::xhr-content'));
      }).catch((error) => {
        $node.get(0)?.dispatchEvent(new CustomEvent('sirh::xhr-content', { detail: error }));
      });
    }
  });
}

export function initContent(node) {
  window.initFormUi(node);
  window.initFormAjaxSubmit(node.get(0));
  window.initModal(node);
  window.initModalForms(node);
  window.initConfirmDialog(node);
  window.initPopinTrigger(node.get(0));
  window.initLinkFetch(node.get(0));
  window.initSelectAll(node.get(0));
  window.initDataTables(node.get(0));
  window.initTreeView(node);
  window.initDropdown(node);
  initTableTree(node);
  initXhrContent(node);
  initXhrList(node);
}

