const $ = require("jquery");

export default function initXhrList(parent) {
  $('[data-list-xhr]', parent).each((idx, node) => {
    const props = JSON.parse(node.dataset.listXhr || '{}');
    const $panel = $(node).closest('.panel');

    let { offset } = props;
    let rows = '';

    (function send() {
      if (offset > props.count) return;

      let data = undefined;
      if (props.data) {
        const keys = Object.keys(props.data);
        data = new FormData();
        for (let idx = 0; idx < keys.length; idx++) {
          const key = keys[idx];
          data.set(key, props.data[key]);
        }
      }

      return window.fetch(`${props.url}?offset=${offset}&limit=${props.limit}`, {
        method: data ? 'POST' : 'GET',
        credentials: 'same-origin',
        body: data,
        headers: {
          'X-Fetch': 'xhr-list',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then((response) => {
        if (response.ok) return response.text();
        throw new Error(response.statusText);
      }).then((html) => {
        rows += html;
        offset += props.limit;
        if ($panel.length) {
          $panel.find('.xhr-loading-value').html(offset);
        }

        return send();
      });
    })().then(() => {
      if (!$(node).is('table')) {
        $(node).append(rows);
      } else if ($(node).hasClass('datatable')) {
        const api = $(node).dataTable().api();
        $(rows).each((i, row) => {
          if ($(row).is('tr')) {
            api.row.add(row);
          }
        });
        api.draw();
      } else {
        $(node).find('tbody').append(rows);
      }

      if ($panel.length) {
        $panel.find('.xhr-loading-state').remove();
        $panel.find('.panel-container').collapse('show');
        $panel.find('.panel-hdr .fal')
          .removeClass('fa-spinner fa-spin')
          .addClass(`fa-${props.icon}`)
        ;

        if (!rows.length) {
          $panel.remove();
        }
      }
    });
  });
}
