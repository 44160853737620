import { Controller } from "@hotwired/stimulus";

const AUTOCOMPLETE_CHOICE = '<a href="#!" class="dropdown-item text-left">__label__</a>';

export default class FormAutocompleteController extends Controller {
    connect() {
        const $element = $(this.element);
        const scope = $element.data('autocomplete');
        const $label = $element.find('label');
        const $input = $element.find('input[type="hidden"]');
        const $output = $element.find('input[type="text"]');
        const $menu = $element.find('.dropdown-menu');
        const $loader = $element.find('.dropdown-loader');

        $output.val($input.val());
        if ($input.hasClass('is-invalid')) {
            $output.addClass('is-invalid');
        }
        if ($input.hasClass('is-valid')) {
            $output.addClass('is-valid');
        }

        let xhr;
        $output.on('input', function () {
            const value = $output.val();
            if (xhr) xhr.abort();
            if (value.length < 3) return true;
            $loader.removeClass('d-none');
            $menu.html('');

            xhr = new XMLHttpRequest();
            xhr.open('GET', `/json/autocomplete/${scope}?term=${encodeURIComponent(value)}`);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Cache-Control', 'no-cache');
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.withCredentials = true;

            xhr.addEventListener('load', function () {
                const terms = JSON.parse(xhr.responseText);

                for (let idx = 0, len = terms.length; idx < len; idx++) {
                    const label = terms[idx].label;
                    const $choice = $(AUTOCOMPLETE_CHOICE.replace('__label__', label));
                    $menu.append($choice);
                    (($choice, { id, label, values }) => {
                        $choice.on('mousedown', () => {
                            $output.data('info', values);

                            $menu.removeClass('show');
                            $input.val(id);
                            $output.val(label);
                            $input.trigger('change');
                        });
                    })($choice, terms[idx]);
                }

                $loader.addClass('d-none');
                $menu.addClass('show');
                $menu.trigger('autocomplete::loaded');
            });
            xhr.send();
        });

        $label.on('click', function () {
            $label.addClass('active');
            $input.focus();
        });

        $output.on('focus', function () {
            $label.addClass('active');
        });

        $output.on('blur', () => {
            if (!$input.val()) {
                $label.removeClass('active');
            }
            $menu.removeClass('show');
            $menu.html('');
        });

        if ($input.val()) {
            $output.addClass('active');
            $input.addClass('active');
            $label.addClass('active');
        }
    }
}
