import React, { useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
import Classnames from 'classnames';
import { Controller } from "@hotwired/stimulus";
import ICONS, { BRAND_ICONS } from '../../constants/fontawesome';

export default class FormSelectFontAwesomeController extends Controller {
    initialize() {
        this.container = document.createElement('div');
        this.element.classList.add('visually-hidden');
        this.element.after(this.container);
    }

    connect() {
        const modal = this.element.closest('.modal:not(.show)');
        const collapse = this.element.closest('.collapse:not(.show)');

        if (modal) {
            $(modal).one('shown.bs.modal', this.onShown);
        } else if (collapse) {
            $(collapse).one('shown.bs.collapse', this.onShown);
        } else {
            this.onShown();
        }
    }

    disconnect() {
        const modal = this.element.closest('.modal:not(.show)');
        const collapse = this.element.closest('.collapse:not(.show)');

        if (modal) {
            $(modal).off('shown.bs.modal', this.onShown);
        } else if (collapse) {
            $(collapse).off('shown.bs.collapse', this.onShown);
        }
    }

    onShown = () => {
        ReactDOM.render(
            <SelectFontAwesomeContainer
                node={this.element}
            />,
            this.container
        );
    };
}

const ICON_PREFIXES = ['fal', 'far', 'fas', 'fad', 'fab'];
const ICON_CATEGORIES = ['Light', 'Regular', 'Solid', 'Duotone', 'Brand'];

function SelectFontAwesomeContainer(props) {
    const [prefixes, setPrefixes] = useState(['fal', 'fab']);
    const [query, setQuery] = useState('');
    const [value, setValue] = useState(props.node.value);

    const commonPrefixes = useMemo(() => prefixes.filter((category) => category !== 'fab'), [prefixes]);

    const onChangeQuery = (evt) => setQuery(evt.target.value);
    const onClickPrefix = (prefix) => (evt) => {
        evt.preventDefault();
        if (prefixes.includes(prefix)) {
            setPrefixes(prefixes.filter((p) => p !== prefix));
        } else {
            setPrefixes([...prefixes, prefix]);
        }
    };

    const onClickIcon = (icon) => (evt) => {
        evt.preventDefault();
        props.node.value = icon;
        props.node.dispatchEvent(new Event('change'));
        setValue(icon);
    };

    const icons = [].concat(
        ICONS.reduce((accu, icon) => accu.concat(commonPrefixes.map((prefix) => `${prefix} fa-${icon}`)), []),
        BRAND_ICONS.map((icon) => `fab fa-${icon}`),
    ).filter((icon) => {
        return icon.toUpperCase().indexOf(query.toUpperCase()) >= 0;
    });

    return <div className="sfa-wrapper">
        <div className="form-floating form-group">
            <input type="search" className="form-control" placeholder="" value={query} onChange={onChangeQuery} />
            <label>Rechercher une icône</label>
        </div>
        <div className="d-flex mb-3">
            {ICON_PREFIXES.map((prefix, idx) => {
                const active = prefixes.includes(prefix);

                return <button key={`filter-prefix-${prefix}`} type="button" className={Classnames('btn btn-outline-primary mx-1', active && 'active')} onClick={onClickPrefix(prefix)}>
                    {ICON_CATEGORIES[idx]}
                </button>;
            })}
        </div>
        <div className="sfa-container row">
            {icons.map((icon) => {
                return <div key={`icon-${icon.replace(/ /g, '-')}`} className="col-12 col-md-4 col-xl-2">
                    <p className={Classnames('d-flex flex-column justify-content-center align-items-center cursor-pointer p-3', value === icon && 'bg-primary text-white')} onClick={onClickIcon(icon)}>
                        <span className={`${icon} fa-fw fa-3x mb-2`} />
                        {icon.replace(/fa[srldb] fa-/g, '')}
                    </p>
                </div>;
            })}
        </div>
    </div>;
}
