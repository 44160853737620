import { Controller } from "@hotwired/stimulus";
import { translate } from "@jochlain/translations-yaml/macro";

export default class ExportFieldController extends Controller {
    static targets = ['group', 'title', 'type'];
    details = this.element.matches('details') ? this.element : this.element.closest('details');

    initialize() {
        this.onToggleOpen({ target: this.details });
        this.changeSummary()
    }

    connect() {
        this.details.addEventListener('toggle', this.onToggleOpen);
        this.element.addEventListener('change', this.onChange);
        this.titleTarget.addEventListener('keyup', this.changeSummary);
    }

    disconnect() {
        this.details.removeEventListener('toggle', this.onToggleOpen);
        this.element.removeEventListener('change', this.onChange);
        this.titleTarget.removeEventListener('keyup', this.changeSummary);
    }

    changeSummary = () => {
        const summary = this.element.querySelector('summary');
        const field_group = this.groupTarget.querySelector(`option[value="${this.groupTarget.value}"]`);
        const field_type = this.typeTarget.querySelector(`option[value="${this.typeTarget.value}"]`);
        if (this.titleTarget.value || field_type.value) {
            summary.innerHTML = `${this.titleTarget.value}`;
            if (field_type.value) {
                let label = field_type.innerHTML;
                if (field_group) label = `${field_group.innerHTML} >> ` + label;
                summary.innerHTML += ` <small class="font-italic ml-1">(${label})</small>`;
            }
        } else {
            const locale = document.body.parentElement.lang;
            summary.innerText = translate('New field', {}, { host: 'export', locale });
        }
    };

    onChange = (event) => {
        if (event.target.matches('[data-form--views--export--field-target="type"]')) {
            this.changeSummary();
        }
    };

    onToggleOpen = (event) => {
        if (event.target.open) {
            event.target.parentElement.querySelectorAll('details').forEach((details) => {
                if (details.open && details !== event.target) {
                    details.open = false;
                }
            });
        }
    };
}
