"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class LayoutPanelController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.onCollapse = () => {
            const btn = this.element.querySelector('[data-action="panel-collapse"]');
            const label = (btn === null || btn === void 0 ? void 0 : btn.getAttribute('data-title-expand')) || 'Agrandir';
            this.setTooltipText(btn, label);
        };
        this.onShown = () => {
            const btn = this.element.querySelector('[data-action="panel-collapse"]');
            const label = (btn === null || btn === void 0 ? void 0 : btn.getAttribute('data-title-collapse')) || 'Réduire';
            this.setTooltipText(btn, label);
        };
        this.onToggle = () => {
            var _a;
            const isExpanded = (_a = this.element.querySelector('.panel-container')) === null || _a === void 0 ? void 0 : _a.classList.contains('show');
            (isExpanded ? this.onShown : this.onCollapse)();
        };
    }
    initialize() {
        this.onToggle();
        const btn = this.element.querySelector('[data-action="panel-collapse"]');
        btn === null || btn === void 0 ? void 0 : btn.removeAttribute('title');
        btn === null || btn === void 0 ? void 0 : btn.removeAttribute('data-title');
    }
    connect() {
        $('.panel-container', this.element).on('shown.bs.collapse', this.onToggle);
        $('.panel-container', this.element).on('hidden.bs.collapse', this.onToggle);
        this.onToggle();
    }
    disconnect() {
        $('.panel-container', this.element).off('shown.bs.collapse', this.onToggle);
        $('.panel-container', this.element).off('hidden.bs.collapse', this.onToggle);
    }
    setTooltipText(element, text) {
        // @ts-ignore
        $(element).attr('data-original-title', text).tooltip('update');
    }
}
exports.default = LayoutPanelController;
