import { Controller } from "@hotwired/stimulus";
import { parseDataTargetSelector as parseTarget } from "#utils/dom";
import "./multiple_controller.scss";

const DATA_CHOICE = parseTarget('choice', 'form--choice--multiple');
const DATA_INPUT = parseTarget('input', 'form--choice--multiple');
const DATA_LABEL = parseTarget('label', 'form--choice--multiple');

export default class FormChoiceMultipleController extends Controller {
  static targets = ['container', 'choice', 'label', 'form', 'input'];

  connect() {
    this.element.querySelectorAll(DATA_CHOICE).forEach((choice) => {
      choice.addEventListener('click', this.onClickAdd);
    });
    this.element.querySelectorAll(DATA_INPUT).forEach((input) => {
      input.addEventListener('click', this.onClickRemove);
    });
  }

  disconnect() {
    this.element.querySelectorAll(DATA_CHOICE).forEach((choice) => {
      choice.removeEventListener('click', this.onClickAdd);
    });
    this.element.querySelectorAll(DATA_INPUT).forEach((input) => {
      input.removeEventListener('click', this.onClickRemove);
    });
  }

  onClickAdd = (event) => {
    const choice = event.currentTarget.querySelector(DATA_LABEL);

    const icon = document.createElement('span');
    icon.classList.add('far');
    icon.classList.add('fa-minus');
    icon.classList.add('fa-fw');
    icon.classList.add('text-danger');
    icon.classList.add('mr-1');

    const input = document.createElement('input');
    input.name = 'input[]';
    input.type = 'hidden';
    input.value = choice.getAttribute('rel');

    const label = document.createElement('span');
    label.innerText = choice.innerText;

    const entry = document.createElement('div');
    entry.appendChild(input);
    entry.appendChild(icon);
    entry.appendChild(label);
    entry.setAttribute('data-form--choice--multiple-target', 'input');

    entry.addEventListener('click', this.onClickRemove);

    this.formTarget.appendChild(entry);
  };

  onClickRemove = (event) => {
    event.currentTarget.remove();
  };
}
