const $ = require("jquery");

const DEFAULTS = {
    plugins: [
        "dnd", "types",
    ],
    types : {
        "#": {"max_children": 1, "max_depth": 20, "valid_children": ["root"]},
        "root": {"icon": "fa fa-folder", "valid_children": ["default"]},
        "default": {"icon": "fa fa-folder-open", "valid_children": ["default", "file"]},
        "file": {"icon": "fa fa-file", "valid_children": []}
    }
};

class FileManager
{
    constructor(node, options)
    {
        this.options = $.extend(true, {}, DEFAULTS, options);

        this.listDataTable = null;

        // get nodes
        this.container = $(node);
        this.toolsContainer = $(node).find("[data-role=tools]");
        this.treeContainer = $(node).find("[data-role=categories]");
        this.listContainer = $(node).find("[data-role=list]");
        this.formContainer = $(node).parent().find("[data-role=form]");
        this.consoleContainer = $(node).find("[data-role=console]");

        // hack grid for tablet because DataTables does not calculate responsive breakpoints on container width but screem's one
        if (this.isProbablyATablet()) {
            this.treeContainer.removeClass('col-lg-4');
            this.listContainer.removeClass('col-lg-8');
        }

        // keep employee
        this.employee = this.container.data("employee");
        this.debug = /\.local(host)?$/.test(window.location.hostname);

        // Initialize tools
        if (undefined !== this.toolsContainer) {
            this.toolsContainer.find("button[data-toggle]").on("click", (evt) => {
                this.treeContainer.trigger("jstree." + $(evt.currentTarget).data("toggle") + ".sirh", evt);
            });
        }

        this.initialize();

        // Initialize console
        if (undefined !== this.consoleContainer) {
            this
                .consoleContainer
                .removeClass("d-none")
                .find(".alert")
                .hide()
            ;
        }

        // Initialize list
        this.treeContainer
            .on("loaded.jstree", this.onLoad.bind(this))
            .on("select_node.jstree", this.displayCategoryAssociatedFiles.bind(this))
            .on("jstree.toggle_tree.sirh", this.toggleTree.bind(this))
            .on("jstree.refresh.sirh", this.refresh.bind(this))
            .on("jstree.add_file.sirh", this.addFile.bind(this))
            .on("jstree.move_file.sirh", this.moveFile.bind(this))
            .on("jstree.update.success.sirh", this.showConsole.bind(this, "success"))
            .on("jstree.update.error.sirh", this.showConsole.bind(this, "error"))
        ;
    }

    initialize()
    {
        if (undefined !== this.listContainer) {
            this.listContainer.addClass("d-none");
        }

        // Initialize tree
        if (undefined !== this.treeContainer && undefined !== this.options.callbacks.tree_read) {
            this.showLoader(true);

            // get tree json then init tree view
            $.when($.ajax({
                url: this.options.callbacks.tree_read,
                dataType: "json",
                method: "GET"
            })).done((data) => {
                this.treeData = data;

                if (0 === this.treeData.length) {
                    this.showConsole("warning");
                    this.showLoader(false);
                }

                this.treeContainer.jstree($.extend(true, {}, this.options, {
                    core: {
                        check_callback: true,
                        data: data,
                        themes: {
                            name: "proton",
                            responsive: true
                        },
                        worker: false
                    }
                }));
            });
        }

        if (1100 > window.innerWidth) {
            this.toggleTree();
        }

        if (undefined !== this.options.tools.upload || 1 === this.options.tools.upload && undefined !== this.formContainer && undefined !== this.options.callbacks.form) {
            $.when($.ajax({
                url: this.options.callbacks.form,
                dataType: "json",
                method: "GET"
            })).done((data) => {
                if (undefined !== data.form) {
                    this.formContainer.append(data.form);
                    initFormUi(this.formContainer);

                    this.toolsContainer.find("[data-toggle=add_file]").removeClass("d-none");
                } else {
                    this.formContainer.html("");
                }
            });
        }
    }

    displayCategoryAssociatedFiles(evt)
    {
        const node = this.getSelected();
        if (null === node) {
            return false;
        }

        this.listContainer.addClass("loading");

        // get files
        $.ajax({
            url: this.options.callbacks.read,
            dataType: "html",
            method: "GET",
            data: { category: node.id },
            success: (data) => {
                this.listContainer.unbind();
                this.listContainer
                    .removeClass("d-none")
                    .html(data)
                ;

                if (!this.options.tools["upload"]) {
                    this.listContainer.find("[data-toggle=file-move]").remove();
                }
                if (!this.options.tools["delete"]) {
                    this.listContainer.find("[data-toggle=dropzone-file-delete]").remove();
                }

                // init content
                initPopinTrigger(this.listContainer);
                initFormDropZone(this.listContainer);
                initLinkFetch(this.listContainer.get(0));

                // move file
                this.listContainer
                    .find("[data-toggle=file-move]")
                    .on("click", (evt) => {
                        this.treeContainer.trigger("jstree.move_file.sirh", evt);
                    })
                ;

                this.listDataTable = createDataTable(this.listContainer.find('table').get(0));
            },
            error: () => {
                this.treeContainer.trigger("jstree.update.error.sirh");
            },
            complete: () => {
                this.showLoader(false);
            }
        });
    }

    getSelected()
    {
        const selected = this.tree.get_selected(true);

        if (!selected.length) {
            return null;
        }

        return selected[0];
    }

    onLoad()
    {
        let data = this.tree.settings.core.data[0];
        if (this.options.default_category_selected) {
            const default_selected = this.options.default_category_selected;
            const _data = this.tree.settings.core.data.find(function (data) {
                return Number(data.id) === Number(default_selected);
            });
            if (_data) {
                data = _data;
            }
        }
        this.tree.select_node(this.tree.get_node(data, false));
    }

    refresh()
    {
        this.tree.destroy();
        this.listContainer.html("");
        this.treeContainer.html("");
        this.formContainer.html("");
        this.toolsContainer.find("*").off();
        this.toolsContainer.find("[data-toggle=add_file]").addClass("d-none");

        // clear all events
        this.container.find("*").off();

        this.listDataTable = null;

        this.container.filemanager(this.options);
    }

    toggleTree()
    {
        const button = document.querySelector('button[data-toggle="toggle_tree"]');
        if (this.treeContainer.hasClass('d-none')) {
            this.treeContainer.removeClass("d-none");
            if (!this.isProbablyATablet()) {
                this.listContainer.addClass("col-lg-8");
            }
            this.listContainer.addClass("col-xl-9");
            $(button).removeClass('btn-info');
            $(button).addClass('btn-warning');
            button.innerHTML = `<span class="fal fa-folder fa-fw mr-1"></span>${button.dataset.titleClose}`;
        } else {
            this.treeContainer.addClass("d-none");
            if (!this.isProbablyATablet()) {
                this.listContainer.removeClass("col-lg-8");
            }
            this.listContainer.removeClass("col-xl-9");
            $(button).removeClass('btn-warning');
            $(button).addClass('btn-info');
            button.innerHTML = `<span class="fal fa-folder-open fa-fw mr-1"></span>${button.dataset.titleOpen}`;
        }
    }

    moveFile(element, evt)
    {
        evt.preventDefault();

        const node = $(evt.delegateTarget);
        const tree = $("<div></div>");
        tree.jstree($.extend(true, {}, this.options, {
            core: {
                check_callback: true,
                data: this.treeData,
                multiple: false,
                themes: {
                    name: "proton",
                    responsive: true
                }
            },
            plugins: [ "checkbox" ],
            checkbox: {
                three_state: false,
                cascade: "none"
            }
        }));

        const $modal = $('<div class="modal fade"><div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"><div class="modal-content"><div class="modal-body"></div><div class="modal-footer"></div></div></div></div>');
        $(document.body).append($modal);
        const title = node.attr("title");
        if (title) {
            $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">${title}</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
        }
        $modal.find('.modal-body').append(tree);
        $modal.find('.modal-footer').append(`<button type="button" class="btn btn-default" data-dismiss="modal"><i class="fal fa-undo mr-1"></i>Annuler</button>`);
        const $submit = $(`<button type="button" class="btn btn-primary"><i class="fal fa-save mr-1"></i>Sauver</button>`);
        $modal.find('.modal-footer').append($submit);
        $submit.on('click', () => {
            let selected = tree.jstree().get_selected(false);
            selected = (selected.length) ? selected[0] : null;

            if (selected !== Number(node.data("category-id"))) {
                $.ajax({
                    url: node.data("href"),
                    dataType: "json",
                    method: (true === this.debug ? "GET" : "POST"),
                    data: { category: selected },
                    success: (data) => {
                        node
                            .data("category-id", data.category.id)
                            .closest("tr")
                            .find("[data-role=file-category-path]")
                            .text(data.category.path);

                        this.treeContainer.trigger("jstree.update.success.sirh");
                    },
                    error: () => {
                        this.treeContainer.trigger("jstree.update.error.sirh");
                    },
                    complete: (dialog) => {
                        dialog.modal("hide");
                        this.showLoader(false);
                    }
                });
            } else {
                $modal.modal("hide");
            }
        })

        $modal.on("shown.bs.modal", () => {
            tree
                .jstree("open_all")
                .jstree("select_node", node.data("category-id"))
            ;
        });
        $modal.on('hidden.bs.modal', () => {
            $modal.remove();
        });
        $modal.modal('show');
    }

    addFile(element, evt)
    {
        evt.preventDefault();

        if (undefined === this.options.tools.upload || 0 === this.options.tools.upload || undefined === this.options.callbacks.upload) {
            return;
        }

        const target = $(evt.delegateTarget);
        const form = $("[data-role=add_file]")
            .clone(true)
            .removeClass("d-none")
        ;

        const dialog = window.bootbox.dialog({
            size: "lg",
            className: "popin",
            title: target.attr("title"),
            centerVertical: true,
            message: form
        });

        dialog.find('.modal-dialog').addClass('modal-dialog-centered');
        dialog.on("shown.bs.modal", (e) => {
            const form = dialog.find(".bootbox-body form");

            // init dropzone
            form
                .attr("action", this.options.callbacks.upload)
                .attr("data-xhr", "true")
                .attr("method", "get")
                .find(".dropzone")
                .addClass("autodiscover")
                .attr("id", "dropzone_" + Date.now())
            ;

            // clear buttons
            form.find("button").off();

            // init callbacks
            const success = (data, textStatus, jqXHR) => {
                this.treeContainer.trigger("jstree.update.success.sirh");
                dialog.modal("hide");

                $(data.rows).each(function (i) {
                    this.listDataTable.row.add($(data.rows[i]));
                }.bind(this));
                this.listDataTable.draw(true);
            };

            const error = (jqXHR, textStatus, errorThrown) => {
                dialog.modal("hide");
                this.treeContainer.trigger("jstree.update.error.sirh");
            };

            initFormXhr(form.parent(), success, error, () => {});
            initFormDropZone(form.parent());

            // cancel
            form
                .find("button[data-toggle=close]")
                .on("click", (evt) => {
                    evt.preventDefault();
                    dialog.modal("hide");
                })
            ;
        })
    }

    showConsole(result)
    {
        this.showLoader(false);

        this
            .consoleContainer
            .find(".alert.alert-" + result)
            .fadeIn(3000)
            .fadeOut(3000)
        ;
    }

    showLoader(state)
    {
        if (true === state) {
            this.container.parent().addClass("processing");
            this.container.append("<div class=\"loader\">&nbsp;</div>");
        } else {
            this.container.parent().removeClass("processing");
            this.container.find(".loader").remove();
        }
    }

    isProbablyATablet()
    {
        return (1100 > window.innerWidth && 768 <= window.innerWidth);
    }

    get tree()
    {
        return this.treeContainer.jstree(true);
    }
}

$.fn.filemanager = function(options) {
    return this.each(function() {
        new FileManager(this, options);
    });
};

$(document).ready(function () {
    $("[data-role=filemanager]").each(function() {
        var uid = $(this).data("uid");
        var options = window["_filemanager_configuration_" + uid];
        if (undefined !== uid && undefined !== options) {
            $(this).filemanager(options);
        }
    });
});
