import LayoutController from "./layout_controller";

export default class LayoutShortcutsController extends LayoutController {
    initialize() {
        const endpoint = 'shortcuts';
        const uri = this.getEndpointUri(endpoint);
        if (undefined === uri) {
            return;
        }

        this
            .fetch(endpoint, uri)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
            }).then((html) => {
                this.element.innerHTML = html;

                this.element.previousElementSibling?.classList.remove('d-none');
            })
        ;
    }
}
