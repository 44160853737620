import { Controller } from "@hotwired/stimulus";

export default class FormSwitchStateController extends Controller {
    connect() {
        this.element.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.element.removeEventListener('click', this.onClick);
    }

    onClick = (evt) => {
        evt.preventDefault();

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open('POST', this.element.href);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.withCredentials = true;
        xhr.addEventListener('loadend', (response) => {
            if (200 === xhr.status) {
                this.icon.classList.remove('fa-toggle-on', 'fa-toggle-off');
                this.icon.classList.add(true === xhr.response.status ? 'fa-toggle-on' : 'fa-toggle-off');
            }
        });
        xhr.send();
    }

    get icon() {
        return this.element.querySelector('.fal');
    }
}
