import { Controller } from "@hotwired/stimulus";

let week_from_value = null;
let week_to_value = null;

export default class FormWeekController extends Controller {
    connect() {
        this.initDatepicker();
    }

    disconnect() {
        $(this.placeholder).datepicker('destroy');
    }

    onChangeDate = (e) => {
        const date = e.date;
        week_from_value = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
        week_to_value = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);

        const format = this.element.dataset.format
            .replace(/d/g, 'D')
        ;
        this.from_input.value = window.moment(week_from_value).format(format);
        this.to_input.value = window.moment(week_to_value).format(format);

        $(this.placeholder).datepicker('update', week_from_value);
    }

    onBeforeShowDay = (e) => {
        if (null === week_from_value || null === week_to_value) {
            return;
        }

        if(e >= week_from_value && e <= week_to_value) {
            return 'active border-radius-none';
        }
    }

    initDatepicker() {
        return $(this.placeholder)
            .datepicker({
                beforeShowDay: this.onBeforeShowDay,
                format: this.element.dataset.format,
                language: 'fr'
            })
            .on('changeDate', this.onChangeDate)
        ;
    }

    get from_input() {
        return this.element.querySelector('.week-from');
    }

    get to_input() {
        return this.element.querySelector('.week-to');
    }

    get placeholder() {
        return this.element.querySelector('.datepicker-placeholder');
    }
}
