"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FormPasswordRevealController__input, _FormPasswordRevealController__container, _FormPasswordRevealController__placehoder_show_icon, _FormPasswordRevealController__placehoder_show_title, _FormPasswordRevealController__placehoder_hide_icon, _FormPasswordRevealController__placehoder_hide_title, _FormPasswordRevealController_processVisibility;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
require("./password-reveal_controller.scss");
class FormPasswordRevealController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _FormPasswordRevealController__input.set(this, void 0);
        _FormPasswordRevealController__container.set(this, void 0);
        _FormPasswordRevealController__placehoder_show_icon.set(this, 'fa-eye');
        _FormPasswordRevealController__placehoder_show_title.set(this, 'Afficher');
        _FormPasswordRevealController__placehoder_hide_icon.set(this, 'fa-eye-slash');
        _FormPasswordRevealController__placehoder_hide_title.set(this, 'Masquer');
        _FormPasswordRevealController_processVisibility.set(this, () => {
            let display = false;
            let title = '';
            let icon = '';
            let type = 'password';
            if ('false' === __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").dataset.display) {
                title = __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_hide_title, "f");
                icon = __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_hide_icon, "f");
                type = 'text';
                display = true;
            }
            else {
                title = __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_show_title, "f");
                icon = __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_show_icon, "f");
            }
            __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").dataset.display = String(display);
            __classPrivateFieldGet(this, _FormPasswordRevealController__input, "f").setAttribute('type', type);
            __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").setAttribute('title', title);
            __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").classList.remove(__classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_hide_icon, "f"), __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_show_icon, "f"));
            __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").classList.add(icon);
        });
    }
    initialize() {
        __classPrivateFieldSet(this, _FormPasswordRevealController__input, this.element, "f");
        __classPrivateFieldSet(this, _FormPasswordRevealController__container, document.createElement('span'), "f");
        __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").classList.add('password-reveal', 'fal', __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_show_icon, "f"));
        __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").setAttribute('title', __classPrivateFieldGet(this, _FormPasswordRevealController__placehoder_show_title, "f"));
        __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").dataset.display = "false";
        const parent = this.element.parentElement;
        parent.classList.add('password-group');
        parent.appendChild(__classPrivateFieldGet(this, _FormPasswordRevealController__container, "f"));
    }
    connect() {
        __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").addEventListener('click', __classPrivateFieldGet(this, _FormPasswordRevealController_processVisibility, "f"));
    }
    disconnect() {
        __classPrivateFieldGet(this, _FormPasswordRevealController__container, "f").removeEventListener('click', __classPrivateFieldGet(this, _FormPasswordRevealController_processVisibility, "f"));
    }
}
exports.default = FormPasswordRevealController;
_FormPasswordRevealController__input = new WeakMap(), _FormPasswordRevealController__container = new WeakMap(), _FormPasswordRevealController__placehoder_show_icon = new WeakMap(), _FormPasswordRevealController__placehoder_show_title = new WeakMap(), _FormPasswordRevealController__placehoder_hide_icon = new WeakMap(), _FormPasswordRevealController__placehoder_hide_title = new WeakMap(), _FormPasswordRevealController_processVisibility = new WeakMap();
