import { Controller } from "@hotwired/stimulus";

const CLASSNAME = 'mod-skin-dark';
const COOKIE = 'theme_mod_skin=:value; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax';

export default class LayoutSkinController extends Controller {
    button = this.element;
    icon = this.element.querySelector('.fal');

    initialize() {
        if (document.body.classList.contains(CLASSNAME)) {
            this.setSkinDark();
        } else {
            this.setSkinLight();
        }
    }

    connect() {
        this.button.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.button.removeEventListener('click', this.onClick);
    }

    onClick = (event) => {
        event.preventDefault();

        let header = document.querySelector('.page-header');
        if (null !== header) {
            header.style.backgroundColor = 'inherit';
        }

        let sidebar = document.querySelector('.page-sidebar');
        if (null !== sidebar) {
            sidebar.style.backgroundColor = 'inherit';
        }

        this.toggleSkin();
    }

    setSkinDark() {
        const { titleLight } = this.button.dataset;
        document.cookie = COOKIE.replace(':value', 'dark');
        document.body.classList.add(CLASSNAME);
        this.button.setAttribute('title', titleLight);
        this.button.setAttribute('data-title', titleLight);
        this.button.setAttribute('data-original-title', titleLight);
        this.icon.classList.add('fa-sun');
        this.icon.classList.remove('fa-moon');
    }

    setSkinLight() {
        const { titleDark } = this.button.dataset;
        document.cookie = COOKIE.replace(':value', 'light');
        document.body.classList.remove(CLASSNAME);
        this.button.setAttribute('title', titleDark);
        this.button.setAttribute('data-title', titleDark);
        this.button.setAttribute('data-original-title', titleDark);
        this.icon.classList.add('fa-moon');
        this.icon.classList.remove('fa-sun');
    }

    toggleSkin() {
        if (document.body.classList.contains(CLASSNAME)) {
            this.setSkinLight();
        } else {
            this.setSkinDark();
        }
    }
}
