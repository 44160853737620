
export function setupLabels($container) {
  // Setup labels
  $container.find('.form-floating').each(function () {
    var $input = $(this).find('input:not([type="checkbox"]), select, textarea');
    var $label = $(this).find('label');
    if ($input.val() || $input.attr('placeholder')) {
      $label.addClass('active');
    }
  });
}
