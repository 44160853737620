import { setupLabels } from "./_init/floating_labels";
import { setupFormButtonReset } from "./_init/form_btn_reset";
import { setLinkFetch } from "./_init/link_fetch";
import initTableTree from "./_init/table_tree";
import initXhrContent from './_init/xhr_content';
import initXhrList from './_init/xhr_list';
import initFormEventsModifiers from "#utils/form/modifier";
import initDocumentSelect2, { initSelect2 } from "#utils/form/select2";

window.initFormEventsModifiers = initFormEventsModifiers;
window.initSelect2 = initSelect2;
const $ = require("jquery");

$.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy', /* Leverages same syntax as 'format' */
    weekStart: 1
};

$.fn.hasAttr = function (name) {
    return undefined !== this.attr(name);
};

window.initFormAutocompletion = function initFormAutocompletion(node) {
    $('input[type="text"].autocomplete', node).each(function (i) {
        var $this = $(this);
        if ('disabled' == $this.attr('disabled') || 'readonly' == $this.attr('readonly')) {
            return true;
        }

        $this.autocomplete({
            source: '/json/autocomplete/' + $this.data('autocomplete-scope'),
            minLength: 3,
            change: function (event, ui) {
                var $uid = $(this).data('autocomplete-uid');

                if (null == ui.item) {
                    $('input[data-autocomplete-scope][data-autocomplete-uid="' + $uid + '"]').val('');
                }

            },
            select: function (event, ui) {
                var $uid = $(this).data('autocomplete-uid');

                $.each(ui.item.values, function (key) {
                    $('input[data-autocomplete-scope="' + key + '"][data-autocomplete-uid="' + $uid + '"]').val(ui.item.values[key]);
                });

                $(this).trigger('change');
            }
        });
    });
    // $('input[type="text"].geocomplete', node).geocomplete();
}

$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

window.initFormDropZone = function initFormDropZone(target) {
    const node = target.get(0);
    target.find('[data-trigger="dropzone"]').each(function () {
        var that = $(this);

        that.addClass('rhdropzone');
        that.find(':input').on('change', function (evt) {
            that.find('.name').remove();
            that.append('<div class="name">' + this.value.replace('C:\\fakepath\\', '') + '</div>');
            that.find('img').remove();

            var tgt = evt.target || window.event.srcElement,
                files = tgt.files;
            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function (e) {
                    if (/application\/pdf/.test(e.target.result)) {
                        // The workerSrc property shall be specified.
                        PDFJS.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
                        var pdfData = atob(e.target.result.replace(/data:application\/pdf;base64,/, ''));
                        var loadingTask = PDFJS.getDocument({data: pdfData});

                        loadingTask.promise.then(function (pdf) {
                            // Fetch the first page
                            var pageNumber = 1;
                            pdf.getPage(pageNumber).then(function (page) {
                                var scale = 1.5;
                                var viewport = page.getViewport(scale);

                                // Prepare canvas using PDF page dimensions
                                var canvas = document.getElementById('the-canvas');
                                var context = canvas.getContext('2d');
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;

                                // Render PDF page into canvas context
                                page.render({
                                    canvasContext: context,
                                    viewport: viewport
                                });
                            });
                        }, function (reason) {
                            // PDF loading error
                        });
                    } else {
                        that.css('background-image', 'url(' + e.target.result + ')');
                    }
                    //$('#preview' + $(this).attr('id')).attr('src', e.srcElement.result);
                }
                fr.readAsDataURL(files[0]);
            }
        });
    });

    node.querySelectorAll('.dropzone.autodiscover').forEach((element) => {
        if (element.dropzone) {

        }

        // create new instance
        const uid = $(element).data('dropzone-uid');
        const options = $(element).data('dropzone-options');
        const myDropzone = new Dropzone(element, $.extend(options, {
            params: { uid },
            removedfile: (file) => {
                if ('_error' === file.status) {
                    $(file.previewElement).remove();
                } else {
                    const url =  (file.xhr)
                        ? file.xhr.responseURL
                        : myDropzone.options.url
                    ;

                    $.ajax({
                        method: 'DELETE',
                        url: url,
                        data: { uid, file: file.name },
                        complete: () => $(file.previewElement).remove(),
                    });
                }
            }
        }));
        // add already added files
        if (undefined !== options['addedFiles']) {
            const files = options['addedFiles']
            const count = 0;
            for (let keys = Object.keys(files), idx = 0, len = keys.length; idx < len; idx++) {
                const file = files[keys[idx]];
                myDropzone.displayExistingFile(file, file.src);
            }
            if (undefined !== myDropzone.options.maxFiles && 0 < count) {
                myDropzone.options.maxFiles -= count;
            }
        }
    });

    // delete file
    target.find('[data-toggle="dropzone-file-delete"]').on('click', function (evt) {
        evt.preventDefault();

        var container = $(this).closest('.file-wrapper');
        $.ajax($(this).attr('href'), {
            method: 'POST',
            success: function () {
                if (1 === container.length) {
                    container.remove();
                } else {
                    window.location.reload();
                }
            }
        });
    });
}

window.initFormSelect2icons = function initFormSelect2icons(node) {
    node.find('.select2icons, .select2Icons').each(function () {
        if ($(this).hasClass('initialized')) {
            return true;
        }

        const $that = $(this);
        const $form = $that.closest('form');
        const $buttons = $that.find('button[data-value]');
        const $select = $that.find('select');
        const $buttonAll = $that.find('.btn-select-all');

        if ($form.prop('disabled') || $select.prop('disabled') || $select.attr('readonly') === 'readonly') {
            $buttons.prop('disabled', true);
            $select.find('option[selected]').each(function () {
                $that.find('button[data-value="' + $(this).attr('value') + '"]').removeClass('btn-outline-dark').addClass('btn-primary');
            });
            return true;
        }
        $that.find("option").each(function () {
            if ($(this).prop('disabled') || $(this).attr('readonly') === 'readonly') {
                $that.find('button[data-value="' + $(this).val() + '"]').prop('disabled', true);
            }
        });

        $buttons.on('click', function (evt) {
            evt.preventDefault();

            if (!$select.prop('multiple')) {
                // old select value
                $buttons.removeClass('btn-primary').addClass('btn-default');

                // new value
                $(this)
                    .addClass('btn-primary')
                    .removeClass('btn-default')
                ;
                $select.val($(this).data('value'));
                $select.trigger('change');
            } else {
                var value = $(this).data('value');
                var values = $select.val();
                $(this).toggleClass('btn-primary btn-outline-dark');
                var selected = (values && values.includes(String(value)));
                $select.find('option[value="' + value + '"]').prop('selected', !selected);
                $select.trigger('change');

                var isAllSelected = true;
                $buttons.each(function () {
                    if (!$select.find('option[value="' + $(this).data('value') + '"]').prop('selected')) {
                        isAllSelected = false;
                    }
                });
                $buttonAll.text(!isAllSelected ? 'Sélectionner tous les types d\'encaissements' : 'Désélectionner tous les types d\'encaissements');
            }
        });

        if ($select.prop('multiple')) {
            $buttonAll.on('click', function () {
                var isAllSelected = true;
                $buttons.each(function () {
                    if (!$select.find('option[value="' + $(this).data('value') + '"]').prop('selected')) {
                        isAllSelected = false;
                    }
                });
                $buttons.each(function () {
                    var button = $(this);
                    $select.find('option[value="' + button.data('value') + '"]').prop('selected', !isAllSelected);
                    if (isAllSelected) {
                        button.removeClass('btn-primary').addClass('btn-outline-dark');
                    } else {
                        button.addClass('btn-primary').removeClass('btn-outline-dark');
                    }
                });
                $(this).text(isAllSelected ? 'Sélectionner tous les types d\'encaissements' : 'Désélectionner tous les types d\'encaissements');
            });
        }

        // add initialized wrapper
        $that.addClass('initialized');
        $select.find('option[selected]').each(function () {
            $that.find('button[data-value="' + $(this).attr('value') + '"]').removeClass('btn-outline-dark').addClass('btn-primary');
        });
    });
}

window.initFormErrorsCleanup = function initFormErrorsCleanup(node) {
    $('.form-group.has-error :input', node).on('change keyup', function (evt) {
        var $group = $(this).closest('.form-group');
        $group.removeClass('has-error');
        $('.help-block', $group).remove();
    });
}

window.initFormUi = function initFormUi(node) {
    try {
        initDocumentSelect2(node);
        $(node).find('[data-masonry] .collapse').on('shown.bs.collapse hidden.bs.collapse', (event) => {
            Masonry.data(event.target.closest('[data-masonry]')).layout();
        });
    } catch (e) {}

    let $forms = $(node).find('form')
    if ($(node).is('form')) $forms = $(node);
    $forms.each(function () {
        const $form = $(this);
        let counter = 0;

        $form.on('form_modifier.process_start', () => {
            if (++counter === 1) {
                $form.addClass('processing').append('<div class="loader"></div>');
            }
        });
        $form.on('form_modifier.process_end', () => {
            if (--counter === 0) {
                $form.find('.loader').remove();
                $form.removeClass('processing');
            }
        });
    });

    initFormXhr(node);
    initFormAutocompletion(node);
    initFormEventsModifiers(node);
    initFormErrorsCleanup(node);
    initFormCollections(node);
    initFormSelect2icons(node);
    initFormDropZone(node);
    initEmbededForms(node);
    initDataTables(node);
    initWizard(node);
}

window.initDropdown = function initDropdown(node) {
    node.find("[data-toggle=\"dropdown-with-search\"]").each( function (evt) {
        $(this).attr("data-toggle", "dropdown");

        $(this).dropdown();
        var $input = $("<input type=\"text\" />");

        $input.on("keyup", function (evt) {
            var $query = $(this).val().toUpperCase();
            $(this).closest(".dropdown-menu").find("li[data-value]").each(function () {
                var display = "";

                if ("" !== $(this).data("value") && -1 === $(this).data("search").indexOf($query)) {
                    display = "none";
                }

                $(this).css("display", display);
            });
        });

        $(this).parent().find("ul").prepend($("<li class=\"query\"></li>").append($input));
    });
}

window.initWizard = function initWizard(node) {
    node.find('.sw-toolbarless').each(function () {
        const wizard = $(this);
        wizard.smartWizard({
            theme: 'default',
            justified: true,
            darkMode: document.body.classList.contains('mod-skin-dark'),
            backButtonSupport: false,
            enableURLhash: false,
            useURLhash: false,
            showStepURLhash: false,
            transition: {
                animation: 'slide-horizontal'
            },
            toolbarSettings: {
                showNextButton: false,
                showPreviousButton: false,
            },
            anchorSettings: {
                enableAllAnchors: wizard.hasClass('sw-parallel'),
            }
        });

        wizard.on("leaveStep", (event, context, stepNumber) => {
            const step = wizard.find(`.tab-pane:nth-child(${stepNumber + 1})`);
            step.addClass('step-submitted');
            const inputs = step.find('.form-group > input, .form-group > select, .form-group > textarea');
            let valid = true;
            for (let idx = 0, len = inputs.length; idx < len; idx++) {
                const input = inputs.get(idx);
                if (input.getAttribute('type') === 'hidden') {
                    const clone = input.cloneNode();
                    clone.setAttribute('type', 'text');
                    if (!clone.checkValidity()) {
                        input.classList.add('is-invalid');
                        valid = false;
                    }
                } else if (!input.checkValidity()) {
                    input.classList.add('is-invalid');
                    valid = false;
                }
            }
            return valid;
        });

        const invalid_first = wizard.find('.form-group > input.is-invalid, .form-group > select.is-invalid, .form-group > textarea.is-invalid').get(0)
        if (invalid_first) {
            wizard.smartWizard("goToStep", $(invalid_first).closest('.tab-pane').attr('id').split('-')[1] - 1);
        }
    });
}

window.initFormXhr = function initFormXhr(node, successCallback, errorCallback, completeCallback) {
    node.find("form[data-xhr=\"true\"]").on("submit", function (evt) {
        evt.preventDefault();

        var form = $(this);
        var container = form.closest(form.data("xhr-container"));
        var action = form.attr("action");
        var data = form.serializeObject();
        data.is_fully_submitted  = true;

        // remove previous erros
        node
            .find(".has-error")
            .removeClass("has-error")
            .find(".help-block")
            .remove()
        ;

        // hack required fields
        var isValid = true;
        form.find(":input").each(function () {
            if ("required" === $(this).attr("required") && ("" === $(this).val() || null == $(this).val())) {
                isValid = false;
                $(this).closest(".form-group")
                    .addClass("has-error")
                    .append("<span class=\"invalid-feedback d-block\"><span class=\"d-block\"><span class=\"form-error-icon badge badge-danger text-uppercase\">Erreur</span> <span class=\"form-error-message\">Cette valeur ne doit pas être vide.</span></span></span>")
                ;
            }
        });
        const dropzoneNode = form.find('.dropzone').get(0);
        if (undefined !== dropzoneNode) {
            var dropzone = Dropzone.forElement('#' + $(dropzoneNode).attr('id'));
            if (0 === dropzone.files.length) {
                isValid = false;
                $(dropzoneNode).closest(".dropzone-container")
                    .addClass("has-error")
                    .append("<span class=\"invalid-feedback d-block\"><span class=\"d-block\"><span class=\"form-error-icon badge badge-danger text-uppercase\">Erreur</span> <span class=\"form-error-message\">Cette valeur ne doit pas être vide.</span></span></span>")
                ;
            }
        }
        if (false === isValid) {
            return false;
        }

        // init callbacks
        if (undefined === successCallback || !successCallback instanceof Function) {
            successCallback = function (html) {
                container.html(html);
                initFormUi(container);
                initPopinTrigger(container);
            };
        }
        if (undefined === errorCallback || !errorCallback instanceof Function) {
            errorCallback = function () {
                return;
            };
        }
        if (undefined === completeCallback || !completeCallback instanceof Function) {
            completeCallback = function () {
                form.removeClass("processing");
                $(".loader", form).remove();

                form.trigger("updated");
            };
        }

        $.ajax({
            url : action,
            type: form.attr("method"),
            data : data,
            beforeSend: function () {
                form.addClass("processing").append("<div class=\"loader\"></div>");
            },
            success: successCallback,
            error: errorCallback,
            complete: completeCallback
        });
    });
}

window.initEmbededForms = function initEmbededForms(node) {
    node.find("[data-trigger=\"embedded-form\"] form").each(function () {
        $(this).on("submit", function (evt) {
            var form = $(this);
            var container = form.parent();
            var action = form.attr("action");

            evt.preventDefault();

            var data = form.serializeObject();
            data.is_fully_submitted = true;

            $.ajax({
                url : action,
                type: form.attr("method"),
                data : data,
                beforeSend: function () {
                    form.addClass("processing").append("<div class=\"loader\"></div>");
                },
                success: function (data) {
                    var json = $.parseJSON(data);
                    container.html(json.html);
                    initFormUi(container);
                },
                complete: function () {
                    form.removeClass("processing");
                    $(".loader", form).remove();
                    form.trigger("updated");
                }
            });
        });
    });
}

window.initFormCollections = function initFormCollections(node) {
    // POUR CHAQUE COLLECTION, DELETE BUTTON
    $('.form-collection', node).each(function () {
        if (0 === $(this).closest('form').length) {
            return true;
        }

        const $collection = $(this);
        const addButtonDelete = ($item) => {
            let deleteLabel = $collection.data('delete-label') || 'Supprimer';
            if (deleteLabel === 'icon') deleteLabel = '<span class="fal fa-trash-alt"></span>';
            else deleteLabel = `<span class="fal fa-trash-alt fa-fw mr-1"></span>${deleteLabel}`;

            const $deleteCollectionLink = $('<button type="button" class="btn btn-danger btn-remove btn-sm">' + deleteLabel + '</button>');
            $item.find('.delete').append($deleteCollectionLink);
            $item.css('border', '2px dashed transparent')
            $deleteCollectionLink.on('mouseover', function () {
                $item.css('border', '2px dashed rgba(201, 26, 35, 0.2)');
            }).on('mouseleave', function () {
                $item.css('border', '2px dashed transparent');
            });

            $deleteCollectionLink.on('click', (event) => {
                event.preventDefault();
                const header = '<div class="modal-header"><h5 class="modal-title">Merci de confirmer</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>';
                const footer = '<div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal"><span class="fal fa-undo fa-fw mr-1"></span>Annuler</button><button type="button" class="btn btn-danger btn-confirm"><span class="fal fa-trash-alt fa-fw mr-1"></span>Supprimer</button></div>';
                const $modal = $(`<div class="modal fade"><div class="modal-dialog modal-dialog-centered"><div class="modal-content">${header}<div class="modal-body"></div>${footer}</div></div></div>`);
                $(document.body).append($modal);
                $modal.find('.modal-body').html($collection.data('delete-message') || 'Supprimer ?');
                $modal.find('.btn-confirm').on('click', (event) => {
                    event.preventDefault();
                    $item.remove();
                    $modal.modal('hide');
                });
                $modal.on('hidden.bs.modal', () => {
                    $modal.remove();
                });
                $modal.modal('show');
            });
        };

        if (1 == $collection.attr('data-allow-add')) {
            const labelAdd = $collection.attr('data-add-label');
            let $addCollectionLink;
            if (labelAdd) {
                $addCollectionLink = $('<button type="button" class="btn btn-primary btn-sm btn-add"><span class="fal fa-plus mr-1"></span>' + labelAdd + '</button>');
                $collection.append($addCollectionLink);
            } else if ($collection.find('.form-collection-add').length) {
                $addCollectionLink = $collection.find('.form-collection-add .btn-add');
            }

            $addCollectionLink.on('click', function (event) {
                event.preventDefault();
                const uuid = Math.random().toString(36).substr(2, 9);
                const $prototype = $($collection.data('prototype').replace(/__name__/g, uuid));
                if (labelAdd) $addCollectionLink.before($prototype);
                else $addCollectionLink.closest('.form-collection-add').before($prototype);

                addButtonDelete($prototype);
                initFormUi($prototype);
            });
        }

        if (1 == $collection.attr('data-allow-delete')) {
            var $items = $('.form-collection-item', $collection);
            if ($collection.hasClass('form-collection-deletor')) {
                $items.each(function () {
                    addButtonDelete($(this));
                });
            }
        }
    });
}

window.initNavigation = function initNavigation() {
    var $urlpath = window.location.pathname;

    $('.navigation > ul > li ul').each(function () {
        var $ul = $(this);
        var $li = $(this).parent();

        $('a', $ul).each(function () {
            if ($urlpath == $(this).attr('href')) {
                $(this).parent().addClass('active');
                $li.addClass('active open');
                $ul.show();

                return false;
            }
        });
    });

    // if ('true' == sessionStorage.getItem('minify_navigation')) {
    //     $('body').addClass('minified');
    // }

    $('span.minifyme').bind('click', function () {
        sessionStorage.setItem('minify_navigation', !$('body').hasClass('minified'));
    });
}

window.initDatepickerDefaults = function initDatepickerDefaults() {
    $.fn.datepicker.defaults.language = 'fr';
    $.fn.datepicker.dates['fr'] = {
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        today: 'Aujourd\'hui',
        clear: 'Effacer',
        format: 'dd/mm/yyyy',
        titleFormat: "MM yyyy",
        weekStart: 1
    };
}


window.initPermissionsTreeUi = function initPermissionsTreeUi() {
    var setChildsChecked = function (root, value) {
        // set childs
        $(':checkbox', root).each(function () {
            setChecked($(this), value);
        });

    };

    var setChecked = function (node, value) {
        node.prop('checked', value);
    };

    $('#permissions .tree').each(function () {
        const $tree = $(this);

        $('.site-item', $tree).each(function () {
            var $siteContainer = $(this);
            var $siteCheckbox = $('.site', $siteContainer);
            $siteCheckbox.on('change', function () {
                setChildsChecked($siteContainer, $(this).is(':checked'));
            });

            // units
            $('.unit-item', $siteContainer).each(function () {
                var $unitContainer = $(this);
                var $unitCheckbox = $('.unit', $unitContainer);
                $unitCheckbox.on('change', function () {
                    var $val = $(this).is(':checked');

                    // parent
                    if (true == $val) {
                        setChecked($siteCheckbox, $val);
                    }

                    // childs
                    setChildsChecked($unitContainer, $val);
                });

                // services
                $('.service-item', $unitContainer).each(function () {
                    var $serviceContainer = $(this);
                    var $serviceCheckbox = $('.service', $serviceContainer);
                    $serviceCheckbox.on('change', function () {
                        var $val = $(this).is(':checked');

                        // parents
                        if (true == $val) {
                            setChecked($unitCheckbox, $val);
                            setChecked($siteCheckbox, $val);
                        }
                    });
                });
            });
        });
    });

    $('#archive_categories_permissions .tree' ).each(function () {
        const $tree = $(this);
        $('.files-type-item', $tree).each(function () {
            var $siteContainer = $('.files' ,this);
            var $siteCheckbox = $('.file', $siteContainer);
            $siteCheckbox.on('change', function () {
                setChildsChecked($(this).closest('.parent_li'), $(this).is(':checked'));
            });
        });
    });
}

window.initModal = function initModal(node, showCallback) {
    node.find('[data-trigger="modal"]').on("click", function (evt) {
        evt.preventDefault();

        const title = $(this).attr('title') || $(this).data('title');

        window.fetch($(this).attr('href'), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-Form-Rendered': 'modal',
            },
        }).then((response) => {
            if (response.ok) return response.text();
            throw new Error(response.statusText);
        }).then((html) => {
            const $modal = $('<div class="modal fade"><div class="modal-dialog modal-dialog-centered"><div class="modal-content"><div class="modal-body"></div></div></div></div>');
            $(document.body).append($modal);
            if (title) {
                $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">${title}</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
            }
            $modal.find('.modal-body').html(html);

            if ('function' == typeof showCallback) {
                $modal.on("shown.bs.modal", showCallback);
            }

            $modal.on('hidden.bs.modal', () => {
                $modal.remove();
            });

            $modal.modal('show');
        });
    });
}

window.initModalForms = function initModalForms(node) {
    node.find('[data-trigger="modal-form"]').on("click", function (evt) {
        evt.preventDefault();

        window.showModalForm(this);
    });
}

window.showModalForm = function showModalForm(node, showCallback)
{
    const $this = $(node);
    const title = $this.attr('title') || $this.data('title');

    window.fetch($this.attr('href'), {
        method: 'GET',
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Form-Rendered': 'modal',
        },
    }).then((response) => {
        if (response.redirected) {
            document.location = response.url;
        } else if (!response.ok) {
            throw new Error(response.statusText);
        } else {
            return response.text().then((html) => {
                const $modal = $('<div class="modal fade"><div class="modal-dialog modal-dialog-centered"><div class="modal-content"><div class="modal-body"></div></div></div></div>');
                $(document.body).append($modal);
                if (title) {
                    $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">${title}</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
                }
                $modal.find('.modal-body').html(html);

                if ('function' == typeof showCallback) {
                    $modal.on("shown.bs.modal", showCallback);
                } else {
                    $modal.on("shown.bs.modal", (evt) => {
                        const $modal = $(evt.target).is('.modal') ? $(evt.target) : $(evt.target).closest('.modal');
                        (function initForm() {
                            const $form = $modal.find("form");
                            initFormUi($form);
                            if (!$form.hasClass('form-no-submit-ajax')) {
                                $form.on("submit", function (evt) {
                                    evt.preventDefault();
                                    $form.find('button[type="submit"]').prop('disabled', true);

                                    let xhr = new XMLHttpRequest();
                                    xhr.open('POST', $form.attr('action'));
                                    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                                    xhr.responseType = "arraybuffer";
                                    xhr.addEventListener('readystatechange', () => {
                                        if ([301, 302].includes(xhr.status)) {
                                            document.location = xhr.responseURL;
                                        } else if (xhr.readyState == 4 && xhr.status == 200) {
                                            $form.find('button[type="submit"]').prop('disabled', false);
                                            const response_type = xhr.getResponseHeader('Content-Type');
                                            if (response_type === 'application/pdf') {
                                                let filename = "";
                                                const disposition = xhr.getResponseHeader('Content-Disposition');
                                                if (disposition && disposition.indexOf('attachment') !== -1) {
                                                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                                    const matches = filenameRegex.exec(disposition);
                                                    if (matches != null && matches[1]) {
                                                        filename = matches[1].replace(/['"]/g, '');
                                                    }
                                                }

                                                const blob = new Blob([xhr.response], { type: "application/pdf" });
                                                const download_link = document.createElement('a');
                                                download_link.href = URL.createObjectURL(blob);
                                                download_link.download = filename || 'document.pdf';
                                                download_link.setAttribute('target', "_blank");
                                                download_link.style.position = 'absolute';
                                                download_link.style.height = '1px';
                                                download_link.style.width = '1px';
                                                download_link.style.zIndex = -40;
                                                document.body.appendChild(download_link);
                                                download_link.click();
                                                document.body.removeChild(download_link);
                                                if ('rhsuite_employee_document' == $form.attr('name') ) {
                                                    $modal.modal('hide');
                                                }
                                            } else {
                                                const text = (new TextDecoder()).decode(new Uint8Array(xhr.response));

                                                if (/^\//.test(text)) {
                                                    window.open(text, '_blank').focus();
                                                } else if ('reload' === text) {
                                                    $modal.modal('hide');
                                                    window.location.reload();
                                                } else {
                                                    $modal.find('.modal-body').html(text);
                                                    initForm();
                                                }
                                            }
                                        } else if (4 === xhr.readyState && 400 === xhr.status) {
                                            $form.find('button[type="submit"]').prop('disabled', false);

                                            const text = (new TextDecoder()).decode(new Uint8Array(xhr.response));
                                            $modal.find('.modal-body').html(text);
                                            initForm();
                                        }
                                    });
                                    xhr.send(new FormData($form.get(0)));
                                });
                            }
                        })();
                    });
                }

                $modal.on('hidden.bs.modal', () => {
                    $modal.remove();
                });

                $modal.modal('show');
            });
        }
    });
}

const CONFIRM_MODAL_TEMPLATE = `
<div id="modal-confirm-auto" class="modal fade modal-confirm" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
      </div>
      <div class="modal-footer">
        <button class="btn btn-default" data-dismiss="modal" aria-hidden="true"><span class="fal fa-undo mr-1"></span>NON</button>
        <a id="dataConfirmOK" class="btn btn-success"><span class="fal fa-check mr-1"></span>OUI</a>
      </div>
    </div>
  </div>
</div>
`;

window.initConfirmDialog = function initConfirmDialog(node) {
    // Confirm dialog
    $(node).find('a[data-confirm]').click(function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const href = $(this).attr('href');
        if (!document.getElementById('modal-confirm-auto')) {
            $('body').append(CONFIRM_MODAL_TEMPLATE);
        }
        const $confirm = $('#modal-confirm-auto');

        const $message = $(this).data('message');
        $confirm.find('.modal-body').html($(this).data('confirm') + ((undefined !== $message) ? '<br>' +  $message : ''));
        $confirm.find('.modal-body').prepend('<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
        $('#dataConfirmOK').attr('href', href);
        $confirm.modal({ show: true });
    });
}

$(document).delegate('#dataConfirmOK', 'click', function() {
    let $modal = $('#modal-confirm-auto');
    $('#modal-confirm-auto').find('.modal-body').html('<div class="text-center py-5">' +
        '<span class="fal fa-sync fa-spin fa-5x"></span><br />' +
        '<p class="mt-3">Chargement en cours</p>' +
        '</div>');
    $modal.find('.modal-footer').hide();
});

const POPIN_MODAL_TEMPLATE = `
<div id="dataPopinModal" class="modal fade modal-popin" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      </div>
    </div>
  </div>
</div>
`;

window.initPopinTrigger = function initPopinTrigger(node) {
    $(node).find('[data-trigger="popin"]').off().on('click', function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        try {
            const target = $($(this).data('popin-target'));
            const $confirm = $('#dataPopinModal');
            if (!$confirm.length) {
                $('body').append(POPIN_MODAL_TEMPLATE);
            }

            $confirm.find('.modal-body').html($(target).html());
            $confirm.modal({ show:true });
        } catch (e) {
        }
    });

    const onClickToggleModal = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        try {
            const id = $(this).data('toggle-modal');
            const preview = document.querySelector(id);
            const content = $(".preview-content", preview).clone();
            const source = content.find(":first-child");
            source.attr("src", source.data("src"));

            const $modal = $('<div class="modal fade"><div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"><div class="modal-content"><div class="modal-body"></div><div class="modal-footer"></div></div></div></div>');
            $(document.body).append($modal);
            const title = source.data("title");
            if (title) {
                $modal.find('.modal-body').before(`<div class="modal-header"><h5 class="modal-title">${title}</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`);
            } else {
                $modal.find('.modal-body').append(`<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>`);
            }
            $modal.find('.modal-body').append(content);
            $modal.on('hidden.bs.modal', () => {
                $modal.remove();
            });
            $modal.modal('show');
        } catch (error) {
            // console.error(error);
        }
    };

    $(node).off("click", "a[data-toggle-modal]", onClickToggleModal);
    $(node).on("click", "a[data-toggle-modal]", onClickToggleModal);
}

window.initSelectAll = function initSelectAll(container) {
    $('.select_all', container).click(function () {
        if (this.checked) {
            // Iterate each checkbox
            $(this).closest('table').find(':checkbox').each(function () {
                this.checked = true;
            });
        } else {
            $(this).closest('table').find(':checkbox').each(function () {
                this.checked = false;
            });
        }
    });
}

window.initTreeView = function initTreeView(container) {
    $(container).find('[data-role="tree"]').each(function() {
        const $tree = $(this);

        $tree
            .on('loaded.jstree', function() {
                $tree.jstree('open_all');

                // move anchor internal links
                $tree.find('.jstree-anchor').each(function() {
                    const $anchor = $(this);

                    $anchor.find('a').each(function() {
                        $anchor.after($(this));
                    });
                });

                // custom dnd
                $tree.find('ul > .jstree-sortable:first-child').each(function() {
                    const container = $(this).closest('ul');

                    container
                        .sortable({
                            items: container.find('li'),
                            placeholder: 'sortable-placeholder',
                            forcePlaceholderSize: true,
                            stop: function(event) {
                                $(event.target).find('input').each(function(idx) {
                                    $(this).val(idx + 1);
                                });
                            }
                        })
                    ;
                });
            })
            .on('before_open.jstree', function (event, { node }) {
                // checkboxes
                const $node = $(`#${node.id}`);
                $node.find(':checkbox').each(function () {
                    if (!$(this).parent().find('fal').length) {
                        $(this).after(`<i class="fal fa-${$(this).is(':checked') ? 'check-' : ''}square"></i>`);
                    }

                    const check_id = $(this).closest('[role="treeitem"]').attr('id');
                    const element_id = `checkbox_${check_id}`;
                    const $form = $(this).closest('form');
                    if ($form.find(`#${element_id}`).length) {
                        $(this).remove();
                    } else {
                        $(this).attr('id', element_id);
                        $(this).addClass('hidden');
                        $(this).prependTo($form);
                    }
                });
            })
            .on('activate_node.jstree', function(event, { node }) {
                const $checkbox = $(`#checkbox_${node.id}`);
                // not check is check because activate is before check event
                const checked = !$checkbox.is(':checked');

                $checkbox.prop('checked', checked);

                if (true === checked) {
                    $(`\#${node.id}_anchor`).find('i')
                        .removeClass('fa-square')
                        .addClass('fa-check-square')
                    ;

                    node.parents.forEach(function(parent_id) {
                        if ('#' !== parent_id) {
                            $(`#checkbox_${parent_id}`).prop('checked', true);
                            $(`\#${parent_id}_anchor`).find('i')
                                .removeClass('fa-square')
                                .addClass('fa-check-square')
                            ;
                        }
                    });

                    node.children_d.forEach(function(child_id) {
                        $(`#checkbox_${child_id}`).prop('checked', true);
                        $(`\#${child_id}_anchor`).find('i')
                            .removeClass('fa-square')
                            .addClass('fa-check-square')
                        ;
                    });
                } else {
                    $(`\#${node.id}_anchor`).find('i')
                        .addClass('fa-square')
                        .removeClass('fa-check-square')
                    ;

                    node.children_d.forEach(function(child_id) {
                        $(`#checkbox_${child_id}`).prop('checked', false);
                        $(`\#${child_id}_anchor`).find('i')
                            .addClass('fa-square')
                            .removeClass('fa-check-square')
                        ;
                    });
                }
            })
        ;

        $tree
            .jstree({
                check_callback : false,
                core: {
                    themes: {
                        icons: false,
                        name: "proton",
                        responsive: true
                    },
                    worker: false
                },
                plugins: []
            })
        ;
    });
}

window.initImageZoom = function initImageZoom(node) {
    $(node).find('[data-toggle="inner-zoom"]').elevateZoom({
        zoomType: 'inner',
        cursor: 'crosshair'
    });

    $(node).find('[data-toggle="lens-zoom"]').elevateZoom({
        zoomType: 'lens',
        lensShape: 'round',
        lensSize: 180
    });
}

window.initFilters = function initFilters() {
    var container = $('#content');

    $('#toggle-filters a[data-action="toggleFilters"]').each(function () {
        $(this).on('click', function () {
            if ($(this).hasClass('collapsed')) {
                container.find('.filters-placeholder').remove();
            } else {
                var filters = $('#header-filters .filters-placeholder').clone(true, true);
                filters.removeClass('hidden-xs');
                container.prepend(filters);
            }

            $(this).toggleClass('collapsed');
        });

        // add default counters
        var count = 0;
        $('#header .dropdown-toggle > span').each(function () {
            if ('Tous' != $(this).text().trim()) {
                count += 1;
            }
        });
        $(this).find('i').append('<em>' + count + '</em>');

        // add listeners on filtered
        $(window).on('filter::selected', function (evt, filter, value) {
            var count = parseInt($('#toggle-filters i em').text());

            if ('' != value || null != value) {
                count += 1;
            } else {
                count -= 1;
            }

            $('#toggle-filters i em').text(count);
        });
    });
}

// TOGGLE SHORTCUT
window.toggleAlerts = function toggleAlerts(){
    const alert_dropdown = $('#alerts-shortcut');

    // SHORTCUT ANIMATE HIDE
    function shortcut_buttons_hide() {
        alert_dropdown.animate({ height : "hide" }, 300, "easeOutCirc");
        $.root_.removeClass('shortcut-on');

    }

    // SHORTCUT ANIMATE SHOW
    function shortcut_buttons_show() {
        alert_dropdown.animate({ height : "show" }, 200, "easeOutCirc");
        $.root_.addClass('shortcut-on');
    }

    if (alert_dropdown.is(":visible")) {
        shortcut_buttons_hide();
    } else {
        shortcut_buttons_show();
    }

    // SHORT CUT (buttons that appear when clicked on user name)
    alert_dropdown.find('a').click(function (e) {
        e.preventDefault();
        window.location = $(this).attr('href');
        setTimeout(shortcut_buttons_hide, 300);
    });

    // SHORTCUT buttons goes away if mouse is clicked outside of the area
    $(document).mouseup(function (e) {
        if (!alert_dropdown.is(e.target) && alert_dropdown.has(e.target).length === 0) {
            shortcut_buttons_hide();
        }
    });
};

// TOGGLE PLANNING ABSENCES
window.toggleAbsences = function toggleAbsences(){
    var absences_dropdown = $('#absences-shortcut');

    if (absences_dropdown.is(":visible")) {
        shortcut_buttons_hide();
    } else {
        shortcut_buttons_show();
    }

    // SHORT CUT (buttons that appear when clicked on user name)
    absences_dropdown.find('a').click(function (e) {
        e.preventDefault();
        window.location = $(this).attr('href');
        setTimeout(shortcut_buttons_hide, 300);
    });

    // SHORTCUT buttons goes away if mouse is clicked outside of the area
    $(document).mouseup(function (e) {
        if (!absences_dropdown.is(e.target) && absences_dropdown.has(e.target).length === 0) {
            shortcut_buttons_hide();
        }
    });

    // SHORTCUT ANIMATE HIDE
    function shortcut_buttons_hide() {
        absences_dropdown.animate({
            height : "hide"
        }, 300, "easeOutCirc");
        $.root_.removeClass('shortcut-on');

    }

    // SHORTCUT ANIMATE SHOW
    function shortcut_buttons_show() {
        absences_dropdown.animate({
            height : "show"
        }, 200, "easeOutCirc");
        $.root_.addClass('shortcut-on');
    }
}

window.initScrollButton = function initScrollButton() {
    var scrollButton = $(".scroll-top");
    $(window).scroll(function (e) {
        if (window.pageYOffset > 0) {
            scrollButton.css('display','block');
        } else {
            scrollButton.css('display', 'none');
        }
    });
}

window.scrollTop = function scrollTop() {
    $(document.body).animate({ scrollTop: 0 }, 500, 'swing');
}

window.createDataTable = function createDataTable(table) {
    $.fn.DataTable.ext.pager.numbers_length = 5;

    let headerOffset = $('header.page-header').height();
    const $subheader = $('.page-wrapper .page-subheader');
    if ($subheader.length) headerOffset += $subheader.height();

    const $table = $(table).css({ width: '100%' });
    const columns = [].slice.call(table.querySelectorAll('thead tr:last-child th, thead tr:last-child td'));

    const is_filtered = $table.hasClass('datatable--filtered');

    let datatable;
    if ($.fn.DataTable.isDataTable($table)) {
        datatable = $table.DataTable();
    } else {
        let sortIdx = columns.findIndex((col) => col.classList.contains('sort-asc'));
        let sortDir = 'asc';

        if (sortIdx < 0) {
            sortIdx = columns.findIndex((col) => col.classList.contains('sort-desc'));
            sortDir = 'desc';

            if (sortIdx < 0) {
                sortIdx = columns.findIndex((col) => !col.classList.contains('no-sort'));
                sortDir = 'asc';
            }
        }

        datatable = $table.DataTable({
            responsive: true,
            fixedHeader: { headerOffset },
            info: $table.hasClass('datatable--detail'),
            paging: $table.hasClass('datatable--paginate'),
            pagingType: 'simple_numbers',
            pageLength: $table.data('page-length') || 10,
            ordering: $table.hasClass('datatable--reorder'),
            order: [[sortIdx, sortDir]],
            searching: is_filtered || $table.hasClass('datatable--searchable'),
            language: { url: '/translations/datatable.french.json' },
            autoWidth: true,
            columnDefs: [].concat(
                { targets: 'no-sort', orderable: false },
                { targets: 'no-search', searchable: false },
                columns.map((node, idx) => ({ node, idx })).sort((first, second) => {
                    const firstHasPriority = first.node.dataset.priority && Number(first.node.dataset.priority);
                    const secondHasPriority = second.node.dataset.priority && Number(second.node.dataset.priority);
                    if (firstHasPriority && !secondHasPriority) return -1;
                    if (!firstHasPriority && secondHasPriority) return 1;
                    if (firstHasPriority && secondHasPriority) return firstHasPriority - secondHasPriority;

                    const firstHasPhone = (first.node.dataset.hide || '').includes('phone');
                    const secondHasPhone = (second.node.dataset.hide || '').includes('phone');
                    if (firstHasPhone && !secondHasPhone) return 1;
                    if (!firstHasPhone && secondHasPhone) return -1;

                    const firstHasTablet = (first.node.dataset.hide || '').includes('tablet');
                    const secondHasTablet = (second.node.dataset.hide || '').includes('tablet');
                    if (firstHasTablet && !secondHasTablet) return 1;
                    if (!firstHasTablet && secondHasTablet) return -1;

                    return first.idx - second.idx;
                }).map(({ node, idx }, priority) => ({
                    responsivePriority: priority,
                    targets: idx,
                })),
            ),
            initComplete: function () {
                if (!is_filtered) {
                    return;
                }

                const $head = $table.find('thead');
                $head
                    .find('tr')
                    .clone(true)
                    .addClass('filters')
                    .appendTo($head)
                ;

                const api = this.api();
                api.columns().eq(0).each(function (idx) {
                    const column = api.column(idx);
                    const data_filter = $(column.header()).data('filter');
                    if (data_filter === undefined) {
                        return;
                    }

                    const $cell = $('.filters th').eq(
                        $(api.column(idx).header()).index()
                    );
                    switch (data_filter) {
                        case 'select': {
                            const $select = $(`<select><option value="">--</option></select>`);
                            $cell.empty().append($select);
                            $select.select2({ allowClear: true, placeholder: '--', width: '100%' });
                            column.data().unique().sort().each((data) => $select.append(`<option value="${data}">${data}</option>`));
                            break;
                        }
                        default: {
                            $cell.html('<input type="text" class="form-control" />');
                            break;
                        }
                    }

                    let timeout;
                    const debounceSearch = (input) => {
                        if (timeout) {
                            clearTimeout(timeout);
                        }

                        timeout = setTimeout(() => {
                            const value = input.value ? $.fn.DataTable.util.escapeRegex(input.value) : '';
                            const cursorPosition = input.selectionStart;
                            api.column(idx).search(value).draw();
                            if ('input' === input.tagName.toLowerCase()) {
                                $(input).focus()[0].setSelectionRange(cursorPosition, cursorPosition);
                            }
                        }, 333);
                    };

                    const onChange = function (event) {
                        event.stopPropagation();
                        debounceSearch(this);
                    };

                    $cell.find('input, select')
                        .off('keyup change', onChange)
                        .on('keyup change', onChange)
                    ;
                });
            },
        });
        window.addEventListener('resize', () => {
            datatable.columns.adjust().responsive.recalc();
        });
    }

    return datatable;
};

window.initDataTables = function initDataTables(node) {
    $(node).find('.datatable').each(function () {
        createDataTable(this);
    });
}

window.initPageActions = () => {
    const $subheader = $('.page-wrapper .page-subheader');
    if ($subheader.length) {
        const $containerOnScroll = $('<div class="page-subheader-scrolling d-none"></div>').html($subheader.html());
        if (document.location.toString().match(/https?:\/\/control\./)) {
            $containerOnScroll.css('padding', '.75rem 2rem .75rem 2.75rem');
        }
        $(document.body).append($containerOnScroll);
        $(document).scroll(() => {
            if (window.scrollY + 66 <= $subheader.offset().top + $subheader.height()) {
                $containerOnScroll.addClass('d-none');
            } else {
                $containerOnScroll.removeClass('d-none');
            }
        });
    }
}

window.initSetCookieAction = () => {
    const COOKIE = ':name=:value; path=/; expires=:expires; SameSite=Lax';
    document.querySelectorAll('[data-set-cookie-name]').forEach((node) => {
        const name = node.dataset.setCookieName;
        const value = node.dataset.setCookieValue;

        node.addEventListener('click', () => {
            if (value) {
                document.cookie = COOKIE
                    .replace(':name', name)
                    .replace(':value', value)
                    .replace(':expires', 'Fri, 31 Dec 9999 23:59:59 GMT')
                ;
            } else {
                document.cookie = COOKIE
                    .replace(':name', name)
                    .replace(':value', '')
                    .replace(':expires', (new Date()).toUTCString())
                ;
            }
        });
    });
}

window.initLinkFetch = function initLinkFetch(node) {
    node.querySelectorAll('a[data-method="fetch"]').forEach((node) => {
        setLinkFetch(node);
    });
    node.querySelectorAll('a[data-method="delete"]').forEach((node) => {
        setLinkFetch(node, () => {
            if (node.closest('table')) {
                node.closest('tr').remove();
            } else if (node.closest('ul')) {
                node.closest('li').remove();
            }
        });
    });
};

window.initNavigationPreviousPage = () => {
    document.querySelectorAll('.btn-previous-page').forEach((node) => {
        node.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            window.history.back();
        });
    });
};

window.initFormAjaxSubmit = (node, callback) => {
    $(node).on('submit', 'form.form-submit-ajax[name]', (event) => {
        const { submitter } = event.originalEvent;
            if (submitter && submitter.getAttribute('formtarget') === '_blank') {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        submitFormAjax(event.target, callback, submitter).catch((error) => {
            console.error(error);
        });
    });

    $(node).on('click', 'form button.form-submit-ajax', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const submitter = event.target;
        const form = submitter.closest('form');
        submitFormAjax(form, callback, submitter).catch((error) => {
            console.error(error);
        });
    });

    $(node).on('change', '.form-change-ajax[name] :input[name]', (evt) => {
        evt.stopPropagation();
        const form = evt.target.matches('form') ? evt.target : evt.target.closest('form');
        submitFormAjax(form, callback).catch((error) => {
            console.error(error);
        });
    });
}

const RESPONSE_FILE_MIME = [
    'text/csv',
    'application/octet-stream',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

window.submitFormAjax = function submitFormAjax(form, callback, submitter) {
    const submits = [].slice.call(form.querySelectorAll('button[type="submit"], button[type="reset"]'));
    const classes = [];

    for (let idx = 0, len = submits.length; idx < len; idx++) {
        const icon = submits[idx].querySelector('.fal, .fas, .far, .fat, .fad');
        const loader = document.createElement('span');

        classes.push(icon.getAttribute('class'));
        loader.classList.add('spinner-grow');
        loader.classList.add('spinner-grow-sm');
        submits[idx].removeChild(icon);
        submits[idx].prepend(loader);
        submits[idx].classList.add('disabled');
        submits[idx].setAttribute('disabled', 'disabled');
    }

    // keep submitted button for symfony isClicked process
    let data = new FormData(form);
    if (submitter?.name) {
        data.append(submitter.name, submitter.value || '');
    }

    return fetch(submitter?.getAttribute('formaction') || form.getAttribute('action') || document.location.toString(), {
        method: submitter?.getAttribute('formmethod') || form.getAttribute('method'),
        body: data,
        headers: {
            'X-Form-Submitter': 'jQuery',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Requested-Scope': 'save',
        },
    }).then((response) => {
        if (response.redirected || [301, 302].includes(response.status)) {
            document.location = response.url;
            return;
        }

        let isDownload = false;
        return new Promise((resolve, reject) => {
            if ('function' === typeof callback) {
                return response.text().then((text) => callback(form, text)).then(() => resolve([text]), reject);
            }

            if (response.ok) {
                const contentType = response.headers.get('Content-Type');
                const mimeType = contentType.split(';')[0].trim();
                if (RESPONSE_FILE_MIME.includes(mimeType)) {
                    isDownload = true;
                    return response.blob().then((blob) => {
                        const disposition = response.headers.get('Content-Disposition');
                        let filename = "";

                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }

                        if (!filename) {
                            filename = 'document';
                            switch (mimeType) {
                                case 'text/csv':
                                    filename += '.csv';
                                    break;
                                case 'application/pdf':
                                    filename += '.pdf';
                                    break;
                                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                    filename += '.xlsx';
                                    break;
                                case 'application/vnd.ms-excel':
                                    filename += '.xls';
                                    break;
                                case 'application/octet-stream':
                                default:
                                    filename += '.xls';
                                    break;
                            }
                        }

                        const download_link = document.createElement('a');
                        download_link.href = URL.createObjectURL(blob);
                        download_link.download = filename;
                        download_link.setAttribute('target', "_blank");
                        download_link.style.position = 'absolute';
                        download_link.style.height = '1px';
                        download_link.style.width = '1px';
                        download_link.style.zIndex = -40;
                        document.body.appendChild(download_link);
                        download_link.click();
                        document.body.removeChild(download_link);
                        resolve([blob]);
                    }).catch(reject);
                }
            }

            return response.text().then((text) => {
                const selector = `form[name="${form.getAttribute('name')}"]`;
                let $form = $(text);
                if (!$form.is(selector)) {
                    $form = $form.find(selector);
                }

                if ($form.length) {
                    form.innerHTML = $form.html();
                    initFormUi($(form));
                    resolve([text, true]);
                } else {
                    resolve([text]);
                }
            });
        }).then(([data, withoutFormUpdate]) => {
            if (withoutFormUpdate) return data;
            for (let idx = 0; idx < submits.length; idx++) {
                const icon = document.createElement('i');

                icon.classList.add(...classes[idx].split(' '));
                submits[idx].removeChild(submits[idx].querySelector('.spinner-grow'));
                submits[idx].prepend(icon);
                submits[idx].classList.remove('disabled');
                submits[idx].removeAttribute('disabled');
            }

            return data;
        }).finally(() => {
            const statusText = response.headers.get('x-status-text');
            form.dispatchEvent(new Event('sirh::form-ajax--submitted'));
            document.dispatchEvent(new Event('sirh::form-ajax--submitted'));

            if (response.ok) {
                toastr.success(statusText ?? (isDownload ? 'Fichier récupéré.' : 'Mise à jour effectuée.'));
            } else {
                toastr.error(statusText ?? (isDownload ? 'Erreur durant la récupération du fichier.' : 'Erreur durant la mise à jour, veuillez vérifier les données du formulaire.'));
            }
        });
    });
}

$(document).on('change', '.rhsuite-switch', function () {
    var $input = $(this).parent().parent().find('input[type="hidden"]');
    if ($(this).is(':checked')) {
        $input.val('1');
    } else {
        $input.val('0');
    }
});

window.SIRH_EVENT_DEFER_STIMULUS_CONTROLLERS = 'sirh_event.defer_stimulus_controllers';
window.deferStimulusControllers = function() {
    document.querySelectorAll('[data-controller-defer]').forEach(function (node) {
        node.setAttribute('data-controller', node.getAttribute('data-controller-defer'));
        node.removeAttribute('data-controller-defer');
    });
}
document.addEventListener(window.SIRH_EVENT_DEFER_STIMULUS_CONTROLLERS, window.deferStimulusControllers);

Dropzone.autoDiscover = false;
$(function () {
    $.root_ = $(document.body);

    // if ($(window).width() < 979) {
    //     $("html").addClass("hidden-menu-mobile-lock");
    // }

    // cleanup for style
    $('.select2').each(function () {
        var $this = $(this);
        if ('readonly' === $this.attr('readonly')) {
            $this.removeClass('select2');
        } else {
            $this.removeClass('form-control custom-select');
        }
    });

    initTableTree(document);
    initXhrContent(document);
    initXhrList(document);

    // navigation
    initNavigation();
    initPageActions();
    initSetCookieAction();
    initNavigationPreviousPage();

    // filters
    initFilters();

    // forms
    initDatepickerDefaults();
    // initDatepickerPeriod();
    initFormUi($(document));
    initFormAjaxSubmit(document);

    // tree for ACL
    initPermissionsTreeUi();

    // data confirm Init
    initModal($(document));
    initModalForms($(document));
    initConfirmDialog($(document));
    initPopinTrigger(document);
    initLinkFetch(document);

    /*
     * // DOM Position key index //
     *
     * l - Length changing (dropdown) f - Filtering input (search) t - The
     * Table! (datatable) i - Information (records) p - Pagination (paging) r -
     * pRocessing < and > - div elements <"#id" and > - div with an id <"class"
     * and > - div with a class <"#id.class" and > - div with an id and class
     *
     * Also see: http://legacy.datatables.net/usage/features
     */

    initSelectAll(document);

    initDataTables(document);

    initTreeView(document);

    // image
    initImageZoom(document);

    initDropdown($(document));

    initScrollButton();

    setupLabels($(document));

    setupFormButtonReset($(document));

    $('[data-action="toggleAlerts"]').click(function () {
        toggleAlerts();
    });
    $('[data-action="toggleAbsences"]').click(function () {
        toggleAbsences();
    });
    $('[data-action="scrollTop"]').click(function () {
        scrollTop();
    });

    // tooltips
    $('[data-tooltip]').tooltip();
    $(document).on('click', '.btn-click-disabled', function () {
        $(this).addClass('disabled');
    });

    // Listener to add class scroll-top when body scroll is on top of document
    if ((window.pageYOffset || (document.documentElement || document.body).scrollTop) === 0) {
        document.body.classList.add('scroll-ontop');
    }
    document.addEventListener('scroll', function () {
        if ((window.pageYOffset || (document.documentElement || document.body).scrollTop) === 0) {
            document.body.classList.add('scroll-ontop');
        } else {
            document.body.classList.remove('scroll-ontop');
        }
    });

    if (1 == SIRH_STIMULUS_AUTO_DEFER) {
        document.dispatchEvent(new Event(window.SIRH_EVENT_DEFER_STIMULUS_CONTROLLERS));
    }
});
