import { Controller } from "@hotwired/stimulus";

export default class LayoutController extends Controller {
    options = null;

    constructor(context) {
        super(context);

        if ('layout--layout' === this.scope.identifier) {
            throw new Error('Cannot instantiate LayoutController directly.')
        }
    }

    getEndpointUri(endpoint) {
        return (undefined !== SIRH_LAYOUT_ENDPOINTS) ? SIRH_LAYOUT_ENDPOINTS[endpoint] : undefined;
    }

    getOptions() {
        if (null === this.options) {
            this.options = JSON.parse(this.element.dataset.controllerOptions);
        }

        return this.options;
    }

    getOption(option, defaultValue) {
        if (undefined === defaultValue) {
            defaultValue = null;
        }

        return this.getOptions()[option] ?? defaultValue;
    }

    fetch(endpoint, uri) {
        return window.fetch(uri, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Fetch': 'xhr-' + endpoint,
                'X-Requested-With': 'XMLHttpRequest',
            }
        });
    }
}
