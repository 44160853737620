"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class DropzoneController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.onAddedFile = (file) => {
            var _a, _b;
            this.dropzone.removeFile(file);
            file.previewElement.remove();
            (_a = this.collection) === null || _a === void 0 ? void 0 : _a.addEventListener('form-collection:added', this.onCollectionAdded(file));
            (_b = this.collection) === null || _b === void 0 ? void 0 : _b.dispatchEvent(new Event('form-collection:add'));
        };
        this.onCollectionAdded = (file) => () => {
            var _a, _b;
            (_a = this.collection) === null || _a === void 0 ? void 0 : _a.removeEventListener('form-collection:added', this.onCollectionAdded(file));
            const item = (_b = this.collection) === null || _b === void 0 ? void 0 : _b.querySelector('[data-form--collection-target="item"]:last-of-type');
            if (!item)
                return;
            // @ts-ignore
            const { dropzone } = item.querySelector('.dropzone');
            const label = item.querySelector('input[name^="site_configuration_home[homeFiles]"][name$="[label]"]');
            const uid = item.querySelector('input[name^="site_configuration_home[homeFiles]"][name$="[upload][uid]"]');
            dropzone.emit('addedfile', file);
            label.value = file.name.split('.').slice(0, -1).join('.');
            uid.value = Math.random().toString(36).slice(2, 9);
            const data = new FormData();
            data.append('file', file);
            data.append('uid', uid.value);
            return fetch('/json/dropzone', {
                method: 'POST',
                body: data,
                credentials: 'same-origin',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }).then((response) => {
                if (!response.ok)
                    throw new Error(response.statusText);
                return response.json();
            }).then((response) => {
                dropzone.emit('success', file);
            }, (error) => {
                dropzone.emit('error', file, error.message);
            });
        };
        this.onCollectionDeleted = () => {
            var _a;
            (_a = document.getElementById('alert-validate-deleted')) === null || _a === void 0 ? void 0 : _a.classList.remove('d-none');
        };
    }
    initialize() {
        // @ts-ignore
        this.dropzone = new Dropzone(this.input, $(this.input).data('dropzone-options'));
    }
    connect() {
        var _a;
        this.dropzone.on('addedfile', this.onAddedFile);
        (_a = this.collection) === null || _a === void 0 ? void 0 : _a.addEventListener('form-collection:deleted', this.onCollectionDeleted);
    }
    disconnect() {
        var _a;
        this.dropzone.off('addedfile', this.onAddedFile);
        (_a = this.collection) === null || _a === void 0 ? void 0 : _a.removeEventListener('form-collection:deleted', this.onCollectionDeleted);
    }
    get collection() {
        return this.element.closest('[data-controller~="form--collection"]');
    }
    get input() {
        if (this._input)
            return this._input;
        return this._input = this.element.querySelector('.dropzone');
    }
}
exports.default = DropzoneController;
