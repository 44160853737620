import { Controller } from "@hotwired/stimulus";

export default class FormSwitchController extends Controller {
    initialize() {
        this.observer = new MutationObserver(this.onMutate);
        const input = this.findInput();

        this.checkbox = document.createElement('input');
        this.checkbox.type = 'checkbox';
        this.checkbox.id = input.getAttribute('id');
        this.checkbox.disabled = input.disabled;
        this.checkbox.classList.add('custom-control-input');

        input.classList.remove('custom-control-input');
        input.after(this.checkbox);
        input.removeAttribute('id');

        this.findLabel().classList.remove('indeterminate');
    }

    connect() {
        const input = this.findInput();
        const checked = (input.getAttribute('checked') === 'checked') && (input.value === '1');
        this.checkbox.checked = checked;
        input.value = checked ? '1' : '0';
        input.setAttribute('checked', 'checked');

        const { textOn, textOff } = this.element.dataset;
        if (textOn && textOff) {
            this.findLabel().innerText = this.checkbox.checked ? textOn : textOff;
        }

        this.checkbox.addEventListener('change', this.onChange);
        this.observer.observe(input, { attributes: true });
    }

    disconnect() {
        this.checkbox.removeEventListener('change', this.onChange);
        this.observer.disconnect();
    }

    onChange = () => {
        const input = this.findInput();
        this.change();
        input.dispatchEvent(new Event('change', { bubbles: true }));
    };

    onMutate = () => {
        this.change();
    };

    change = () => {
        const input = this.findInput();
        if (input.value !== (this.checkbox.checked ? '1' : '0')) {
            input.value = this.checkbox.checked ? '1' : '0';
            input.setAttribute('checked', 'checked');

            const { textOn, textOff } = this.element.dataset;
            if (textOn && textOff) {
                this.findLabel().innerText = this.checkbox.checked ? textOn : textOff;
            }
        }
    };

    findInput() {
        return this.element.querySelector('input[name][type="checkbox"], input[name][type="radio"]');
    }

    findLabel() {
        return this.element.querySelector('label.custom-control-label');
    }
}
