import { Controller } from "@hotwired/stimulus";

const CLASSNAME = 'nav-function-hidden';
const COOKIE = 'theme_menu_display=:value; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax';

export default class MenuIndentController extends Controller {
    button = this.element;
    icon = this.element.querySelector('.fal');

    initialize() {
        if (document.body.classList.contains(CLASSNAME)) {
            this.setMenuHidden();
        } else {
            this.setMenuShown();
        }
    }

    connect() {
        this.button.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.button.removeEventListener('click', this.onClick);
    }

    onClick = (event) => {
        event.preventDefault();
        this.toggleMenu();
    };

    onChange = (event) => {
        event.preventDefault();
        this.toggleMenu();
    };

    setMenuHidden() {
        const { titleShown } = this.button.dataset;
        document.cookie = COOKIE.replace(':value', 'hidden');
        document.body.classList.add(CLASSNAME);
        this.button.setAttribute('title', titleShown);
        this.button.setAttribute('data-title', titleShown);
        this.button.setAttribute('data-original-title', titleShown);
        this.icon.classList.add('fa-indent');
        this.icon.classList.remove('fa-outdent');
    }

    setMenuShown() {
        const { titleHidden } = this.button.dataset;
        document.cookie = COOKIE.replace(':value', 'shown');
        document.body.classList.remove(CLASSNAME);
        this.button.setAttribute('title', titleHidden);
        this.button.setAttribute('data-title', titleHidden);
        this.button.setAttribute('data-original-title', titleHidden);
        this.icon.classList.add('fa-outdent');
        this.icon.classList.remove('fa-indent');
    }

    toggleMenu() {
        if (document.body.classList.contains(CLASSNAME)) {
            this.setMenuShown();
        } else {
            this.setMenuHidden();
        }
    }
}
