import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class WizardContractMassController extends Controller {
    static targets = ['firstColumn', 'secondColumn', 'stepPeriod', 'stepEmployee', 'stepFunction', 'stepSalary'];

    initialize() {
        this.reorganize();
    }

    connect() {
        $('form', this.stepPeriodTarget).on('change', ':input', this.onChangeStepPeriod);
        $('form', this.stepEmployeeTarget).on('change', ':input', this.onChangeStepEmployee);
        $('form', this.stepFunctionTarget).on('change', ':input', this.onChangeStepFunction);
        $('form', this.stepSalaryTarget).on('change', ':input', this.onChangeStepSalary);

        $('form button[type="submit"]', this.stepPeriodTarget).on('click', this.onClickSubmitStepPeriod);
        $('form button[type="submit"]', this.stepEmployeeTarget).on('click', this.onClickSubmitStepEmployee);
        $('form button[type="submit"]', this.stepFunctionTarget).on('click', this.onClickSubmitStepFunction);
        $('form button[type="submit"]', this.stepSalaryTarget).on('click', this.onClickSubmitStepSalary);
    }

    disconnect() {
        $('form', this.stepPeriodTarget).off('change', ':input', this.onChangeStepPeriod);
        $('form', this.stepEmployeeTarget).off('change', ':input', this.onChangeStepEmployee);
        $('form', this.stepFunctionTarget).off('change', ':input', this.onChangeStepFunction);
        $('form', this.stepSalaryTarget).off('change', ':input', this.onChangeStepSalary);

        $('form button[type="submit"]', this.stepPeriodTarget).off('click', this.onClickSubmitStepPeriod);
        $('form button[type="submit"]', this.stepEmployeeTarget).off('click', this.onClickSubmitStepEmployee);
        $('form button[type="submit"]', this.stepFunctionTarget).off('click', this.onClickSubmitStepFunction);
        $('form button[type="submit"]', this.stepSalaryTarget).off('click', this.onClickSubmitStepSalary);
    }

    reorganize = () => {
        if (!this.stepEmployeeTarget.innerHTML.trim()) {
            this.firstColumnTarget.setAttribute('class', 'col-12');
            this.secondColumnTarget.setAttribute('class', 'col-0');
        } else {
            this.firstColumnTarget.setAttribute('class', 'col-12 col-xl-8');
            this.secondColumnTarget.setAttribute('class', 'col-12 col-xl-4');
        }
    }

    change = (modifier, targets = []) => {
        return this.send(modifier, targets, { change: true });
    };

    submit = (modifier, targets = []) => {
        return this.send(modifier, targets, { submit: true });
    }

    send = (modifier, targets = [], opts = {}) => {
        const form = modifier.querySelector('form');
        if (!form) return;

        const $forms = $(targets).find('form');
        $forms.trigger('form_modifier.process_start');

        const headers = { 'X-Requested-With': 'XMLHttpRequest' };
        if (opts.change) Object.assign(headers, { 'X-Form-Modifier': 'fetch' });

        return window.fetch(form.action || document.location.toString(), {
            method: form.method || 'POST',
            body: new FormData(form),
            credentials: 'include',
            redirect: 'follow',
            headers,
        }).then((response) => {
            if (response.redirected) document.location = response.url;
            else if (response.ok) return response.text().then((html) => $(html));
            else throw new Error(response.statusText);
        }).then(($html) => {
            if ($html) {
                $('#alert-session-flash-container').html($html.find('#alert-session-flash-container').html());
                Object.entries({ success: 'success', error: 'danger' }).forEach(([method, className]) => {
                    $html.find(`#alert-session-flash-container .alert-${className}`).each(function () {
                        window.toastr[method]($(this).html());
                    });
                });

                for (let idx = 0, len = targets.length; idx < len; idx++) {
                    const content = $html.find(`#${targets[idx].id}`).html();
                    $(targets[idx]).html(content);
                    if (content) window.initFormUi($(targets[idx]));
                }

                const subheader = $html.find('.page-subheader .rh-wizard-indicator').html();
                $('.page-subheader .rh-wizard-indicator').html(subheader);
                $('.page-subheader-scrolling .rh-wizard-indicator').html(subheader);
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            $forms.trigger('form_modifier.process_end');

            this.disconnect();
            this.connect();
            this.reorganize();
        });
    };

    onChangeStepPeriod = () => {
        this.change(this.stepPeriodTarget, [
            this.stepPeriodTarget,
            this.stepEmployeeTarget,
            this.stepFunctionTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepEmployee = () => {
        this.change(this.stepEmployeeTarget, [
            this.stepEmployeeTarget,
            this.stepFunctionTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepFunction = () => {
        this.change(this.stepFunctionTarget, [
            this.stepFunctionTarget,
            this.stepSalaryTarget,
        ]);
    }

    onChangeStepSalary = () => {
        this.change(this.stepSalaryTarget, [
            this.stepSalaryTarget,
        ]);
    }

    onClickSubmitStepPeriod = (event) => {
        event.preventDefault();
        this.submit(this.stepPeriodTarget, [
            this.stepPeriodTarget,
            this.stepEmployeeTarget,
        ]);
    };

    onClickSubmitStepEmployee = (event) => {
        event.preventDefault();
        this.submit(this.stepEmployeeTarget, [
            this.stepEmployeeTarget,
            this.stepFunctionTarget,
        ]);
    };

    onClickSubmitStepFunction = (event) => {
        event.preventDefault();
        this.submit(this.stepFunctionTarget, [
            this.stepFunctionTarget,
            this.stepSalaryTarget,
        ]);
    };

    onClickSubmitStepSalary = (event) => {
        event.preventDefault();
        this.submit(this.stepSalaryTarget, [
            this.stepSalaryTarget,
        ]);
    };
}
