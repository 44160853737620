import { Controller } from "@hotwired/stimulus";
import { TINYMCE_DEFAULT_HEIGHT, setupTinyMce } from "#utils/form/tinymce";

export default class FormTinyMceController extends Controller {
  editor = null;

  connect() {
    const textarea = this.element.querySelector('textarea');
    let height = textarea.dataset.height || TINYMCE_DEFAULT_HEIGHT;
    if (TINYMCE_DEFAULT_HEIGHT > height) {
      height = TINYMCE_DEFAULT_HEIGHT;
    }

    setupTinyMce(this.element, textarea, { height }).then((editor) => {
      this.editor = editor;
    }).catch(() => {
      console.log('tinymce is not defined');
    });
  }

  disconnect() {
    Promise.resolve().then(() => {
      this.editor?.remove();
    }).catch(() => {
      console.log('tinymce is not defined');
    })
  }
}