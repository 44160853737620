import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

const TEMPLATE_MODAL = `
    <div class="modal fade show">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Attention</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
          </div>
        </div>
      </div>
    </div>
`;

export default class FormPartnerController extends Controller {

    urlEdition = this.element.getAttribute('data-ajax-url-edition');
    urlAddUser = this.element.getAttribute('data-ajax-url-add-user');
    socId = this.element.getAttribute('data-society-id');
    cusId = this.element.getAttribute('data-user-id');

    msg = '';
    data = new FormData();

    connect() {
        this.data.set('partnerId', this.element.value);
        this.data.set('societyId', this.socId);
        this.data.set('userId', this.cusId);
        this.element.addEventListener('change', this.onInputChange);

        // ajax - checkbox we limit on loading page
        this.ajaxUserAllowanceCheckbox();
    }

    disconnect() {
        this.element.addEventListener('change', this.onInputChange);
    }

    onInputChange = (event) => {
        let edition = true;
        this.data.set('partnerId', this.element.value);

        let partnerAllowSocieties = Array.from(document.querySelectorAll('[data-partner-allow-society-id]'))

        if (partnerAllowSocieties) {
            this.resetAll(partnerAllowSocieties);
        }

        // check
        if (!this.element.value) {
            return true;
        }

        // check - edition ou add in progress
        if (!this.socId) {
            // @todo translate
            this.msg = 'L\'utilisateur n\'aura plus accès à ces sociétés, si vous choisissez ce partenaire pour cet utilisateur:';
        } else if (!this.cusId ) {
            // @todo translate
            this.msg = 'Les utilisateurs suivants n\'auront plus accès à cette société, si vous choisissez ce partenaire pour cette société:';
        } else {
            edition = false; // don't launch ajax to prevent loss of allowance because it's a new Society OR ControlUser
        }

        // ajax - checkbox
        this.ajaxUserAllowanceCheckbox(partnerAllowSocieties);

        // ajax - allowance removed alert
        if (edition) {
            let role = document.querySelector('[data-role]');
            if (role && role.value === 'ROLE_CONTROL_ADMIN') {
                return true;
            }

            this.ajaxUserAllowanceRemovedAlert()
        }
    };

    // set in red societies who are no more allowed
    ajaxUserAllowanceCheckbox(partnerAllowSocieties)
    {
        if (this.urlAddUser && partnerAllowSocieties) {
            window.fetch(this.urlAddUser,{
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: this.data,
            })
                .then(response => response.json())
                .then(result => {
                    if (Array.isArray(result)) {
                        this.discardAll(partnerAllowSocieties);
                        if (result.length > 0) {
                            // remove class error to authorize
                            for (const i in result) {
                                for (let x = 0; x < partnerAllowSocieties.length; x++) {
                                    if (parseInt(partnerAllowSocieties[x].getAttribute('data-partner-allow-society-id')) === parseInt(result[i])) {
                                        partnerAllowSocieties[x].classList.remove('partnerNotAllowedSociety');
                                    }
                                }
                            }
                        }
                    }
                });
        }
    }

    resetAll(societies)
    {
        for (let i = 0; i < societies.length; i++) {
            societies[i].classList.remove('partnerNotAllowedSociety');
        }

    }
    discardAll(societies)
    {
        for (let i = 0; i < societies.length; i++) {
            societies[i].classList.add('partnerNotAllowedSociety');
        }
    }

    // ajax to alert loss of allowance
    ajaxUserAllowanceRemovedAlert()
    {
        if (this.urlEdition) {
            window.fetch(this.urlEdition, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: this.data,
            })
                .then(response => response.json())
                .then(result => {
                    if (result) {
                        // modal - specific to partner
                        let nbSocieties = 0;
                        let modalBody = document.createElement("div");
                        modalBody.innerHTML = `<div><p>${this.msg}</p></div>`;
                        for (const resultKey in result) {
                            nbSocieties++;
                            let para = document.createElement("p");
                            const node = document.createTextNode(result[resultKey]);
                            para.appendChild(node);
                            para.classList.add('alert', 'alert-danger');
                            modalBody.appendChild(para);
                        }

                        // modal - generic
                        if (nbSocieties > 0) {
                            let $modal = $(TEMPLATE_MODAL);
                            $modal.find('.modal-body').append(modalBody);
                            $modal.on('hidden.bs.modal', () => {
                                $modal.remove();
                            });
                            $(document.body).append($modal);
                            $modal.modal('show');
                        }
                    }
                });
        }
    }
}
