import { Controller } from "@hotwired/stimulus";

export default class FormTotalController extends Controller {
    connect() {
        this.element.addEventListener('keydown', this.onKeyDown);
    }

    disconnect() {
        this.element.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = (event) => {
        const key = event.key; // ES6+
        const regex = new RegExp("[0-9,.]");
        if (key === "Delete" || key === 'Backspace' || key === "Enter") {
            return true;
        } else if (!regex.test(key)) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    }
}
