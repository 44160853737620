import { Controller } from "@hotwired/stimulus";
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min';

const DATEPICKER_OPTIONS = [
  'autoclose' , 'assumeNearbyYear', 'beforeShowDay', 'beforeShowMonth', 'beforeShowYear', 'beforeShowDecade',
  'beforeShowDecade', 'calendarWeeks', 'clearBtn', 'container', 'datesDisabled', 'daysOfWeekDisabled',
  'daysOfWeekHighlighted', 'defaultViewDate', 'disableTouchKeyboard', 'enableOnReadonly', 'endDate', 'forceParse',
  'format', 'immediateUpdates', 'inputs', 'keepEmptyValues', 'keyboardNavigation', 'language', 'maxViewMode',
  'minViewMode', 'multidate', 'multidateSeparator', 'orientation', 'showOnFocus', 'startDate', 'startView',
  'templates', 'title', 'todayBtn', 'todayHighlight', 'toggleActive', 'weekStart', 'zIndexOffset'
];

const DATEPICKER_DEFAULT_OPTIONS = {
  autoclose: true,
  language: 'fr',
  zIndexOffset: 1000,
  // orientation: 'bottom',
};

export default class FormDateRangeController extends Controller {
  initialize() {
    if (undefined !== this.element.attributes.disabled || undefined !== this.element.attributes.readonly) {
      return;
    }

    this.setupDatePicker();
  }

  connect() {
    if (undefined !== this.element.attributes.disabled || undefined !== this.element.attributes.readonly) {
      return;
    }

    $(this.element).mask('00/00/0000');

    // force view update if value is already defined
    $(this.element).datepicker('update');
    $(this.linkedInput).data('datepicker')?.update();
  }

  disconnect() {
    $(this.element).datepicker('destroy');
    $(this.element).unmask();
  }

  onBeforeShowDay = (date) => {
    if (!this.linkedInput) {
      return true;
    }

    // prevent calling datepicker constructor on not yet mapped field
    if (!$(this.linkedInput).data('datepicker')) {
      return true;
    }

    const linkedDate = $(this.linkedInput).datepicker('getDate');
    if (null !== linkedDate) {
      return 'from' === this.element.dataset.datepickerRangeScope
        ? date <= linkedDate
        : date >= linkedDate
      ;
    }
  }

  onChangeDate = () => {
    $(this.element).closest('.form-floating').addClass('active');
    $(this.linkedInput).data('datepicker')?.update();
  }

  setupDatePicker = () => {
    const options = Object.assign(
      { ...DATEPICKER_DEFAULT_OPTIONS },
      { beforeShowDay: this.onBeforeShowDay },
      JSON.parse(this.element.dataset.datepickerOptions)
    );

    // select month or year views?
    if (undefined === options.maxViewMode && undefined !== options.changeMonth && false == options.changeMonth) {
      options.maxViewMode = 0;
    }
    if (undefined === options.maxViewMode && undefined !== options.changeYear && false == options.changeYear) {
      options.maxViewMode = 1;
    }

    // clear options
    for (let keys = Object.keys(options), idx = 0, len = keys.length; idx < len; idx++) {
      if (!DATEPICKER_OPTIONS.includes(keys[idx])) {
        delete options[keys[idx]];
      }
    }

    // remove autocomplete
    this.element.setAttribute('autocomplete', 'off');
    $(this.element).datepicker(options);
    $(this.element).on('changeDate', this.onChangeDate);
  }

  get form() {
    let parent = this.element.closest('.form-collection-item');
    if (!parent) {
      parent = this.element.closest('form');
    }
    return parent;
  }

  get linkedInput() {
    const linkRange = this.element.dataset.datepickerRangeLinked;
    if (!linkRange) {
      return;
    }

    const uid = this.element.dataset.datepickerRangeUid;
    return this.form.querySelector(`input[data-datepicker-range="${linkRange}"][data-datepicker-range-uid="${uid}"]`);
  }
}
