import { Controller } from "@hotwired/stimulus";

export default class FormDisableSubmitController extends Controller {

    btnSubmit = this.element;

    connect() {
        this.element.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.element.removeEventListener('click', this.onClick);
    }

    clearAvoidDoubleClick (btn, initialHtml) {
        btn.removeAttribute('alreadySubmitted', false)
        btn.style.cursor = 'pointer';
        btn.innerHTML = initialHtml;
    }

    onClick = (event) => {
        let initialHtml = this.btnSubmit.innerHTML;
        if (this.btnSubmit.getAttribute('alreadySubmitted') === "true") {
            event.preventDefault();
        } else {
            this.btnSubmit.setAttribute('alreadySubmitted', "true")
            this.btnSubmit.style.cursor = 'not-allowed';
            this.btnSubmit.textContent = 'En cours';
            setTimeout(this.clearAvoidDoubleClick, 5000, this.btnSubmit, initialHtml);
        }
    }
}
