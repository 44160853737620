import { Controller } from "@hotwired/stimulus";

export default class FormFirstnameController extends Controller {
    connect() {
        this.element.addEventListener('keyup', this.onKeyUp);
    }

    disconnect() {
        this.element.removeEventListener('keyup', this.onKeyUp);
    }

    onKeyUp = () => {
        let value = this.element.value.toLowerCase();
        for (let idx = 0; idx < value.length; idx++) {
            const char = value[idx];
            if (idx === 0) {
                value = value[0].toUpperCase() + value.slice(1);
            } else if ([' ', '-'].includes(char) && idx + 1 < value.length) {
                value = value.slice(0, idx + 1) + value[idx + 1].toUpperCase() + value.slice(idx + 2);
            }
        }
        this.element.value = value;
    }
}
