import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.min.css';
import './phone_controller.scss';

export default class FormPhoneController extends Controller {
    connect() {
        if (this.element.classList.contains('initialized')) return;
        this.element.classList.add('initialized');

        this.input = document.createElement('input');
        this.input.type = 'tel';
        this.input.required = this.element.required;
        this.input.classList.add('form-control');
        if (this.element.classList.contains('is-valid')) {
            this.input.classList.add('is-valid');
        }
        if (this.element.classList.contains('is-invalid')) {
            this.input.classList.add('is-invalid');
        }
        this.element.after(this.input);

        this.iti = intlTelInput(this.input, {
            nationalMode: false,
            preferredCountries : ['fr'],
            separateDialCode: true,
            utilsScript: '/dist/intlTelInput.utils.min.js',
        });

        this.iti.setNumber(this.element.value);
        this.setPlaceholder();

        this.input.addEventListener('countrychange', () => {
            this.input.value = '';
            this.setPlaceholder();
            $(this.input).trigger('change');
        });

        this.input.addEventListener('change', () => {
            this.element.value = this.iti.getNumber();
            this.checkValidity(this.element);
            this.checkValidity(this.input);
        });
    }

    disconnect() {
        this.input.parentNode.removeChild(this.input);
    }

    getPlaceholder() {
        const data = this.iti.getSelectedCountryData();
        const number = window.intlTelInputUtils.getExampleNumber(data.iso2, false, 1);
        const dialCode = `+${data.dialCode}`;
        const start = (number[dialCode.length] === ' ' || number[dialCode.length] === '-') ? dialCode.length + 1 : dialCode.length;
        return number.slice(start);
    };

    setPlaceholder() {
        const placeholder = this.getPlaceholder().trim();
        $(this.input).mask(placeholder.replace(/\d/g, 0));
        this.input.setAttribute('pattern', placeholder.replace(/\d/g, '\\d'));
        this.input.setAttribute('placeholder', placeholder);
    }

    checkValidity = (input) => {
        input.classList.remove('is-valid');
        input.classList.remove('is-invalid');
        input.classList.remove('valid');
        input.classList.remove('invalid');

        let valid = true;
        if (input.required && !input.value) {
            valid = false;
        } else if (input.pattern && !(new RegExp(input.pattern)).test(input.value)) {
            valid = false;
        }

        input.classList.add(valid ? 'is-valid' : 'is-invalid');
    };
}
