"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FormSubmitLoaderController__input, _FormSubmitLoaderController__form, _FormSubmitLoaderController_onSubmit;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class FormSubmitLoaderController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _FormSubmitLoaderController__input.set(this, void 0);
        _FormSubmitLoaderController__form.set(this, void 0);
        _FormSubmitLoaderController_onSubmit.set(this, () => {
            const icon = __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").querySelector('.fal, .fas, .far, .fat, .fad');
            icon === null || icon === void 0 ? void 0 : icon.remove();
            const loader = document.createElement('span');
            loader.classList.add('spinner-grow', 'spinner-grow-sm');
            __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").prepend(loader);
            __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").classList.add('disabled');
            __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").setAttribute('disabled', 'disabled');
        });
    }
    initialize() {
        __classPrivateFieldSet(this, _FormSubmitLoaderController__input, this.element, "f");
        __classPrivateFieldSet(this, _FormSubmitLoaderController__form, this.element.closest('form'), "f");
    }
    connect() {
        var _a;
        if (null === __classPrivateFieldGet(this, _FormSubmitLoaderController__form, "f")) {
            __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").addEventListener('click', __classPrivateFieldGet(this, _FormSubmitLoaderController_onSubmit, "f"));
        }
        else {
            (_a = __classPrivateFieldGet(this, _FormSubmitLoaderController__form, "f")) === null || _a === void 0 ? void 0 : _a.addEventListener('submit', __classPrivateFieldGet(this, _FormSubmitLoaderController_onSubmit, "f"));
        }
    }
    disconnect() {
        var _a;
        __classPrivateFieldGet(this, _FormSubmitLoaderController__input, "f").removeEventListener('click', __classPrivateFieldGet(this, _FormSubmitLoaderController_onSubmit, "f"));
        (_a = __classPrivateFieldGet(this, _FormSubmitLoaderController__form, "f")) === null || _a === void 0 ? void 0 : _a.removeEventListener('submit', __classPrivateFieldGet(this, _FormSubmitLoaderController_onSubmit, "f"));
    }
}
exports.default = FormSubmitLoaderController;
_FormSubmitLoaderController__input = new WeakMap(), _FormSubmitLoaderController__form = new WeakMap(), _FormSubmitLoaderController_onSubmit = new WeakMap();
