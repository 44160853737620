import { Controller } from "@hotwired/stimulus";

export default class FormContractCancelController extends Controller {
    initialize() {
        const today = new Date();
        const value = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth()).padStart(2, '0')}/${String(today.getFullYear()).padStart(4, 0)}`;
        $(this.element).closest('form').find('input[name="form[removeEventsFrom]"]').val(value).prop('disabled', true);
    }

    connect() {
        $(this.element).find('input[name="form[removeEvents]"]').on('change', this.onChange);
    }

    disconnect() {
        $(this.element).find('input[name="form[removeEvents]"]').off('change', this.onChange);
    }

    onChange = () => {
        const value = $(this.element).find('input[name="form[removeEvents]"]:checked').val();
        $(this.element).closest('form').find('input[name="form[removeEventsFrom]"]').prop('disabled', value !== 'from');
    };
}
