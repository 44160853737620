import $ from "jquery";

window.FORM_MODIFIER_EVENT_PROCESS_START = 'form_modifier.process_start';
window.FORM_MODIFIER_EVENT_PROCESS_END = 'form_modifier.process_end';

export default function initFormEventsModifiers(node) {
  if (node.length) node = node.get(0);

  const updaters = [].slice.call(node.querySelectorAll('[data-form-modifiers]'));
  if (node.dataset && node.dataset.formModifiers) {
    updaters.unshift(node);
  }

  const xhrs = {};

  for (let idx = 0, len = updaters.length; idx < len; idx++) {
    const updater = updaters[idx];
    if (updater.closest('[data-controller~="wizard--contract-mass"]')) continue;
    if (updater.closest('[data-controller~="wizard--contract"]')) continue;
    if (updater.closest('[data-controller~="wizard--employee"]')) continue;
    const form = updater.closest('form');
    if (!form) continue;

    const targets = updater.dataset.formModifiers.split(',').map((selector) => selector.trim());
    for (let idx = 0, len = targets.length; idx < len; idx++) {
      const target = document.querySelector(targets[idx]);
      if (!target) continue;
      if (!target.classList.contains('can-be-empty')) {
        target.classList.add('can-be-empty');
      }
    }

    // Cant use :input selector in pure js
    $(`[name^="${form.getAttribute('name')}"]`, updater).filter(':input').on('change', function () {
      $(form).trigger(window.FORM_MODIFIER_EVENT_PROCESS_START);
      form.dispatchEvent(new CustomEvent(`custom::${window.FORM_MODIFIER_EVENT_PROCESS_START}`, {
        detail: { targets, updater },
      }));

      let action = form.getAttribute('action') || document.location.toString();
      let method = (form.getAttribute('method') || 'POST').toUpperCase();
      let data = getFormData(form, updater);

      if ('HEAD' === method || 'GET' === method) {
        action += '?' + new URLSearchParams(data).toString();
        data = null;
      }

      if (xhrs[form.getAttribute('name')]) {
        xhrs[form.getAttribute('name')].abort();
      }

      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, action);
        xhr.setRequestHeader('X-Form-Modifier', 'jQuery');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('X-Requested-Scope', 'modifiers');
        (updater.getAttribute('data-form-modifier-headers') || "").split(',').forEach((header) => {
          const [name, value] = header.split(':').map((value) => value.trim());
          if (name && value) {
            xhr.setRequestHeader(`X-Form-Modifier-${name}`, value);
          }
        });

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === 4) {
            delete xhrs[form.getAttribute('name')];
            if (xhr.status == 200) {
              if (form.closest('[data-trigger="embedded-form"]')) {
                resolve(JSON.parse(xhr.responseText).html);
              } else {
                resolve(xhr.responseText);
              }
            } else {
              reject(xhr.responseText);
            }
          }
        });
        xhrs[form.getAttribute('name')] = xhr;
        xhr.send(data);
      }).then((html) => {
        const $html = $(html);
        for (let idx = 0, len = targets.length; idx < len; idx++) {
          const content = $html.find(targets[idx]).html();
          const $target = $(targets[idx], form);
          $target.html(content);
          if ($target.length && content) {
            initFormUi($target);
          }
        }
        initSelectAll(form);
      }, (error) => {
        if (!error) return;
        console.error(error);
      }).finally(() => {
        $(form).trigger(window.FORM_MODIFIER_EVENT_PROCESS_END);
        form.dispatchEvent(new CustomEvent(`custom::${window.FORM_MODIFIER_EVENT_PROCESS_END}`, {
          detail: { targets, updater },
        }));
      });
    });
  }
}

// Exclude some data from data-form-modifier-exclude attribute
// Examples:
//  - data-form-modifier-exclude="name|email" => form[name] and form[email] will be excluded
//  - data-form-modifier-exclude="name/first|name/last" => form[name][first] and form[name][last] will be excluded
function getFormData(form, updater) {
  const excludedNames = updater.dataset.formModifierExclude?.split('|').map((name) => `${form.name}[${name.replaceAll('/', '][')}]`);
  const data = new FormData(form);

  if (excludedNames?.length) {
    const formKeys = Array.from(data?.keys() || []);
    const excludedKeys = [];

    for (let idxName = 0; idxName < excludedNames.length; idxName++) {
      const excludedName = excludedNames[idxName];

      // Loop backward because of splice inside
      for (let idxKey = formKeys.length - 1; idxKey >= 0; idxKey--) {
        const formKey = formKeys[idxKey];
        if (formKey.startsWith(excludedName)) {
          excludedKeys.push(formKey);
          // Splice to avoid re-loop on the same key
          formKeys.splice(idxKey, 1);
        }
      }
    }

    for (let idx = 0; idx < excludedKeys.length; idx++) {
      data.delete(excludedKeys[idx]);
    }
  }

  return data;
}
