import { Controller } from "@hotwired/stimulus";

require('summernote/dist/summernote-lite');

import 'summernote/dist/summernote-lite.css';
import '../../../theme/smartadmin/plugins/summernote/summernote-custom.scss';

export default class FormSummernoteController extends Controller {
    connect() {
        const textarea = this.input;

        if (null === textarea || textarea.classList.contains('has-wysiwyg')) {
            return;
        }

        const height = textarea.dataset.height || 300;
        textarea.classList.add('has-wysiwyg');
        $(textarea).summernote({
            height: height,
            minHeight: height,
            enterHtml: '<br>',
            toolbar: [
                ['style', ['style', '<button>unbreakable</button>']],
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['table', ['table']],
                ['media', ['link', 'picture', 'hr']],
                ['view', ['fullscreen', 'codeview', 'undo', 'redo']],
                ['help', [ 'help']]
            ]
        });
    }

    get input() {
        return this.element.querySelector('textarea');
    }
}
