export function setLinkFetch(node, callback) {
  node.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();

    window.fetch(node.getAttribute('href'), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Fetch': true,
      },
    }).then((response) => {
      if (response.error) throw new Error(response.statusText);
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.message;
          }
          throw new Error(response.message);
        });
      } else {
        return response.text().then((message) => {
          if (response.status >= 200 && response.status < 300) {
            return message;
          }
          throw new Error(message);
        });
      }
    }).then((message) => {
      if (message) window.toastr.success(message);
      callback?.(node);
    }, (error) => {
      if (error) window.toastr.error('Une erreur est survenue.');
    });
  });
}