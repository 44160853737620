import { Controller } from "@hotwired/stimulus";
import { setupLabels } from "#theme/smartadmin/js/_init/floating_labels";

window.googleMapLoaded = function() {
    document.dispatchEvent(new Event('googlemap:loaded'));
}

export default class FormAddressController extends Controller {
    static targets = ['address', 'zip', 'city', 'country'];

    connect() {
        if (!window.google) {
            document.addEventListener('googlemap:loaded', this.onLoaded);
        } else {
            this.onLoaded();
        }
    }

    disconnect() {
        document.addEventListener('googlemap:loaded', this.onLoaded);
    }

    onLoaded = () => {
        this.autocomplete = new window.google.maps.places.Autocomplete(this.addressTarget);
        window.google.maps.event.addListener(this.autocomplete, 'place_changed', this.onChange);
    }

    onChange = () => {
        this.getComponents().then((components) => {
            const { address, city, country, zipCode } = components;
            this.addressTarget.value = address;
            this.cityTarget.value = city;

            if (this.hasZipCode()) {
                this.zipTarget.value = zipCode;
            }
            if (this.hasCountry()) {
                this.countryTarget.selectedIndex = 0;
                this.countryTarget.value = country;
            }

            return new Promise((resolve) => {
                const container = this.cityTarget.closest('[data-controller="form--autocomplete"]');
                if (!container) {
                    resolve(components);
                } else {
                    this.cityTarget.value = '';
                    const input = document.getElementById(`${this.cityTarget.id}_autocomplete`);
                    input.value = `${city} (${zipCode})`;
                    input.dispatchEvent(new Event('input'));
                    input.classList.add('disabled');
                    const menu = container.querySelector('.dropdown-menu');
                    $(menu).on('autocomplete::loaded', () => {
                        menu.querySelector('.dropdown-item:first-of-type')?.dispatchEvent(new Event('mousedown'));
                        menu.classList.remove('show');
                        input.classList.remove('disabled');
                        resolve(components);
                    });
                }
            });
        }).then(() => {
            this.checkValidity(this.addressTarget);
            this.checkValidity(this.cityTarget);
            if (this.hasCountry()) {
                this.checkValidity(this.countryTarget);
            }
            if (this.hasZipCode()) {
                this.checkValidity(this.zipTarget);
            }
        }).then(() => {
            setupLabels($(this.element));
        });
    };

    checkValidity(input) {
        input.classList.remove('is-valid');
        input.classList.remove('is-invalid');
        input.classList.remove('valid');
        input.classList.remove('invalid');

        let valid = true;
        if (input.required && !input.value) {
            valid = false;
        } else if (input.pattern && !(new RegExp(input.pattern)).test(input.value)) {
            valid = false;
        }

        input.classList.add(valid ? 'is-valid' : 'is-invalid');
    }

    getComponents() {
        return new Promise((resolve) => {
            const place = this.autocomplete.getPlace();
            const components = {};

            for (let i = 0; i < place.address_components.length; i++) {
                const component = place.address_components[i];
                for (let j = 0; j < component.types.length; j++) {
                    if ("postal_code" === component.types[j]) {
                        components.zipCode = component.long_name;
                    } else if ("locality" === component.types[j]) {
                        components.city = component.long_name;
                    } else if ("street_number" === component.types[j]) {
                        components.address = component.long_name;
                    } else if ("route" === component.types[j]) {
                        components.address += ' ' + component.long_name;
                    } else if ("country" === component.types[j]) {
                        components.country = component.short_name;
                    }
                }
            }

            resolve(components);
        });
    }

    hasCountry() {
        return !!this.element.querySelector('[data-form--address-target="country"]');
    }

    hasZipCode() {
        return !!this.element.querySelector('[data-form--address-target="zip"]');
    }
}
