import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { modify } from '#utils/form';
import { parseDataTargetSelectors } from "#utils/dom";

export default class FormModifiersCollectionController extends Controller {
    form = this.element.closest('form');
    item = this.element.closest('[data-form--collection-target="item"]');

    connect() {
        $(`[name^="${this.form.name}"]`, this.element).on('change', this.onInputChange);
    }

    disconnect() {
        $(`[name^="${this.form.name}"]`, this.element).off('change', this.onInputChange);
    }

    onInputChange = () => {
        const targets = parseDataTargetSelectors(this.element.dataset.collectionModifiers, 'collection');
        modify(this.form, targets, this.item);
    };
}
