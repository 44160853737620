import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import {parseDataSelectors, parseDataTargetSelectors} from '#utils/dom';
import { modify } from '#utils/form';

export default class FormModifiersFormController extends Controller {
    form = this.element.closest('form');

    connect() {
        $(`[name^="${this.form.name}"]`, this.element).on('change', this.onInputChange);
    }

    disconnect() {
        $(`[name^="${this.form.name}"]`, this.element).off('change', this.onInputChange);
    }

    onInputChange = () => {
        modify(this.form, parseDataTargetSelectors(this.element.dataset.formModifiers, 'form-modifier'));
    };
}
