"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class ConfigurationHomeUploadController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.onAddedFile = () => {
            var _a, _b;
            (_a = this.icon) === null || _a === void 0 ? void 0 : _a.classList.remove('fa-upload', 'fa-check', 'fa-spinner', 'fa-spin', 'fa-times');
            (_b = this.icon) === null || _b === void 0 ? void 0 : _b.classList.add('fa-spinner', 'fa-spin');
            if (this.label) {
                this.label.innerText = 'Envoi';
            }
        };
        this.onClick = (evt) => {
            var _a;
            evt.preventDefault();
            evt.stopPropagation();
            (_a = this.input) === null || _a === void 0 ? void 0 : _a.click();
        };
        this.onError = (file, message) => {
            var _a, _b;
            this.element.classList.remove('btn-danger', 'btn-secondary', 'btn-success');
            this.element.classList.add('btn-danger');
            (_a = this.icon) === null || _a === void 0 ? void 0 : _a.classList.remove('fa-upload', 'fa-check', 'fa-spinner', 'fa-spin', 'fa-times');
            (_b = this.icon) === null || _b === void 0 ? void 0 : _b.classList.add('fa-times');
            if (this.label) {
                this.label.innerText = 'Erreur';
            }
            // @ts-ignore
            toastr.error(message);
        };
        this.onSuccess = () => {
            var _a, _b;
            this.element.classList.remove('btn-danger', 'btn-secondary', 'btn-success');
            this.element.classList.add('btn-success');
            (_a = this.icon) === null || _a === void 0 ? void 0 : _a.classList.remove('fa-upload', 'fa-check', 'fa-spinner', 'fa-spin', 'fa-times');
            (_b = this.icon) === null || _b === void 0 ? void 0 : _b.classList.add('fa-check');
            if (this.label) {
                this.label.innerText = 'Prêt';
            }
        };
    }
    connect() {
        var _a, _b, _c;
        this.element.addEventListener('click', this.onClick);
        this.element.addEventListener('success', this.onSuccess);
        (_a = this.dropzone) === null || _a === void 0 ? void 0 : _a.on('addedfile', this.onAddedFile);
        (_b = this.dropzone) === null || _b === void 0 ? void 0 : _b.on('error', this.onError);
        (_c = this.dropzone) === null || _c === void 0 ? void 0 : _c.on('success', this.onSuccess);
    }
    disconnect() {
        var _a, _b;
        this.element.removeEventListener('click', this.onClick);
        this.element.removeEventListener('success', this.onSuccess);
        (_a = this.dropzone) === null || _a === void 0 ? void 0 : _a.off('error', this.onError);
        (_b = this.dropzone) === null || _b === void 0 ? void 0 : _b.off('success', this.onSuccess);
    }
    get dropzone() {
        if (this._dropzone) {
            return this._dropzone;
        }
        // @ts-ignore
        return this._dropzone = this.input.dropzone;
    }
    get input() {
        var _a;
        if (this._input)
            return this._input;
        return this._input = (_a = this.element.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.dropzone');
    }
    get icon() {
        if (this._icon)
            return this._icon;
        return this._icon = this.element.querySelector('.fal');
    }
    get label() {
        if (this._label)
            return this._label;
        return this._label = this.element.querySelector('.file-upload-label');
    }
}
exports.default = ConfigurationHomeUploadController;
