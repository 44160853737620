"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FormPasswordPolicyController_instances, _FormPasswordPolicyController__input, _FormPasswordPolicyController__rules, _FormPasswordPolicyController_getRules, _FormPasswordPolicyController_onInputKeyUp, _FormPasswordPolicyController_onInputPaste, _FormPasswordPolicyController_validate;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
require("./password-policy_controller.scss");
class FormPasswordPolicyController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _FormPasswordPolicyController_instances.add(this);
        _FormPasswordPolicyController__input.set(this, void 0);
        _FormPasswordPolicyController__rules.set(this, []);
        _FormPasswordPolicyController_onInputKeyUp.set(this, (evt) => {
            var _a;
            const target = evt.target;
            __classPrivateFieldGet(this, _FormPasswordPolicyController_instances, "m", _FormPasswordPolicyController_validate).call(this, (_a = target === null || target === void 0 ? void 0 : target.value) !== null && _a !== void 0 ? _a : '');
        });
        _FormPasswordPolicyController_onInputPaste.set(this, (evt) => {
            var _a, _b;
            __classPrivateFieldGet(this, _FormPasswordPolicyController_instances, "m", _FormPasswordPolicyController_validate).call(this, (_b = (_a = evt.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text')) !== null && _b !== void 0 ? _b : '');
        });
    }
    initialize() {
        __classPrivateFieldSet(this, _FormPasswordPolicyController__input, this.element, "f");
        __classPrivateFieldSet(this, _FormPasswordPolicyController__rules, __classPrivateFieldGet(this, _FormPasswordPolicyController_instances, "m", _FormPasswordPolicyController_getRules).call(this), "f");
    }
    connect() {
        if (0 === __classPrivateFieldGet(this, _FormPasswordPolicyController_instances, "m", _FormPasswordPolicyController_getRules).call(this).length) {
            return;
        }
        this.element.addEventListener('keyup', __classPrivateFieldGet(this, _FormPasswordPolicyController_onInputKeyUp, "f"));
        this.element.addEventListener('paste', __classPrivateFieldGet(this, _FormPasswordPolicyController_onInputPaste, "f"));
    }
    disconnect() {
        this.element.removeEventListener('keyup', __classPrivateFieldGet(this, _FormPasswordPolicyController_onInputKeyUp, "f"));
        this.element.removeEventListener('paste', __classPrivateFieldGet(this, _FormPasswordPolicyController_onInputPaste, "f"));
    }
}
exports.default = FormPasswordPolicyController;
_FormPasswordPolicyController__input = new WeakMap(), _FormPasswordPolicyController__rules = new WeakMap(), _FormPasswordPolicyController_onInputKeyUp = new WeakMap(), _FormPasswordPolicyController_onInputPaste = new WeakMap(), _FormPasswordPolicyController_instances = new WeakSet(), _FormPasswordPolicyController_getRules = function _FormPasswordPolicyController_getRules() {
    var _a, _b;
    if (0 === __classPrivateFieldGet(this, _FormPasswordPolicyController__rules, "f").length) {
        for (const node of Array.from(document.querySelectorAll('.password-policy-contraints > li'))) {
            const rule = (_a = node.dataset.passwordCheck) !== null && _a !== void 0 ? _a : '';
            const length = Number((_b = node.dataset.passwordConstraint) !== null && _b !== void 0 ? _b : 0);
            if ('' !== rule && 0 < length) {
                __classPrivateFieldGet(this, _FormPasswordPolicyController__rules, "f").push({ rule, length, node });
            }
        }
    }
    return __classPrivateFieldGet(this, _FormPasswordPolicyController__rules, "f");
}, _FormPasswordPolicyController_validate = function _FormPasswordPolicyController_validate(token) {
    __classPrivateFieldGet(this, _FormPasswordPolicyController_instances, "m", _FormPasswordPolicyController_getRules).call(this).forEach((constraint) => {
        let isValid = false;
        let count = 0;
        if ('length' === constraint.rule) {
            count = token.length;
        }
        else {
            let regex;
            switch (constraint.rule) {
                case 'digits':
                    regex = /[0-9]+/g;
                    break;
                case 'lower':
                    regex = /[a-z]+/g;
                    break;
                case 'upper':
                    regex = /[A-Z]+/g;
                    break;
                case 'special_chars':
                    regex = /\W|_/g;
                    break;
                default:
            }
            if (undefined !== regex) {
                count = (token.match(regex) || '').length;
            }
        }
        isValid = constraint.length <= count;
        constraint.node.classList.remove('constraint-valid', 'constraint-invalid');
        constraint.node.classList.add('constraint-' + (isValid ? 'valid' : 'invalid'));
    });
};
