import { Controller } from "@hotwired/stimulus";

export default class FileSignatureActionController extends Controller {
    connect() {
        this.element.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.element.removeEventListener('click', this.onClick);
    }

    onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const loader = document.createElement('span');
        loader.classList.add('spinner-grow');
        loader.classList.add('spinner-grow-sm');

        this.element.removeChild(this.element.querySelector('.fal, .fas, .far, .fat, .fad'));
        this.element.prepend(loader);
        this.element.setAttribute('disabled', 'disabled');

        window.fetch(this.element.getAttribute('href'), {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-Fetch': true,
            },
        }).then((response) => {
            if (!response.ok) throw new Error(response.statusText);
            return response.json();
        }).then(({ message, color, url, text }) => {
            window.toastr.success(message);

            this.element.setAttribute('href', url);
            this.element.setAttribute('title', text);
            this.element.classList.remove('btn-primary');
            this.element.classList.remove('btn-danger');
            this.element.classList.add(`btn-${color}`);
            this.element.innerHTML = `<span class="fal fa-signature fa-fw mr-1"></span>${text}`;
            this.element.removeAttribute('disabled');
        }, () => {
            window.toastr.error('Oops ! Une erreur est survenue.');
            const icon = document.createElement('span');
            icon.className = 'fal fa-signature fa-fw mr-1';
            this.element.removeChild(loader);
            this.element.prepend(icon);
            this.element.removeAttribute('disabled');
        });
    };
}
