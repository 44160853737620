import { Controller } from "@hotwired/stimulus";

import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/nano.min.css';

export default class FormColorPickerController extends Controller {
    pickr = null

    connect() {
        this.element.autocomplete = 'random';

        this.pickr = Pickr.create({
            components: {
                preview: true,
                opacity: false,
                palette: true,
                hue: true,
                interaction: {
                    hex: false,
                    rgba: false,
                    hsla: false,
                    hsva: false,
                    cmyk: false,
                    input: true,
                    clear: false,
                    save: true
                }
            },
            default: this.element.value,
            defaultRepresentation: this.element.dataset.format,
            el: this.element,
            i18n: {
                'btn:save': 'OK',
            },
            theme: 'nano',
            useAsButton: true,
        });

        this.pickr.on('save', (color, instance) => {
            const format = this.element.dataset.format;
            const hexa = color.toHEXA().toString();
            let value = hexa;
            if ('HSLA' === format) {
                value = color.toHSLA().toString();
            } else if ('RGBA' === format) {
                value = color.toRGBA().toString();
            }

            this.display(hexa);
            this.element.value = value;

            instance.setColor(hexa, true);
            instance.hide();
        });

        this.display(this.element.value);
    }

    disconnect() {
        this.pickr.destroy();
    }

    display(color) {
        console.log(color);
        this.preview.style.backgroundColor = color;
        this.preview.style.borderColor = color;
    }

    get preview() {
        return this
            .element
            .parentElement
            .querySelector('.form-color-viewer')
        ;
    }
}
