import { Controller } from "@hotwired/stimulus";
import Color from 'color';

export default class LayoutColorController extends Controller {
    style = document.createElement('style');

    initialize() {
        const { headerBackground, sidebarBackground } = this.element.dataset;

        const root = `
            :root {
                --theme-color-major: #FFF;
                --theme-text-major: #000;
                --theme-color-minor: #FFF;
                --theme-text-minor: #000;
            }

            body.mod-skin-dark {
                --theme-color-major: #26272B;
                --theme-text-major: #FFF;
                --theme-color-minor: #26272B;
                --theme-text-minor: #FFF;
            }
        `;

        let stylesheet = `
            ${root}
            body:not(.mod-skin-dark) .bg-skin {
                background-color: #FFFFFF;
            }

            .mod-skin-dark .bg-skin {
                background-color: #26272B;
            }
        `;

        const majorColor = Color(headerBackground || '#FFF');
        const minorColor = Color(sidebarBackground || headerBackground || '#FFF');

        if (headerBackground) {
            stylesheet = `
                ${this.getHeaderStyle(majorColor)}
                ${stylesheet}
                body {
                    --theme-color-major: ${majorColor.string()};
                    --theme-text-major: ${majorColor.isLight() ? '#000' : '#FFF'};
                }
            `;

            if (!sidebarBackground) {
                stylesheet = `
                    ${stylesheet}
                    body {
                        --theme-color-minor: ${majorColor.string()};
                        --theme-text-minor: ${majorColor.isLight() ? '#000' : '#FFF'};
                    }

                    ${this.getMenuStyle(majorColor)}
                    ${this.getActionStyle(majorColor)}
                `;
            }
        }

        if (sidebarBackground) {
            stylesheet = `
                ${stylesheet}
                body {
                    --theme-color-minor: ${minorColor.string()};
                    --theme-text-minor: ${minorColor.isLight() ? '#000' : '#FFF'};
                }

                ${this.getMenuStyle(minorColor)}
                ${this.getActionStyle(minorColor)}
            `;
        }

        this.setStylesheet(stylesheet);
    }

    getActionStyle(background) {
        return `
            .shortcut-menu .menu-open:not(:checked) + .menu-open-button,
            .shortcut-menu .menu-item {
                background-color: ${background.string()} !important;
                color: ${background.isLight() ? '#000' : '#FFF'} !important;
            }
        `;
    }

    getHeaderStyle(background) {
        return `
            body:not(.mod-skin-dark) .page-header {
                background-color: ${background.string()} !important;
            }

            body:not(.mod-skin-dark) .header-icon:not(.btn) > [class*="fa-"]:first-child,
            body:not(.mod-skin-dark) .header-icon:not(.btn) > .ni:first-child {
                color: rgba(${background.isLight() ? '0, 0, 0' : '255, 255, 255'}, 0.7) !important;
            }
            body.mod-skin-dark .header-icon:not(.btn) > [class*="fa-"]:first-child, 
            body.mod-skin-dark .header-icon:not(.btn) > .ni:first-child {
                color: rgba(255, 255, 255, 0.7) !important;
            }

            body:not(.mod-skin-dark) .page-header .header-btn {
                color: rgba(${background.isLight() ? '0, 0, 0' : '255, 255, 255'}, 0.7) !important;
                border-color: rgba(${background.isLight() ? '0, 0, 0' : '255, 255, 255'}, 0.7) !important;
            }
            body.mod-skin-dark .page-header .header-btn {
                color: rgba(255, 255, 255, 0.7) !important;
                border-color: rgba(255, 255, 255, 0.7) !important;
            }

            @media (min-width: 991.98px) {
                body:not(.mod-skin-dark) .filters-placeholder {
                    color: rgba(${background.isLight() ? '0, 0, 0' : '255, 255, 255'}, 0.7) !important;
                }
            }
        `;
    }

    getMenuStyle(background) {
        let color = background.isLight() ? background.darken(0.15) : background.lighten(0.58);
        if (background.luminosity() < 0.01) color = color.lighten(0.32);
        const gradient = background.desaturate(0.15).alpha(0.18);
        const gradient2 = background.darken(0.2);

        return `
            body:not(.mod-skin-dark) .page-sidebar {
                background-image: -webkit-linear-gradient(270deg, ${gradient.string()}, transparent) !important;
                background-image: linear-gradient(270deg, ${gradient.string()}, transparent) !important;
                background-color: ${background.string()} !important;
            }

            body:not(.mod-skin-dark) .nav-menu li a,
            body:not(.mod-skin-dark) .nav-menu li a > [class*="fa-"],
            body:not(.mod-skin-dark) .nav-menu li a > .ni,
            body:not(.mod-skin-dark) .nav-menu li b.collapse-sign {
                color: rgba(${background.isLight() ? '0, 0, 0' : '255, 255, 255'}, 0.7) !important;
            }
            body:not(.mod-skin-dark) .nav-menu li a > .nav-link {
                color: ${color.string()} !important;
            }
            body:not(.mod-skin-dark) .nav-menu li a:hover {
                color: ${background.isLight() ? '#000' : '#FFF'} !important;
            }

            body:not(.mod-skin-dark) .nav-menu li a:hover > [class*="fa-"],
            body:not(.mod-skin-dark) .nav-menu li a:hover > .ni {
                color: ${background.isLight() ? '#000' : '#FFF'} !important;
            }
            body:not(.mod-skin-dark) .nav-menu li .dl-ref {
                background-color: ${color.string()} !important;
                color: ${color.isLight() ? '#000' : '#FFF'} !important;
            }

            body.mod-skin-dark .nav-menu li a > [class*="fa-"],
            body.mod-skin-dark .nav-menu li a > .ni,
            body.mod-skin-dark .nav-menu li b.collapse-sign {
                color: ${background.string()} !important;
            }
            body.mod-skin-dark .nav-menu li .dl-ref {
                background-color: ${background.string()} !important;
                color: ${background.isLight() ? '#000' : '#FFF'} !important;
            }
            
            .mod-nav-link:not(.mod-skin-dark) .nav-menu li > ul {
                background-color: ${gradient2.string()} !important;
            }
            .mod-nav-link:not(.mod-skin-dark):not(.nav-function-top):not(.nav-function-minify):not(.mod-hide-nav-icons) ul.nav-menu:not(.nav-menu-compact) > li > ul::before {
                border-left-color: ${background.string()} !important;
            }
            .mod-nav-link:not(.mod-skin-dark):not(.nav-function-top):not(.nav-function-minify):not(.mod-hide-nav-icons) ul.nav-menu:not(.nav-menu-compact) > li > ul > li a::after {
                background-color: ${background.string()} !important;
                border-color: ${gradient2.string()} !important;
            }
        `;
    }

    setStylesheet(css) {
        this.style.appendChild(document.createTextNode(css));
        document.querySelector('head').appendChild(this.style);
    }
}
